import React from "react";
import JavaSec02 from "./JavaSec02";
import JavaSec01 from "./JavaSec01";

const Java = () => {
  return (
    <div>
      <JavaSec01 />
      <JavaSec02 />
    </div>
  );
};

export default Java;

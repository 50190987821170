import React, { useState, useRef, useEffect } from 'react';
import '../../Assests/homeSection2Style.css';
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../Assests/style';
import Section2Background from '../../Images/ServiceImages/Section2/Section2Background.webp';
import UiUxCards1 from '../../Images/HomeImages/Section3/Card1.webp';
import WebCards2 from '../../Images/HomeImages/Section3/Card2.webp';
import SoftwareCards3 from '../../Images/HomeImages/Section3/Card3.webp';
import ProjectCards4 from '../../Images/HomeImages/Section3/Card4.webp';
import HrCards5 from '../../Images/HomeImages/Section3/Card5.webp';
import ECommerceCards6 from '../../Images/HomeImages/Section3/Card6.webp';
import NextBtn from "../../Images/ServiceImages/Section2/Next.png";
import PreviousBtn from "../../Images/ServiceImages/Section2/PreviousBtn.png";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Slider from 'react-slick';

const ServiceSection1 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView, setDeviceView] = useState('web');
  const swiperRef = useRef(null); // Added useRef for Swiper

  const cardsData = [
    {
      image: UiUxCards1,
      title: "UI/UX Designing",
      description:
        "Crafting visually appealing and user-friendly digital experiences, focusing on both the look and feel (UI) and seamless interaction (UX).",
      path: "uiuxservice",
    },
    {
      image: WebCards2,
      title: "Web Development",
      description:
        "Creating dynamic websites and applications, combining front-end and back-end development for a seamless online experience.",
      path: "webservice",
    },
    {
      image: SoftwareCards3,
      title: "Software Development",
      description:
        "Designing, coding, and maintaining software applications to address user needs through innovative solutions.",
      path: "softwareservice",
    },
    {
      image: ProjectCards4,
      title: "Project Management",
      description:
        "Planning, organizing, and overseeing projects to ensure timely completion, budget adherence, and goal achievement.",
      path: "projectservice",
    },
    {
      image: HrCards5,
      title: "HR Management",
      description:
        "Overseeing an organization's human capital, including recruitment, training, and fostering a positive work environment.",
      path: "hrservice",
    },
    {
      image: ECommerceCards6,
      title: "E-Commerce Solution",
      description:
        "Providing platforms for businesses to sell products or services online, optimizing the shopping experience for seamless transactions.",
      path: "ecommerceservice",
    },
  ];

  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      setIsMobileView(screenWidth < 768); // Adjust the value based on your mobile breakpoint
      setIsTabletView(screenWidth >= 768 && screenWidth <= 1050); // Adjust the values based on your tablet breakpoint
    };

    // Set initial state on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    nextArrow: null,
    prevArrow: null,
    Autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


    const handleNextSlide = () => {
      swiperRef.current.slickNext();
    };

    const handlePrevSlide = () => {
      swiperRef.current.slickPrev();
    };

  

  return (
    <>
      {!isMobileView && !isTabletView && (
        <div
          className="relative w-[100%] h-full pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
          style={{ backgroundImage: `url(${Section2Background})` }}
        >
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div style={{ marginBottom: counterOn ? "-64px" : "0" }}>
              {counterOn && (
                <div>
                  <Text text={"services"} deviceView={deviceView} />
                </div>
              )}
            </div>
          </ScrollTrigger>
          <div className="w-[448px] h-[22.37px] text-white text-5xl font-bold font-['Poppins'] uppercase leading-tight pb-5 mx-auto text-center">
            OUR SERVICES
          </div>

          <div className="flex justify-center">
            <div className="grid grid-cols-3 gap-16">
              {cardsData.map((card, index) => (
                <Link to={`/${card.path}`} key={index}>
                  <div
                    className={`rounded-lg overflow-hidden shadow-lg ${
                      index >= 3 ? "mt-[50px]" : "mt-[100px]"
                    } transition-transform transform-gpu hover:scale-105 relative`}
                    style={{
                      boxShadow: "15px 15px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <div className="relative">
                      <img
                        src={card.image}
                        alt={"services"}
                        className="w-[359.74px] h-[507.17px] object-cover object-center"
                      />
                      <div className="absolute mt-12 w-[90%] mx-[5%] leading-6 text-center top-1/2">
                        <p className="text-center text-white text-[28px] font-semibold font-['Poppins'] capitalize leading-8 pb-4">
                          {card.title}
                        </p>
                        <p className="text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-40">
                          {card.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Mobile View */}
      {isMobileView && (
        <div
          style={{
            backgroundImage: `url(${Section2Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            padding: "10px",
          }}
        >
          <div>
            <div>
              <div className="flex-shrink-0 w-auto h-[2.125rem] text-center text-white font-['Poppins'] text-[1.375rem] font-bold leading-[normal] uppercase mb-10 min-[425px]:mt-10 md:mt-5 max-md:text-3xl lg:text-5xl min-[375px]:mt-20 max-sm:text-3xl">
                Our Services
              </div>
            </div>
          </div>
          <div className="w-[80%] mx-[10%] relative mt-auto mb-10 mr-10">
            <Slider {...settings} ref={swiperRef}>
              <Link to="/uiuxservice">
                <div className="relative text-white">
                  <img
                    src={UiUxCards1}
                    alt="uiuximage"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 md:text-2xl md:mt-20 max-sm:mt-5">
                      UI/UX
                      <br />
                      Designing
                    </div>
                    <div className="mt-5 text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Crafting visually appealing and user-friendly digital
                      experiences, focusing on both the look and feel (UI) and
                      seamless interaction (UX).
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/webservice">
                <div className="relative text-white">
                  <img
                    src={WebCards2}
                    alt="webdevelopment"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 md:text-2xl md:mt-20 max-sm:mt-5">
                      Web
                      <br />
                      Development
                    </div>
                    <div className="mt-5 text-center-justify text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Creating dynamic websites and applications, combining
                      front-end and back-end development for a seamless online
                      experience.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/softwareservice">
                <div className="relative text-white">
                  <img
                    src={SoftwareCards3}
                    alt="softwaredeevelopment"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 md:text-2xl md:mt-20 max-sm:mt-5">
                      Software
                      <br />
                      Development
                    </div>
                    <div className="mt-5 text-center-justify text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Designing, coding, and maintaining software applications
                      to address user needs through innovative solutions.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/projectservice">
                <div className="relative text-white">
                  <img
                    src={ProjectCards4}
                    alt="projectmanagement"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 max-md:text-2xl md:mt-20 max-sm:mt-5">
                      Project
                      <br />
                      Management
                    </div>
                    <div className="mt-5 text-center-justify text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Planning, organizing, and overseeing projects to ensure
                      timely completion, budget adherence, and goal achievement.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/hrservice">
                <div className="relative text-white">
                  <img
                    src={HrCards5}
                    alt="hrmanagement"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 max-md:text-2xl md:mt-20 max-sm:mt-5">
                      HR
                      <br />
                      Management
                    </div>
                    <div className="mt-5 text-center-justify text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Overseeing an organization's human capital, including
                      recruitment, training, and fostering a positive work
                      environment.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/ecommerceservice">
                <div className="relative text-white">
                  <img
                    src={ECommerceCards6}
                    alt="ecommerce"
                    className="w-[80%] ml-[10%] rounded-xl"
                  />
                  <div className="absolute mt-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 max-md:text-2xl md:mt-20 max-sm:mt-5">
                      E-Commerce
                      <br />
                      Solutions
                    </div>
                    <div className="mt-5 text-center-justify text-md lg:text-md md:w-auto max-md:text-sm max-sm:text-sm">
                      Providing platforms for businesses to sell products or
                      services online, optimizing the shopping experience for
                      seamless transactions.
                    </div>
                  </div>
                </div>
              </Link>
            </Slider>

            <button
              onClick={handlePrevSlide}
              className="absolute transform -translate-y-1/2 top-1/2 -left-4 focus:outline-none"
            >
              <img src={PreviousBtn} alt="Previous" className="w-9" />
            </button>
            <button
              onClick={handleNextSlide}
              className="absolute transform -translate-y-1/2 top-1/2 -right-4 focus:outline-none"
            >
              <img src={NextBtn} alt="Next" className="w-9" />
            </button>
          </div>
        </div>
      )}

      {/* Tablet View */}
      {isTabletView && (
        <div
          style={{
            backgroundImage: `url(${Section2Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "900px",
            marginTop: "-12px",
            marginBottom: "-10px",
          }}
        >
          <div className=" text-white text-[40px] font-bold font-[poppins] uppercase leading-[51.48px] tracking-wider pt-16 text-center ">
            Our Services
          </div>
          <Swiper
            spaceBetween={50}
            slidesPerView={2}
            navigation={{
              nextEl: ".custom-next-button",
              prevEl: ".custom-prev-button",
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="max-w-[80%]"
            ref={swiperRef} // Added ref to Swiper
          >
            {cardsData.map((card, index) => (
              <SwiperSlide key={index}>
                <Link to={`/${card.path}`}>
                  <div
                    className={`rounded-lg overflow-hidden shadow-lg ${
                      index >= 3 ? "mt-[50px]" : "mt-[50px]"
                    } transition-transform hover:scale-105 relative`}
                  >
                    <div className="relative">
                      <img
                        src={card.image}
                        alt="images"
                        className="w-[420px] h-[480px] object-cover object-center"
                      />
                      <div className="absolute m-5 mt-12 leading-6 text-center top-1/2">
                        <p className="text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-8 pb-4">
                          {card.title}
                        </p>
                        <p className="text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                          {card.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          

          <div class="w-[80%] mx-[10%] p-5 font-[poppins] mt-10 items-center text-white text-[16px] capitalize tracking-wider text-center">
            <p>
              With a proven track record of successful projects across various
              industries, Zynovatex has the skills and knowledge to make your
              software vision a reality.
              <br /> <br /> Contact us today to discuss how our development
              services can empower your organization through innovative,
              reliable software.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceSection1;

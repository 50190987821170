import React from "react";
import Section2Background from "../../../Images/ProjectImages/ProjectSection2.webp";
import ProjectTestimonial from "../ProjectTestimonial";
const Section002 = () => {
  return (
    <div
      className="py-[5%] max-sm:py-[20%] bg-cover"
      style={{ backgroundImage: `url(${Section2Background})` }}
    >
      {/* project detaila */}
      <div className="w-[70%] max-sm:w-[90%] max-sm:mx-[5%] mx-[15%] max-md:pt-10 h-full   border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
        <div className="text-xl text-white max-md:text-lg max-sm:text-base">
          Our expertise in{" "}
          <span className="text-blue-500"> University Projects </span>
          encompasses a wide array of AI and ML applications, offering
          innovative solutions tailored to academic requirements. From data
          analysis to algorithm implementation, we bring academic projects to
          life.
          <br />
          <br />
          <span className="font-extrabold">Used Technologies</span>
          <br />
          <br /> Python (TensorFlow, Scikit-learn), R, Java, Jupyter Notebooks,
          Data Visualization Libraries. <br />
          <br />
          <span className="font-extrabold">Features</span>
          <br />
          <br />
          <p>1. Algorithm Implementation for AI and ML</p>
          <p className="mt-2">2. Data Analysis and Visualization</p>
          <p className="mt-2">3. Academic Standards Adherence</p>
          <p className="mt-2">4. Scalable and Modular Designs</p>
          <p className="mt-2">
            5. Real-time Feedback and Iterative Development
          </p>
          <p className="mt-2">6. Integration of Advanced AI Models</p>
          <br />
          <br />
          <span className="font-extrabold">Challenges Faced</span>
          <br />
          <br />
          Developing university AI and ML projects posed challenges in
          understanding diverse requirements and ensuring scalability, with
          additional hurdles in data limitations and aligning with academic
          standards.
          <br />
          <br />
          <span className="font-extrabold">Solution Highlights</span>
          <br />
          <br />
          Our solution involved meticulous exploration of algorithms,
          collaboration with domain experts, and modular designs, ensuring
          alignment with academic standards and scalability for diverse
          university projects.
          <br />
          <br />
          <span className="font-extrabold">Team's Roles</span>
          <br />
          <br />
          The project team, comprising AI specialists and domain experts,
          collaborated closely to implement algorithms, address project
          requirements, and ensure effective communication and coordination
          through project managers.
          <br />
          <br />
          <span className="font-extrabold">Results and Impacts</span>
          <br />
          <br />
          Completed AI and ML projects enhanced academic learning experiences,
          providing practical insights for students and receiving positive
          feedback from universities, showcasing the impact of our solutions on
          education and research.
          <br />
          <br />
          <span className="font-extrabold">Lessons Learned</span>
          <br />
          <br />
          Key lessons included interdisciplinary collaboration, adapting
          solutions to unique project requirements, and staying updated on
          evolving academic standards and technological advancements in AI and
          ML.
          <br />
          <br />
          <span className="font-extrabold">Future Enhancements</span>
          <br />
          <br />
          Future plans involve integrating advanced AI models, collaborating on
          cutting-edge research projects, and developing educational tools to
          enhance the learning experience for AI and ML students while
          continually aligning with evolving educational needs.
        </div>
      </div>
      <ProjectTestimonial/>
    </div>
  );
};

export default Section002;

import React, { useState, useEffect, Component, useRef } from "react";
import "../../../Assests/homeSection2Style.css";
import Text from "../../../Assests/style";
import UxUiDesign from "../../../Images/ServiceImages/ReadeMoreImages/ProjectManagement.webp";
import Section2Backgrounnd from "../../../Images/ServiceImages/Section2/Section2Background.webp";
import ScrollTrigger from "react-scroll-trigger";
import SocialMedia from "../../../Common/SocialMedia";
import Technologies from "../ServicesReadMore/Technologies";
import Slider from "react-slick";
import PreviousButton from "../../../Images/HomeImages/Section5/PreviousButton.png";
import BackButton from "../../../Images/HomeImages/Section5/BackButton.png";

const ProjectService = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const sliderRef = useRef(null);
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      console.log("Slider component is mounted");
    }
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.1)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div>
            <SocialMedia />
            {/* Section1 */}
            <div
              className="w-[100%] h-screen bg-center bg-cover bg-gradient-to-b from-black to-black"
              style={{ backgroundImage: `url(${UxUiDesign})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div
                  style={{
                    marginBottom: counterOn ? "-64px" : "0",
                    paddingTop: "180px",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text
                        text={"Project Management"}
                        deviceView={deviceView}
                      />
                    </div>
                  )}
                </div>

                <div className=" w-[80%] mx-[10%]  text-center text-white text-5xl max-lg:text-4xl max-lg:mt-4 max-md:text-2xl max-md:mt-10 font-bold font-['Poppins']  uppercase leading-tight">
                  Project Management
                </div>
                <div class="w-[60%] max-lg:w-[70%] max-md:w-[80%] max-lg:text-base max-md:text-sm mx-auto text-center text-white text-lg font-medium font-['Poppins'] capitalize leading-[29px]">
                  <br />
                  Bring strategic vision and efficient execution to your
                  projects with our project management services. We provide the
                  expertise, tools, and methodologies to help you complete
                  projects on time and within budget.
                  <br />
                  <br /> From initial planning to final delivery, our project
                  managers oversee every phase to mitigate risks and optimize
                  results. We customize our approach based on your unique needs
                  to ensure smooth collaboration across team members. With a
                  laser focus on schedules, budgets, and scopes, we drive
                  on-time, on-budget outcomes—and partner with us to
                  successfully execute initiatives of any complexity.
                </div>
              </ScrollTrigger>
            </div>

            {/* Section2 */}
            <div
              className="w-full mt-[-10px] h-full bg-gradient-to-b bg-cover bg-center pb-20  from-black to-black"
              style={{ backgroundImage: `url(${Section2Backgrounnd})` }}
            >
              {/* Header */}
              <div className="text-white w-[80%] mx-[10%] text-center font-['Poppins'] text-4xl max-md:text-3xl max-sm:text-2xl max-[500px]:text- font-bold leading-[63px] pt-[68px] pb-10 uppercase">
                Empowering Your Business with Custom Software Solutions
              </div>

              {/* Glass frame  */}
              <div class="flex justify-center ">
                <div class=" p-10  w-[80%] h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                  {/* Glass frame 1 */}
                  <div className=" inline-flex flex-shrink-0 justify items-center  p-8 h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] w-full  ">
                    {/* Glass Number */}
                    <ScrollTrigger
                      onEnter={() => setIsVisible1(true)}
                      onExit={() => setIsVisible1(false)}
                    >
                      <div
                        className={`flex flex-col flex-shrink-0 justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ${
                          isVisible1
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                          1
                        </div>
                      </div>
                    </ScrollTrigger>
                    <ScrollTrigger
                      onEnter={() => setIsVisible1(true)}
                      onExit={() => setIsVisible1(false)}
                    >
                      {/* Description */}
                      <div
                        className={`text-white text-justify ml-8 font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${
                          isVisible1
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        Project planning involves defining project goals,
                        creating timelines, and allocating resources to ensure a
                        clear roadmap for successful project execution.
                      </div>
                    </ScrollTrigger>
                  </div>

                  {/* Glass frame 2 */}
                  <div className=" inline-flex mt-5 flex-shrink-0 justify items-center  p-8 h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] w-full  ">
                    <ScrollTrigger
                      onEnter={() => setIsVisible2(true)}
                      onExit={() => setIsVisible2(false)}
                    >
                      {/* Description */}
                      <div
                        className={`text-white text-justify mr-8  font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${
                          isVisible2
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        Risk management anticipates and addresses potential
                        challenges and uncertainties, minimizing the impact of
                        unforeseen events on project timelines and deliverables.
                      </div>
                    </ScrollTrigger>
                    <ScrollTrigger
                      onEnter={() => setIsVisible2(true)}
                      onExit={() => setIsVisible2(false)}
                    >
                      {/* Glass Number */}
                      <div
                        className={` flex flex-col flex-shrink-0 justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ${
                          isVisible2
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                          2
                        </div>
                      </div>
                    </ScrollTrigger>
                  </div>

                  {/* Glass frame 3 */}
                  <div className=" inline-flex mt-5 flex-shrink-0 justify items-center  p-8 h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] w-full  ">
                    {/* Glass Number */}
                    <ScrollTrigger
                      onEnter={() => setIsVisible3(true)}
                      onExit={() => setIsVisible3(false)}
                    >
                      <div
                        className={`flex flex-col flex-shrink-0 justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ${
                          isVisible3
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                          3
                        </div>
                      </div>
                    </ScrollTrigger>
                    <ScrollTrigger
                      onEnter={() => setIsVisible3(true)}
                      onExit={() => setIsVisible3(false)}
                    >
                      {/* Description */}
                      <div
                        className={`text-white  text-justify ml-8 font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${
                          isVisible3
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        Agile methodologies embrace flexibility, collaboration,
                        and iterative development, allowing teams to adapt to
                        changing requirements and deliver incremental value.
                      </div>
                    </ScrollTrigger>
                  </div>

                  {/* Glass frame 4 */}
                  <div className=" inline-flex mt-5 flex-shrink-0 justify items-center  p-8 h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] w-full  ">
                    <ScrollTrigger
                      onEnter={() => setIsVisible4(true)}
                      onExit={() => setIsVisible4(false)}
                    >
                      {/* Description */}
                      <div
                        className={`text-white text-justify mr-8  font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${
                          isVisible4
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        Effective team collaboration fosters communication,
                        trust, and synergy among team members, contributing to
                        the overall success of the project.
                      </div>
                    </ScrollTrigger>
                    <ScrollTrigger
                      onEnter={() => setIsVisible4(true)}
                      onExit={() => setIsVisible4(false)}
                    >
                      {/* Glass Number */}
                      <div
                        className={` flex flex-col flex-shrink-0 justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ${
                          isVisible4
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                          4
                        </div>
                      </div>
                    </ScrollTrigger>
                  </div>

                  {/* Glass frame 5 */}
                  <div className=" inline-flex mt-5 flex-shrink-0 justify items-center  p-8 h-full rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] w-full  ">
                    {/* Glass Number */}
                    <ScrollTrigger
                      onEnter={() => setIsVisible5(true)}
                      onExit={() => setIsVisible5(false)}
                    >
                      <div
                        className={`flex flex-col flex-shrink-0 justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ${
                          isVisible5
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                          5
                        </div>
                      </div>
                    </ScrollTrigger>
                    <ScrollTrigger
                      onEnter={() => setIsVisible5(true)}
                      onExit={() => setIsVisible5(false)}
                    >
                      {/* Description */}
                      <div
                        className={`text-white  text-justify ml-8 font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${
                          isVisible5
                            ? "translate-x-0 opacity-100"
                            : "translate-x-full opacity-0"
                        } duration-500 ease-out`}
                      >
                        Resource allocation involves optimizing the use of human
                        and material resources to ensure efficient project
                        execution within specified constraints.
                      </div>
                    </ScrollTrigger>
                  </div>
                </div>
              </div>
            </div>
            <Technologies />
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div>
            <SocialMedia />
            {/* Section1 */}
            <div
              className="w-[100%] h-screen bg-center bg-cover bg-gradient-to-b from-black to-black"
              style={{ backgroundImage: `url(${UxUiDesign})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div
                  style={{
                    marginBottom: counterOn ? "-64px" : "0",
                    paddingTop: "180px",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text
                        text={"Project Management"}
                        deviceView={deviceView}
                      />
                    </div>
                  )}
                </div>

                <div className=" w-[80%] mx-[10%]  text-center text-white text-5xl max-lg:text-4xl max-lg:mt-4 max-md:text-2xl max-md:mt-10 font-bold font-['Poppins']  uppercase leading-tight">
                  Project Management
                </div>
                <div class="w-[60%] max-lg:w-[70%] max-md:w-[80%] max-lg:text-base max-md:text-sm mx-auto text-center text-white text-lg font-medium font-['Poppins'] capitalize leading-[29px]">
                  <br />
                  Bring strategic vision and efficient execution to your
                  projects with our project management services. We provide the
                  expertise, tools, and methodologies to help you complete
                  projects on time and within budget.
                  <br />
                  <br /> From initial planning to final delivery, our project
                  managers oversee every phase to mitigate risks and optimize
                  results. We customize our approach based on your unique needs
                  to ensure smooth collaboration across team members. With a
                  laser focus on schedules, budgets, and scopes, we drive
                  on-time, on-budget outcomes—and partner with us to
                  successfully execute initiatives of any complexity.
                </div>
              </ScrollTrigger>
            </div>

            {/* Section2 */}
            <div
              className="w-full h-full pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
              style={{ backgroundImage: `url(${Section2Backgrounnd})` }}
            >
              {/* Header */}
              <div className="text-white w-[80%] mx-[10%] text-center font-['Poppins'] text-4xl max-md:text-3xl max-sm:text-2xl max-[500px]:text- font-bold leading-[63px] pt-[68px] pb-10 uppercase">
                Empowering Your Business with Custom Software Solutions
              </div>

              {/* Glass frame  */}
              <div className=" w-[50%] mx-[25%] mb-40 mt-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                <div className="text-center text-white w-[80%] mx-[10%] ">
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    className="flex justify-center"
                  >
                    {/* card 1 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              1
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Project planning involves defining project goals,
                          creating timelines, and allocating resources to ensure
                          a clear roadmap for successful project execution.
                        </div>
                      </div>
                    </div>

                    {/* card 2 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              2
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Risk management anticipates and addresses potential
                          challenges and uncertainties, minimizing the impact of
                          unforeseen events on project timelines and
                          deliverables.
                        </div>
                      </div>
                    </div>

                    {/* card 3 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              3
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Agile methodologies embrace flexibility,
                          collaboration, and iterative development, allowing
                          teams to adapt to changing requirements and deliver
                          incremental value.
                        </div>
                      </div>
                    </div>

                    {/* card 4 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              4
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Effective team collaboration fosters communication,
                          trust, and synergy among team members, contributing to
                          the overall success of the project.
                        </div>
                      </div>
                    </div>

                    {/* card 5 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              5
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Resource allocation involves optimizing the use of
                          human and material resources to ensure efficient
                          project execution within specified constraints.
                        </div>
                      </div>
                    </div>

                    {/* Add more cards as needed */}
                  </Slider>
                  <div>
                    <div className="absolute w-10 mt-6 left-80 ">
                      <button onClick={previousSlide}>
                        <img
                          src={BackButton}
                          alt="Previous"
                          style={{
                            ...(isLinkedinHovered && iconHoverStyle),
                            ...fontFamilyStyle,
                          }}
                          onMouseEnter={() => setIsLinkedinHovered(true)}
                          onMouseLeave={() => setIsLinkedinHovered(false)}
                        />
                      </button>
                    </div>
                    <div className="absolute w-10 mt-6 right-80">
                      <button onClick={nextSlide}>
                        <img
                          src={PreviousButton}
                          alt="Next"
                          style={{
                            ...(isTikTokHovered && iconHoverStyle),
                            ...fontFamilyStyle,
                          }}
                          onMouseEnter={() => setIsTikTokHovered(true)}
                          onMouseLeave={() => setIsTikTokHovered(false)}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Technologies />
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div>
            <SocialMedia />
            {/* Section1 */}
            <div
              className="w-[100%] h-screen bg-center bg-cover bg-gradient-to-b from-black to-black"
              style={{ backgroundImage: `url(${UxUiDesign})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div
                  style={{
                    marginBottom: counterOn ? "-64px" : "0",
                    paddingTop: "180px",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text
                        text={"Project Management"}
                        deviceView={deviceView}
                      />
                    </div>
                  )}
                </div>

                <div className=" w-[80%] mx-[10%]  text-center text-white text-5xl max-lg:text-4xl max-lg:mt-4 max-md:text-2xl max-md:mt-10 font-bold font-['Poppins']  uppercase leading-tight">
                  Project Management
                </div>
                <div class="w-[60%] max-lg:w-[70%] max-md:w-[80%] max-lg:text-base max-md:text-sm mx-auto text-center text-white text-lg font-medium font-['Poppins'] capitalize leading-[29px]">
                  <br />
                  Bring strategic vision and efficient execution to your
                  projects with our project management services. We provide the
                  expertise, tools, and methodologies to help you complete
                  projects on time and within budget.
                  <br />
                  <br /> From initial planning to final delivery, our project
                  managers oversee every phase to mitigate risks and optimize
                  results. We customize our approach based on your unique needs
                  to ensure smooth collaboration across team members. With a
                  laser focus on schedules, budgets, and scopes, we drive
                  on-time, on-budget outcomes—and partner with us to
                  successfully execute initiatives of any complexity.
                </div>
              </ScrollTrigger>
            </div>

            {/* Section2 */}
            <div
              className="w-full h-full pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
              style={{ backgroundImage: `url(${Section2Backgrounnd})` }}
            >
              {/* Header */}
              <div className="text-white w-[80%] mx-[10%] text-center font-['Poppins'] text-4xl max-md:text-3xl max-sm:text-2xl max-[500px]:text- font-bold leading-[63px] pt-[68px] pb-10 uppercase">
                Empowering Your Business with Custom Software Solutions
              </div>

              {/* Glass frame  */}
              <div className=" w-[70%] mx-[15%] mb-40 mt-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                <div className="text-center text-white w-[80%] mx-[10%] ">
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    className="flex justify-center"
                  >
                    {/* card 1 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              1
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Project planning involves defining project goals,
                          creating timelines, and allocating resources to ensure
                          a clear roadmap for successful project execution.
                        </div>
                      </div>
                    </div>

                    {/* card 2 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              2
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Risk management anticipates and addresses potential
                          challenges and uncertainties, minimizing the impact of
                          unforeseen events on project timelines and
                          deliverables.
                        </div>
                      </div>
                    </div>

                    {/* card 3 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              3
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Agile methodologies embrace flexibility,
                          collaboration, and iterative development, allowing
                          teams to adapt to changing requirements and deliver
                          incremental value.
                        </div>
                      </div>
                    </div>

                    {/* card 4 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              4
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Effective team collaboration fosters communication,
                          trust, and synergy among team members, contributing to
                          the overall success of the project.
                        </div>
                      </div>
                    </div>

                    {/* card 5 */}
                    <div>
                      <div className=" w-[100%] mx-[0%] mt-10 mb-10  rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                        {/* glass number */}
                        <div className="flex justify-center mt-10">
                          <div className="flex  justify-center items-center p-0  w-[5.625rem] h-[5.625rem] rounded-full border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] transition-transform transform ">
                            <div className="text-[#00d1ff] text-justify font-['Poppins'] text-[3.5rem] font-extrabold leading-[2.1875rem]">
                              5
                            </div>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="text-white w-[80%] mx-[10%] pb-5 mt-10 text-center font-['Poppins'] text-2xl max-lg:text-xl max-md:text-lg font-medium leading-[2.1875rem] transition-transform transform ${">
                          Resource allocation involves optimizing the use of
                          human and material resources to ensure efficient
                          project execution within specified constraints.
                        </div>
                      </div>
                    </div>

                    {/* Add more cards as needed */}
                  </Slider>
                  <div>
                    <div className="absolute w-10 mt-6 left-40 ">
                      <button onClick={previousSlide}>
                        <img
                          src={BackButton}
                          alt="Previous"
                          style={{
                            ...(isLinkedinHovered && iconHoverStyle),
                            ...fontFamilyStyle,
                          }}
                          onMouseEnter={() => setIsLinkedinHovered(true)}
                          onMouseLeave={() => setIsLinkedinHovered(false)}
                        />
                      </button>
                    </div>
                    <div className="absolute w-10 mt-6 right-40">
                      <button onClick={nextSlide}>
                        <img
                          src={PreviousButton}
                          alt="Next"
                          style={{
                            ...(isTikTokHovered && iconHoverStyle),
                            ...fontFamilyStyle,
                          }}
                          onMouseEnter={() => setIsTikTokHovered(true)}
                          onMouseLeave={() => setIsTikTokHovered(false)}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Technologies />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectService;

import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
import anguler from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/anguler.webp';
import c from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/c.webp';
import cpp from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/cpp.webp';
import cshrp from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/cshrp.webp';
import dajango from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/dajango.webp';
import express from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/express.webp';
import fastapi from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/fastapi.webp';
import flask from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/flask.webp';
import java from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/java.webp';
import laraval from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/laraval.webp';
import node from '../../../../Images/ServiceImages/ReadeMoreImages/BackEnd/node.webp';


const BackEnd = () => {
    const swiperRef = useRef(null);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slidesPerView = () => {
        switch (deviceView) {
            case 'mobile':
                return 3;
            case 'tablet':
                return 5;
            case 'web':
            default:
                return 6;
        }
    };


    return (
        <div className='w-full'>
            <Swiper className=''
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                slidesPerView={slidesPerView()}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                <div>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={anguler} alt="anguler" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={c} alt="c " />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={cpp} alt="cpp " />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={cshrp} alt="cshrp" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={dajango} alt="dajango" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={express} alt="express" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={fastapi} alt="fastapi" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={flask} alt="flask" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={java} alt="java" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={laraval} alt="laraval" />
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={node} alt="node" />
                        </div>

                    </SwiperSlide>
                    
                </div>
            </Swiper>
        </div>
    );
};

export default BackEnd;

import React from 'react'
import SocialMedia from '../Component/Common/SocialMedia';
import ContactSection1 from '../Component/Pages/ContactPage/ContactSection1';
import ContactSection2 from '../Component/Pages/ContactPage/ContactSection2';
import ContactSection3 from '../Component/Pages/ContactPage/ContactSection3';

const Contact = () => {
  return (
    <div>
      <ContactSection1 />
      <ContactSection2 />
      <ContactSection3 />
      <SocialMedia />
    </div>
  );
}

export default Contact

import React from "react";
import Section2Background from "../../../Images/ProjectImages/ProjectSection2.webp";
import ProjectTestimonial from "../ProjectTestimonial";

const Section0003 = () => {
  return (
    <div
      className="py-[5%] max-sm:py-[20%] bg-cover"
      style={{ backgroundImage: `url(${Section2Background})` }}
    >
      {/* project detaila */}
      <div className="w-[70%] max-sm:w-[90%] max-sm:mx-[5%] mx-[15%] max-md:pt-10 h-full   border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
        <div className="text-xl text-white max-md:text-lg max-sm:text-base">
          Our
          <span className="text-blue-500"> website </span>
          development service focuses on creating dynamic, responsive, and
          user-friendly websites tailored to your specific needs. From sleek
          corporate sites to engaging e-commerce platforms, we ensure an online
          presence that captivates your audience.
          <br />
          <br />
          <span className="font-extrabold">Used Technologies</span>
          <br />
          <br /> HTML, CSS, JavaScript, React for front-end; Node.js, Django,
          Laravel for back-end; WordPress, Drupal for CMS.
          <br />
          <br />
          <span className="font-extrabold">Features</span>
          <br />
          <br />
          <p>1. Responsive Design for Cross-Device Compatibility</p>
          <p className="mt-2">
            2. Customizable Content Management Systems (CMS)
          </p>
          <p className="mt-2">3. Secure Web Development Practices</p>
          <p className="mt-2">
            4. Intuitive User Interfaces (UI) for Enhanced User Experience
          </p>
          <p className="mt-2">5. E-commerce Integration for Online Stores</p>
          <p className="mt-2">
            6. Search Engine Optimization (SEO) for Visibility
          </p>
          <br />
          <br />
          <span className="font-extrabold">Challenges Faced</span>
          <br />
          <br />
          Building websites posed challenges in meeting diverse client
          requirements, ensuring cross-browser compatibility, and optimizing for
          various devices. Additionally, balancing aesthetic appeal with
          performance optimization presented a continual challenge.
          <br />
          <br />
          <span className="font-extrabold">Solution Highlights</span>
          <br />
          <br />
          Our website development approach emphasized flexible and responsive
          designs, addressing compatibility issues. By utilizing frameworks and
          responsive technologies, we ensured seamless user experiences across
          devices. Collaborative brainstorming sessions and regular client
          feedback loops allowed us to strike a balance between aesthetics and
          performance.
          <br />
          <br />
          <span className="font-extrabold">Team's Roles</span>
          <br />
          <br />
          The development team played a key role in coding, ensuring
          functionality, and optimizing website performance. UI/UX designers
          crafted visually appealing interfaces, while QA specialists rigorously
          tested for compatibility and functionality. Project managers
          facilitated effective communication between development and client
          needs, ensuring projects met deadlines and exceeded expectations.
          <br />
          <br />
          <span className="font-extrabold">Results and Impacts</span>
          <br />
          <br />
          The implementation of our websites resulted in enhanced user
          experiences, increased online visibility, and improved client
          satisfaction. Performance optimization measures led to faster loading
          times, reducing bounce rates and positively impacting search engine
          rankings.
          <br />
          <br />
          <span className="font-extrabold">Lessons Learned</span>
          <br />
          <br />
          Key lessons included the importance of iterative development cycles,
          ongoing client collaboration for real-time feedback, and the necessity
          of staying updated on emerging web technologies to ensure websites
          remain cutting-edge and future-proof.
          <br />
          <br />
          <span className="font-extrabold">Future Enhancements</span>
          <br />
          <br />
          Future plans involve further integration of AI for personalized user
          experiences, continued emphasis on mobile optimization, and exploring
          progressive web app (PWA) technologies. Staying at the forefront of
          design trends and continually refining development processes are
          priorities for ongoing success.
        </div>
      </div>
      <ProjectTestimonial/>
    </div>
  );
};

export default Section0003;

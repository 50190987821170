import React from 'react';
import BlogSec1 from './Sections/BlogSec1';
import BlogSec2 from './Sections/BlogSec2';
import BlogSec3 from './Sections/BlogSec3';

const Blog1 = () => {
    return (
        <div>
            <BlogSec1/>
            <BlogSec2/>
            <BlogSec3/>
        </div>
    )
}

export default Blog1;

import React from "react";
import Section1 from "./FullStackSection1";
import Section2 from "./FullStackSection2";
import Section3 from "./FullStackSection3";

const FullStackVacancies = () => {
  return (
    <div>
      <Section1 />
      <Section2/>
      <Section3/>
    </div>
  );
};

export default FullStackVacancies;

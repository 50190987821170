import React, { useState, useEffect } from "react";
import Section2Background from "../../Images/PrivacyPolicyImages/Section2Background.webp";
import { RxDotFilled } from "react-icons/rx";

const fontFamily = "Poppins";

const PrivacyPolicySection2 = () => {
  const [deviceView, setDeviceView] = useState("web");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="w-[100%] h-full bg-cover bg-center py-[5%] px-[5%] mt-[-5px]"
        style={{ backgroundImage: `url(${Section2Background})` }}
      >
        <div className="ml-[5%] w-[90%] h-full max-sm:w-[96%] max-sm:mb-10 max-sm:ml-[2%] max-sm:px-8 max-sm:py-10 border-[#fff]/[.10] p-20 max-lg:p-16 max-md:px-12 bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
          <div>
            <h1
              className="text-white text-2xl max-lg:text-xl font-bold leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              Privacy Policy of Zynovatex
            </h1>
            <p
              className="text-white text-xl max-lg:text-lg font-light max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Zynovatex operates the www.zynovatex.com website, providing
              services (the "Service"). This page informs visitors regarding our
              policies for the collection, use, and disclosure of Personal
              Information when using our Service, the Zynovatex website.
              <br />
              By using our Service, you agree to the collection and use of
              information as outlined in this policy. The Personal Information
              collected is used for providing and improving the Service. We will
              not use or share your information with anyone except as described
              in this Privacy Policy.
              <br />
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, accessible at www.zynovatex.com, unless
              otherwise defined in this Privacy Policy.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl text-justify"
              style={{ fontFamily: fontFamily }}
            >
              {" "}
              <br />
              Information Collection and Use
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              For an enhanced experience while using our Service, we may require
              you to provide certain personally identifiable information,
              including but not limited to your name, phone number, and postal
              address. The collected information will be used to contact or
              identify you.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Log Data
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg   max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Whenever you visit our Service, we collect information known as
              Log Data, sent by your browser. This Log Data may include your
              computer's Internet Protocol ("IP") address, browser version,
              pages visited, the time and date of your visit, time spent on
              pages, and other statistics.
            </p>
          </div>
          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px]  max-lg:text-xl text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Cookies
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Our website uses "cookies," which are files with a small amount of
              data used as an anonymous unique identifier. These are sent to
              your browser from the visited website and stored on your
              computer's hard drive. You have the option to accept or refuse
              these cookies. If you choose to refuse our cookies, you may not be
              able to use some portions of our Service.
            </p>
          </div>
          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Service Providers
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We may employ third-party companies and individuals for various
              reasons:
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                To facilitate our Service;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                To provide the Service on our behalf;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                To perform Service-related services;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                To assist in analyzing how our Service is used.
              </span>
            </p>
            <p
              className="text-white text-xl font-light  max-sm:text-base  max-lg:text-lg leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              These third parties have access to your Personal Information but
              are obligated not to disclose or use it for any other purpose.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Security
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We value your trust in providing us with your Personal
              Information, and we strive to use commercially acceptable means to
              protect it. However, no method of transmission over the internet
              or electronic storage is 100% secure, and we cannot guarantee its
              absolute security.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Links to Other Sites
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Our Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. We strongly advise
              you to review the Privacy Policy of these websites, as we have no
              control over, and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
            </p>
          </div>
          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Children's Privacy
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Our Services do not address anyone under the age of 13. We do not
              knowingly collect personal identifiable information from children
              under 13. If we discover that a child under 13 has provided us
              with personal information, we immediately delete it from our
              servers. If you are a parent or guardian and you are aware that
              your child has provided us with personal information, please
              contact us so that we can take necessary actions.
            </p>
          </div>
          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Changes to This Privacy Policy
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We may update our Privacy Policy from time to time. We advise you
              to review this page periodically for any changes. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              These changes are effective immediately after they are posted on
              this page.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold leading-[35px] max-lg:text-xl  text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Contact Us
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg  max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at via email zx.info@zynovatex.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicySection2;

import React from 'react'
import TermsSection1 from '../Component/Pages/TermsAndConditionsPage/TermsSection1'
import TermsSection2 from '../Component/Pages/TermsAndConditionsPage/TermsSection2'
import SocialMedia from '../Component/Common/SocialMedia';

const TermsAndConditions = () => {
  return (
    <div>
        <TermsSection1/>
        <TermsSection2/>
        <SocialMedia/>
    </div>
  )
}

export default TermsAndConditions

import React, { useState, useEffect } from 'react';
import '../../Assests/homeSection2Style.css';
import Text from '../../Assests/style';
import ScrollTrigger from 'react-scroll-trigger';
import BgImage from "../../Images/ContactImages/Section3/Section3Background.webp";

const ContactSection3 = () => {
    const [counterOn, setCounterOn] = useState(false);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                setDeviceView('mobile');
            } else if (screenWidth <= 1024) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const LocationIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6 text-white"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>
    );

    return (
      <div>
        <div className="relative">
          <div
            className=" w-[100%] h-full bg-center bg-no-repeat bg-cover pb-28"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
              triggersOnce={true}
            >
              <div style={{ marginBottom: "-64px", marginTop: "5px " }}>
                {counterOn && (
                  <div>
                    <Text text={"Location"} deviceView={deviceView} />
                  </div>
                )}
              </div>
              <div className="text-center">
                <h1 className="font-semibold max-sm:text-xl max-md:text-2xl max-lg:text-3xl max-lg:mt-5 max-md:mt-9 max-sm:mt-8 text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase">
                  Our Location
                </h1>
              </div>
              <div className="card w-[60%] max-lg:w-[80%] max-lg:mx-[10%] max-sm:w-[80%] max-sm:mx-[10%] py-10 mx-[20%] mt-[5%] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] ">
                {/*Google Map */}
                <iframe
                  title="Moratuwa Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11737.889138083436!2d79.8862902880493!3d6.789179330366131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2450af2b3b63d%3A0x4bd5b87e09abb3c7!2sMoratuwa!5e0!3m2!1sen!2slk!4v1691243457967!5m2!1sen!2slk"
                  allowFullScreen
                  loading="lazy"
                  className=" rounded-[8px] w-[90%] h-[600px] max-sm:h-[300px] max-[500px]:h-[300px] max-md:h-[400px] max-lg:h-[500px]  mx-[5%]"
                ></iframe>

                {/* 1 row */}
                <div className="grid mt-6 sm:grid-cols-2 md:mr-16 lg:mr-28">
                  <div>
                    <div className="flex justify-center text-white sm:justify-end">
                      <LocationIcon />
                      Address
                      <span className=" sm:px-6"> :</span>
                    </div>
                  </div>
                  <div className="text-center text-white w-[80%] mx-[10%] sm:text-left">
                    77/5/A/1 Gothami Mawatha, Welewaththa, Wallampitiya,
                    Colombo, Sri Lanka
                  </div>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
    );
};

export default ContactSection3;
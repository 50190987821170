import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import UiUxTeam from "../../Images/AboutImages/Section4/UiUxTeam.webp";
import FrontEndTeam from "../../Images/AboutImages/Section4/FrontEndTeam.webp";
import BackEndTeam from "../../Images/AboutImages/Section4/BackEndTeam.webp";
import DevOpsTeam from "../../Images/AboutImages/Section4/DevOpsTeam.webp";
import PmBaTeam from "../../Images/AboutImages/Section4/ProjectManagementTeam.webp";
import AiMlTeam from "../../Images/AboutImages/Section4/AiMlTeam.webp";
import PreviousButton from "../../Images/HomeImages/Section5/PreviousButton.png";
import BackButton from "../../Images/HomeImages/Section5/BackButton.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import BgImage from "../../Images/AboutImages/Section3/Section2Background.webp";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const AboutSection4 = () => {
  const [counterOn, setConterOn] = useState(false);
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const sliderRef = useRef(null);

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      console.log("Slider component is mounted");
    }
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.1)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div
            style={{
              backgroundImage: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <section>
                <div
                  style={{
                    marginBottom: counterOn ? "-80px" : "0",
                    marginTop: "",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text text={"ZYNOVATEX teAM"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
              </section>
            </ScrollTrigger>
            <div className="text-center mt-2 text-white text-[49px] font-bold font-['Poppins'] uppercase leading-tight">
              The amazing team of us
            </div>
            <div className="pb-20 mt-20 text-center text-white">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                <Link to="/uiuxteam">
                  {/* card 1 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%] rounded-xl ">
                      <img
                        src={UiUxTeam}
                        alt="uiuxteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] text-center text-white lg:text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        UI/UX
                        <br />
                        Designing Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/frontteam">
                  {/* card 2 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%]   rounded-xl  ">
                      <img
                        src={FrontEndTeam}
                        alt="frontendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        front-End
                        <br /> Development Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Building robust and scalable software solutions tailored
                        to Our Customers specific requirements, functionality
                        and reliability.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/backendteam">
                  {/* card 3 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%]   rounded-xl ">
                      <img
                        src={BackEndTeam}
                        alt="backendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] px-[10%] text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        Back-End
                        <br />
                        Development Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Crafting dynamic and responsive websites that showcase
                        Our brand, provide an exceptional user experience.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/devopsteam">
                  {/* card 4 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%]  rounded-xl ">
                      <img
                        src={DevOpsTeam}
                        alt="devopteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        DevOps
                        <br />
                        Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/pmbateam">
                  {/* card 5 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%]  rounded-xl  ">
                      <img
                        src={PmBaTeam}
                        alt="pmbateam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        PM / BA
                        <br />
                        Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/aimlteam">
                  {/* card 6 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[10%]   rounded-xl  ">
                      <img
                        src={AiMlTeam}
                        alt="aimlteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-300px] text-center text-white text-[25px] font-semibold font-['Poppins'] capitalize leading-loose">
                        AI / ML
                        <br />
                        Team
                      </div>
                      <div className="pb-12 text-center text-white text-[15px] font-normal font-['Poppins'] capitalize leading-snug px-[20%]">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Add more cards as needed */}
              </Slider>
              <div>
                <div className="absolute transform -translate-y-1/2 mt-[-250px] left-24 ">
                  <button onClick={previousSlide}>
                    <img
                      src={BackButton}
                      alt="Previous"
                      style={{
                        ...(isLinkedinHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsLinkedinHovered(true)}
                      onMouseLeave={() => setIsLinkedinHovered(false)}
                    />
                  </button>
                </div>
                <div className="absolute mt-[-250px] transform -translate-y-1/2 right-28">
                  <button onClick={nextSlide}>
                    <img
                      src={PreviousButton}
                      alt="Next"
                      style={{
                        ...(isTikTokHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsTikTokHovered(true)}
                      onMouseLeave={() => setIsTikTokHovered(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div
            style={{
              backgroundImage: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <section>
                <div
                  style={{
                    marginBottom: counterOn ? "-80px" : "0",
                    marginTop: "",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text text={"ZYNOVATEX teAM"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
              </section>
            </ScrollTrigger>
            <div className="text-center mt-10 text-white text-3xl font-bold font-['Poppins'] uppercase leading-tight">
              The amazing team of us
            </div>
            <div className="pb-24 text-center text-white mt-14">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                <Link to="/uiuxteam">
                  {/* card 1 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]  rounded-xl  ">
                      <img
                        src={UiUxTeam}
                        alt="uiuxteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        UI/UX
                        <br />
                        Designing Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/frontteam">
                  {/* card 2 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl">
                      <img
                        src={FrontEndTeam}
                        alt="frontendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        Front-End
                        <br />
                        Development Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Building robust and scalable software solutions tailored
                        to Our Customers specific requirements, functionality
                        and reliability.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/backendteam">
                  {/* card 3 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl  ">
                      <img
                        src={BackEndTeam}
                        alt="backendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize ">
                        Back-End
                        <br />
                        Development Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Crafting dynamic and responsive websites that showcase
                        Our brand, provide an exceptional user experience.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/devopsteam">
                  {/* card 4 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl ">
                      <img
                        src={DevOpsTeam}
                        alt="devopsteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        DevOps
                        <br />
                        Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/pmbateam">
                  {/* card 5 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl ">
                      <img
                        src={PmBaTeam}
                        alt="pmbateam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        PM / BA
                        <br />
                        Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/aimlteam">
                  {/* card 6 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl   ">
                      <img
                        src={AiMlTeam}
                        alt="aimlteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-220px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        AI / ML
                        <br />
                        Team
                      </div>
                      <div className="text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal pb-10">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Add more cards as needed */}
              </Slider>
              <div>
                <div className="absolute w-10 mt-5 left-36 ">
                  <button onClick={previousSlide}>
                    <img
                      src={BackButton}
                      alt="Previous"
                      style={{
                        ...(isLinkedinHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsLinkedinHovered(true)}
                      onMouseLeave={() => setIsLinkedinHovered(false)}
                    />
                  </button>
                </div>
                <div className="absolute w-10 mt-5 transform right-36 ">
                  <button onClick={nextSlide}>
                    <img
                      src={PreviousButton}
                      alt="Next"
                      style={{
                        ...(isTikTokHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsTikTokHovered(true)}
                      onMouseLeave={() => setIsTikTokHovered(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div
            style={{
              backgroundImage: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <section>
                <div
                  style={{
                    marginBottom: counterOn ? "-80px" : "0",
                    marginTop: "",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text text={"ZYNOVATEX teAM"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
              </section>
            </ScrollTrigger>
            <div className="text-center mt-14 text-white text-xl font-bold font-['Poppins'] uppercase leading-tight">
              The amazing team of us
            </div>
            <div className="pb-24 mt-10 text-center text-white">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                <Link to="/uiuxteam">
                  {/* card 1 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]  rounded-xl">
                      <img
                        src={UiUxTeam}
                        alt="uiuxteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        UI/UX
                        <br />
                        Designing Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/frontteam">
                  {/* card 2 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl">
                      <img
                        src={FrontEndTeam}
                        alt="frontendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        Front-End
                        <br />
                        Development Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Building robust and scalable software solutions tailored
                        to Our Customers specific requirements, functionality
                        and reliability.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/backendteam">
                  {/* card 3 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%] rounded-xl">
                      <img
                        src={BackEndTeam}
                        alt="backendteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        Back-End
                        <br />
                        Development Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Crafting dynamic and responsive websites that showcase
                        Our brand, provide an exceptional user experience.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/devopsteam">
                  {/* card 4 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%] rounded-xl">
                      <img
                        src={DevOpsTeam}
                        alt="devopsteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        DevOps
                        <br />
                        Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/pmbateam">
                  {/* card 5 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]   rounded-xl  ">
                      <img
                        src={PmBaTeam}
                        alt="pmbateam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        PM / BA
                        <br />
                        Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/aimlteam">
                  {/* card 6 */}
                  <div>
                    <div className="h-[100%] w-[90%] mx-[5%]  rounded-xl  ">
                      <img
                        src={AiMlTeam}
                        alt="aimlteam"
                        className="w-full rounded-xl"
                      />
                      <div className=" mt-[-250px]  text-center text-white text-[20px] font-semibold font-['Poppins'] capitalize leading-loose">
                        AI / ML
                        <br />
                        Team
                      </div>
                      <div className="pb-14 text-center w-[80%] mx-[10%] text-white text-[15px] font-normal font-['Poppins'] capitalize leading-normal">
                        Creating engaging and intuitive user interfaces and
                        experiences that captivate Our audience and enhance user
                        satisfaction.
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Add more cards as needed */}
              </Slider>
              <div>
                <div className="absolute w-10 mt-5 left-36 ">
                  <button onClick={previousSlide}>
                    <img
                      src={BackButton}
                      alt="Previous"
                      style={{
                        ...(isLinkedinHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsLinkedinHovered(true)}
                      onMouseLeave={() => setIsLinkedinHovered(false)}
                    />
                  </button>
                </div>
                <div className="absolute w-10 mt-5 transform right-36 ">
                  <button onClick={nextSlide}>
                    <img
                      src={PreviousButton}
                      alt="Next"
                      style={{
                        ...(isTikTokHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsTikTokHovered(true)}
                      onMouseLeave={() => setIsTikTokHovered(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutSection4;

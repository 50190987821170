import React, { useState } from "react";
import Slider from "react-slick";
import ProjectImage1 from "../Images/ProjectImages/Section2/ProjectImage1.webp";
import ProjectImage2 from "../Images/ProjectImages/Section2/ProjectImage2.webp";
import ProjectImage3 from "../Images/ProjectImages/Section2/ProjectImage3.webp";
import ProjectImage4 from "../Images/ProjectImages/Section2/ProjectImage4.webp";
import { LiaDotCircle } from "react-icons/lia";
import { Link } from "react-router-dom";

const ReactSlick2 = () => {
  const baseIconSize = 25;
  const largerIconSize = 30;
  const dotMarginTop = 20;
  const activeColor = "#fff"; 
  const inactiveColor = "#FFFFFF80";
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
    customPaging: function (i) {
      const size = i === currentSlide ? largerIconSize : baseIconSize;
      const color = i === currentSlide ? activeColor : inactiveColor;
      return (
        <div style={{ marginTop: `${dotMarginTop}px` }}>
          <LiaDotCircle size={size} color={color} />
        </div>
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div>
      <div className="text-center text-white">
        <Slider {...settings} className="flex justify-center">
          {/* card1 */}
          <Link to="/project01">
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]  hover:bg-[#fff]/[.29]">
                <img
                  src={ProjectImage3}
                  alt="teammember1"
                  className="w-full rounded-xl"
                />
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                  CupidCo
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  CupidCo is our bespoke wedding proposal website, providing a
                  personalized platform for couples to create and share their
                  unique love stories. It's designed to make proposals
                  unforgettable by combining technology with emotion.
                </div>
              </div>
            </div>
          </Link>

          {/* card2 */}
          <Link to="/project02">
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18] hover:bg-[#fff]/[.29]">
                <img
                  src={ProjectImage1}
                  alt="teammember1"
                  className="w-full rounded-xl"
                />
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                  POS System
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Are you seeking a comprehensive and efficient Point of Sale
                  (POS) system that seamlessly integrates essential features for
                  your business management? Look no further! Our POS system
                  offers a user-friendly experience with a myriad of
                  functionalities to streamline your operations.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/project03">
            {/* card3 */}
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18] hover:bg-[#fff]/[.29]">
                <img
                  src={ProjectImage4}
                  alt="teammember1"
                  className="w-full rounded-xl"
                />
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                  UNIVERSITY PROJECTS
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Our expertise in university projects encompasses a wide array
                  of AI and ML applications, offering innovative solutions
                  tailored to academic requirements. From data analysis to
                  algorithm implementation, we bring academic projects to life.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/project04">
            {/* card4 */}
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18] hover:bg-[#fff]/[.29]">
                <img
                  src={ProjectImage2}
                  alt="teammember1"
                  className="w-full rounded-xl"
                />
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                  WEBSITES
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Our website development service focuses on creating dynamic,
                  responsive, and user-friendly websites tailored to your
                  specific needs. From sleek corporate sites to engaging
                  e-commerce platforms, we ensure an online presence that
                  captivates your audience.
                </div>
              </div>
            </div>
          </Link>
          {/* card5*/}
          {/* <div>
            <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]">
              <img
                src={ProjectImage1}
                alt="teammember1"
                className="w-full rounded-xl"
              />
              <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                Travel Planner Platform
              </div>
              <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                Delve into a showcase of our UI/UX team's exceptional work,
                spanning diverse projects that exemplify their creativity and
                expertise. Explore designs that redefine digital interactions
                and elevate user experiences.
              </div>
            </div>
          </div> */}

          {/* card6 */}
          {/* <div>
            <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]">
              <img
                src={ProjectImage1}
                alt="teammember1"
                className="w-full rounded-xl"
              />
              <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                Travel Planner Platform
              </div>
              <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                Delve into a showcase of our UI/UX team's exceptional work,
                spanning diverse projects that exemplify their creativity and
                expertise. Explore designs that redefine digital interactions
                and elevate user experiences.
              </div>
            </div>
          </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default ReactSlick2;

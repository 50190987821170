import React, { useState, useEffect } from "react";
import BgImage from "../../Images/BlogSubmissionImages/Section2/Section2Background.webp";
import { Typography } from "@material-tailwind/react";
import { InputText } from "primereact/inputtext";

const BlogSubmissionSection2 = () => {
    const [deviceView, setDeviceView] = useState('web');
    const [resumeFile, setResumeFile] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setDeviceView('mobile');
            } else if (width <= 1024) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResumeChange = (e) => {
        const file = e.target.files[0];
        setResumeFile(file);
    };

    const scrollToTop = () => {
        // Scroll smoothly to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        scrollToTop();
    }, []);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked); // Toggle checkbox state
    };

    const MenuItems = [
        { label: 'Everyone', value: 'Everyone' },
        { label: 'Sample2', value: 'sample2' },
        { label: 'Sample3', value: 'sample3' },
    ];

    return (
        <div style={{ fontFamily: 'Poppins' }}>
            {deviceView === 'web' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h3" color="white" className="mb-8 tracking-wide uppercase">
                                Share Your Insights With Us
                            </Typography>

                            <div className="card w-[60%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">


                                    <Typography variant="h6" color="white" className="mt-4 mb-4">
                                        Blog Title
                                    </Typography>
                                    <InputText
                                        id="BTitle"
                                        type="text"
                                        className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        placeholder="Title"
                                    />

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Upload Your Content
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Cover Image (Optional)
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <Typography variant="h6" color="white" className="mt-10 mb-4">
                                        Blog Tags
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        width="15px"
                                        placeholder="Add Your Tags"
                                    />

                                    <Typography variant="h6" color="white" className="mt-10 mb-6">
                                        How Would You Categorize This Project (Required)
                                    </Typography>
                                    <div className="flex text-[15px] text-white gap-1">
                                        {/* First Column */}
                                        <div className="flex flex-col space-y-6">
                                            <label>
                                                <input type="checkbox" className="mr-2 cursor-pointer w-9" />
                                                Technology Trends
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 cursor-pointer w-9" />
                                                Product Updates
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 cursor-pointer w-9" />
                                                Team & Culture
                                            </label>
                                        </div>

                                        {/* Second Column */}
                                        <div className="flex flex-col ml-8 space-y-6">
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Design & User Experience
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Industry Insights
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Leadership & Professional Growth
                                            </label>
                                        </div>

                                        {/* Third Column */}
                                        <div className="flex flex-col ml-8 space-y-6">
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Development & Coding
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Customer Success Stories
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-2 w-9" />
                                                Technology & Society
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Blog Visibility
                                        </Typography>
                                        {/* Dropdown Menu */}
                                        <select
                                            defaultValue={MenuItems[0].value}
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            style={{ width: '50%', height: '40px', marginRight: '10px', fontFamily: 'Poppins', fontSize: '14px' }}>
                                            <option value=""></option>
                                            {MenuItems.map((item, index) => (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div id="checkBox" className="flex items-center mt-7">
                                        {/* Transparent checkbox with conditional OK icon */}
                                        <input
                                            type="checkbox"
                                            id="transparentCheckbox"
                                            className="hidden"
                                            defaultChecked={isChecked}
                                            onChange={toggleCheckbox}
                                        />
                                        <label
                                            htmlFor="transparentCheckbox"
                                            className="flex items-center cursor-pointer"
                                        >
                                            <div className="mt-1 mr-2 icon-container">
                                                {isChecked ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6 p-1 text-white border-2 border-white rounded-md inline-b max-sm:h-5 max-sm:w-5 lock"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={6}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="inline-block w-6 h-6 p-1 border-2 border-gray-500 rounded-md max-sm:h-5 max-sm:w-5"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            {/* checkbox content */}
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mt-4 mb-2 text-[14px] cursor-default"
                                            >
                                                We care about protecting your data. Here's our Privacy Policy{''}
                                                {/* <Link
                                                    to="/"
                                                    target="_blank"  // Use target="_blank" to open the link in a new tab
                                                    className="text-gray-500 cursor-pointer hover:text-gray-400"
                                                >
                                                    
                                                </Link> */}
                                            </Typography>
                                        </label>
                                    </div>

                                    <div className="flex justify-center gap-[10%]">
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.07] block hover:scale-105">
                                            Cancel
                                        </button>
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-white bg-[#1F2D6E] block hover:scale-105">
                                            Publish
                                        </button>
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.07] block hover:scale-105">
                                            Save as Draft
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {deviceView === 'tablet' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h3" color="white" className="mb-8 tracking-wide uppercase">
                                Share Your Insights With Us
                            </Typography>

                            <div className="card w-[60%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">


                                    <Typography variant="h6" color="white" className="mt-4 mb-4">
                                        Blog Title
                                    </Typography>
                                    <InputText
                                        id="BTitle"
                                        type="text"
                                        className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        placeholder="Title"
                                    />

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Upload Your Content
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Cover Image (Optional)
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <Typography variant="h6" color="white" className="mt-10 mb-4">
                                        Blog Tags
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        width="15px"
                                        placeholder="Add Your Tags"
                                    />

                                    <Typography variant="h6" color="white" className="mt-10 mb-6">
                                        How Would You Categorize This Project (Required)
                                    </Typography>
                                    <div className="flex text-[12px] text-white gap-1">
                                        {/* First Column */}
                                        <div className="flex flex-col space-y-6">
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1 cursor-pointer" />
                                                Technology Trends
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1 cursor-pointer" />
                                                Product Updates
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1 cursor-pointer" />
                                                Team & Culture
                                            </label>
                                        </div>

                                        {/* Second Column */}
                                        <div className="flex flex-col ml-8 space-y-6">
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Design & User Experience
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Industry Insights
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Leadership & Professional Growth
                                            </label>
                                        </div>

                                        {/* Third Column */}
                                        <div className="flex flex-col ml-8 space-y-6">
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Development & Coding
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Customer Success Stories
                                            </label>
                                            <label>
                                                <input type="checkbox" className="w-8 mr-1" />
                                                Technology & Society
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Blog Visibility
                                        </Typography>
                                        {/* Dropdown Menu */}
                                        <select
                                            defaultValue={MenuItems[0].value}
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            style={{ width: '50%', height: '40px', marginRight: '10px', fontFamily: 'Poppins', fontSize: '14px' }}>
                                            <option value=""></option>
                                            {MenuItems.map((item, index) => (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div id="checkBox" className="flex items-center mt-7">
                                        {/* Transparent checkbox with conditional OK icon */}
                                        <input
                                            type="checkbox"
                                            id="transparentCheckbox"
                                            className="hidden"
                                            defaultChecked={isChecked}
                                            onChange={toggleCheckbox}
                                        />
                                        <label
                                            htmlFor="transparentCheckbox"
                                            className="flex items-center cursor-pointer"
                                        >
                                            <div className="mt-1 mr-2 icon-container">
                                                {isChecked ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6 p-1 text-white border-2 border-white rounded-md inline-b max-sm:h-5 max-sm:w-5 lock"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={6}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="inline-block w-6 h-6 p-1 border-2 border-gray-500 rounded-md max-sm:h-5 max-sm:w-5"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            {/* checkbox content */}
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mt-4 mb-2 text-[14px] cursor-default"
                                            >
                                                We care about protecting your data. Here's our Privacy Policy{''}
                                                {/* <Link
                                                    to="/"
                                                    target="_blank"  // Use target="_blank" to open the link in a new tab
                                                    className="text-gray-500 cursor-pointer hover:text-gray-400"
                                                >
                                                    
                                                </Link> */}
                                            </Typography>
                                        </label>
                                    </div>

                                    <div className="flex justify-center gap-[10%]">
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.07] block hover:scale-105">
                                            Cancel
                                        </button>
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-white bg-[#1F2D6E] block hover:scale-105">
                                            Publish
                                        </button>
                                        <button className="w-[200px] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.07] block hover:scale-105">
                                            Save as Draft
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {deviceView === 'mobile' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h5" color="white" className="mb-8 tracking-wide text-center uppercase w-[80%]">
                                Share Your Insights With Us
                            </Typography>

                            <div className="card w-[60%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">

                                    <Typography variant="h6" color="white" className="mt-4 mb-4">
                                        Blog Title
                                    </Typography>
                                    <InputText
                                        id="BTitle"
                                        type="text"
                                        className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        placeholder="Title"
                                    />

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Upload Your Content
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Cover Image (Optional)
                                        </Typography>
                                        <InputText
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            type="file"
                                            id="ContentInput"
                                            onChange={handleResumeChange}
                                            style={{ height: '150px' }}
                                        />
                                    </div>

                                    <Typography variant="h6" color="white" className="mt-10 mb-4">
                                        Blog Tags
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                        width="15px"
                                        placeholder="Add Your Tags"
                                    />

                                    <Typography variant="h6" color="white" className="mt-10 mb-6">
                                        How Would You Categorize This Project (Required)
                                    </Typography>
                                    <div className="flex text-[14px] text-white gap-1">
                                        {/* Column */}
                                        <div className="flex flex-col space-y-6">
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Technology Trends
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Product Updates
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Team & Culture
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Design & User Experience
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Industry Insights
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Leadership & Professional Growth
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Development & Coding
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Customer Success Stories
                                            </label>
                                            <label>
                                                <input type="checkbox" className="mr-1 w-9" />
                                                Technology & Society
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography variant="h6" color="white" className="mt-10 mb-4">
                                            Blog Visibility
                                        </Typography>
                                        {/* Dropdown Menu */}
                                        <select
                                            defaultValue={MenuItems[0].value}
                                            className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                            style={{ width: '100%', height: '40px', marginRight: '10px', fontFamily: 'Poppins', fontSize: '14px' }}>
                                            <option value=""></option>
                                            {MenuItems.map((item, index) => (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div id="checkBox" className="flex items-center mt-7">
                                        {/* Transparent checkbox with conditional OK icon */}
                                        <input
                                            type="checkbox"
                                            id="transparentCheckbox"
                                            className="hidden"
                                            defaultChecked={isChecked}
                                            onChange={toggleCheckbox}
                                        />
                                        <label
                                            htmlFor="transparentCheckbox"
                                            className="flex items-center cursor-pointer"
                                        >
                                            <div className="mt-1 mr-2 icon-container">
                                                {isChecked ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6 p-1 text-white border-2 border-white rounded-md inline-b max-sm:h-5 max-sm:w-5 lock"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={6}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="inline-block w-6 h-6 p-1 border-2 border-gray-500 rounded-md max-sm:h-5 max-sm:w-5"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            {/* checkbox content */}
                                            <Typography
                                                variant="h7"
                                                color="white"
                                                className="mt-4 mb-2 text-[14px] cursor-default"
                                            >
                                                We care about protecting your data. Here's our Privacy Policy{''}
                                                {/* <Link
                                                    to="/"
                                                    target="_blank"  // Use target="_blank" to open the link in a new tab
                                                    className="text-gray-500 cursor-pointer hover:text-gray-400"
                                                >
                                                    
                                                </Link> */}
                                            </Typography>
                                        </label>
                                    </div>

                                    <div className="flex justify-center">
                                        <button className="w-[100%] text-[14px] flex-initial px-8 py-2 mt-10 mx-2 text-white text-md rounded-[5px] border-1 border-white bg-[#1F2D6E] block hover:scale-105">
                                            Publish
                                        </button>
                                    </div>
                                    
                                    <div className="flex justify-center">
                                        <button className="w-[100%] text-[14px] flex-initial px-8 py-2 mt-5 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.05] block hover:scale-105">
                                            Cancel
                                        </button>
                                    </div>


                                    <div className="flex justify-center">
                                        <button className="w-[100%] text-[14px] flex-initial px-8 py-2 mt-5 mx-2 text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#FF0000]/[.05] block hover:scale-105">
                                            Save as Draft
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogSubmissionSection2;

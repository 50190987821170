import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import ArlicleImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/JavaImage.png";

const fontFamily = "Poppins";

const JavaSec02 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Java:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          From Humble Beginnings to Brewing a Tech Empire
        </span>

        {/* description */}
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Java, the ubiquitous programming language, is more than just lines
            of code; it's a story of innovation, adaptation, and caffeine-fueled
            brilliance. In this blog post, we'll brew a cup of Java's rich
            history and delve into the fundamental aspects that make it one of
            the most popular programming languages today.
          </div>
          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            A Bean Sprouts:
          </span>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            The Java Origin Story
          </span>
          <div className=" mt-4 text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Imagine a world before smartphones, the internet booming, and sleek
            laptops. In 1991, a team at Sun Microsystems, led by the legendary
            James Gosling, envisioned a language for interactive devices like
            set-top boxes and televisions. This nascent project, initially
            called "GreenTalk" (with the file extension ".gt"!), aimed for
            simplicity, robustness, and portability across platforms.
          </div>

          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Soon, "Oak" sprouted from GreenTalk, named after the majestic oak
            tree outside Gosling's office. However, due to trademark issues, a
            brainstorming session fueled by, you guessed it, coffee, led to the
            name "Java" – a tribute to the team's favorite caffeinated beverage.
          </div>
        </div>

        {/* blog second Images */}
        <div className="flex justify-center mt-10">
          <img src={ArlicleImage} alt="BlgImage" />
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* Second description */}
        <div className="mt-8">
          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            From Oak to Espresso:
          </span>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            Java Brews a Web Presence
          </span>
          <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            The early 1990s witnessed the rise of the World Wide Web, and Java
            saw an unexpected opportunity. Its focus on portability and security
            made it ideal for applets, small programs embedded within web pages.
            Netscape Navigator, a dominant browser at the time, integrated Java,
            and suddenly, interactive web experiences like stock tickers and
            games became a reality.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            This pivotal moment catapulted Java into the spotlight. Its "Write
            Once, Run Anywhere" motto resonated with developers, and Java became
            a mainstay in web development.
          </div>
          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            Beyond the Web:
          </span>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            Java's Diverse Brew
          </span>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            While Java's web presence remains strong, its reach extends far
            beyond. Today, it's the backbone of countless enterprise
            applications, powering everything from e-commerce platforms to
            scientific simulations. Android, the world's most popular mobile
            operating system, is built on Java's foundation.
          </div>
          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            Java's versatility lies in its core principles:
          </span>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            01. Object-oriented: Objects encapsulate data and behavior, leading
            to modular and reusable code.
            <br />
            <br />
            o2. Platform-independent: Java bytecode runs on any system with a
            Java Virtual Machine (JVM), ensuring code portability.
            <br />
            <br />
            03. Secure: Java's built-in security features mitigate common
            vulnerabilities.
            <br />
            <br />
            04. Robust: Automatic memory management and exception handling make
            Java programs less prone to errors.
          </div>

          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            Learning Java:
          </span>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            Your First Sip
          </span>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Intrigued by Java's rich history and diverse applications? The good
            news is, getting started with Java is easier than ever! Numerous
            online resources, tutorials, and interactive platforms like
            Codecademy and Coursera offer beginner-friendly introductions.
            Remember, just like brewing a perfect cup of coffee, mastering Java
            takes practice and patience.
          </div>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            A Toast to the Future
          </span>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            With over 25 years of evolution, Java continues to adapt and
            innovate. New versions focus on performance improvements, enhanced
            security features, and support for cutting-edge technologies like
            machine learning and cloud computing. So, whether you're a seasoned
            developer or a curious newbie, consider taking a sip of Java. You
            might just discover a language that's not just powerful and
            versatile, but also steeped in a fascinating history of innovation
            and caffeine-fueled brilliance.
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JavaSec02;

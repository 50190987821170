import React from 'react'
import AboutSection1 from '../Component/Pages/AboutPage/AboutSection1';
import HomeSection2 from '../Component/Pages/HomePage/HomeSection2';
import AboutSection2 from '../Component/Pages/AboutPage/AboutSection2';
import AboutSection3 from '../Component/Pages/AboutPage/AboutSection3';
import AboutSection4 from '../Component/Pages/AboutPage/AboutSection4';
import SocialMedia from '../Component/Common/SocialMedia';


const About = () => {
  return (
    <div>
      <AboutSection1/>
      <AboutSection2/>
      <AboutSection3/>
      <AboutSection4/>
      <HomeSection2/>
      <SocialMedia/>
    </div>
  )
}

export default About;

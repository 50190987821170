import React from 'react'
import Section01 from "./Section01";
import Section02 from "./Section02";
import Section03 from "../common/Section3";

const Project02 = () => {
  return (
    <div>
      <Section01/>
      <Section02/>
      <Section03/>
    </div>
  )
}

export default Project02

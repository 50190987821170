import React from 'react'
import Section1 from './FrontendSections/Section1';
import Section2 from './FrontendSections/Section2';
import Section3 from './FrontendSections/Section3';
import Section4 from './FrontendSections/Section4';
import Section5 from './FrontendSections/Section5';



const FrontendTeam = () => {
    return (
        <div>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </div>
    )
}

export default FrontendTeam

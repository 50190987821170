import React from "react";
import Sec01 from "./Sec01";
import Sec02 from "./Sec02";

const BlogGemini = () => {
  return (
    <div>
      <Sec01 />
      <Sec02/>
    </div>
  );
};

export default BlogGemini;

import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import ProfileImage1 from "../Images/TeamsImages/ProfileImage1.webp";
import ProfileImage2 from "../Images/TeamsImages/ProfileImage2.webp";
import ProfileImage3 from "../Images/TeamsImages/ProfileImage3.webp";
import ProfileImage4 from "../Images/TeamsImages/ProfileImage4.webp";
import ProfileImage5 from "../Images/TeamsImages/ProfileImage5.webp";
import PreviousButton from "../Images/HomeImages/Section5/PreviousButton.png";
import BackButton from "../Images/HomeImages/Section5/BackButton.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa6";

const ReactSlick5 = () => {
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const sliderRef = useRef(null);

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 425) {
        setDeviceView("mobile");
      } else if (width <= 768) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      console.log("Slider component is mounted");
    }
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.1)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="mt-10 text-center text-white w-[80%] mx-[10%]">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage3} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Mr. akila and his team are very talented people with good
                    skills. They get the work Done on time and very successfully
                    and confidently. Highly recommended for any kind of hard
                    task.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base">
                    Nayoli
                  </div>
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage2} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    A committed, diligent team can successfully. Highly
                    recommended for any difficult work.
                    <br />
                    <br />
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-14">
                    Miss Siriwardana
                  </div>
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage1} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila and his team highlight their exceptional skills in
                    Convolutional Neural Networks (CNN) and Machine Learning.
                    <br />
                    <br />
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-7">
                    Mr Dalugama
                  </div>
                </div>
              </div>

              {/* card 4 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage5} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    I highly recommend Zynovatex. The team was not only
                    professional but also incredibly friendly and attentive to
                    my needs.
                    <br />
                    <br />
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-7">
                    Shelomi Dewsirini
                  </div>
                </div>
              </div>

              {/* card 5 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage4} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila is a dedicated engineer and has the capacity to work
                    in any domain and adapt to any technology.
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base max-[1024px]:pb-6">
                    Ruwan Liyanage <br />
                    Software Engineer at Hsenid Japan
                  </div>
                </div>
              </div>

              {/* Add more cards as needed */}
            </Slider>
            <div className="mt-5">
              <div className="absolute transform -translate-y-1/4 mt-[-220px] left-24 md:left-[120px] lg:left-[200px] max-sm:left-10 max-sm:w-10 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute mt-[-220px] transform -translate-y-1/4 right-24  md:right-[120px] lg:right-[200px] max-sm:right-10 max-sm:w-10">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="mt-10 text-center text-white w-[80%] mx-[10%]">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage3} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Mr. akila and his team are very talented people with good
                    skills. They get the work Done on time and very successfully
                    and confidently. Highly recommended for any kind of hard
                    task.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base">
                    Nayoli
                  </div>
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage2} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    A committed, diligent team can successfully. Highly
                    recommended for any difficult work.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-20">
                    Miss Siriwardana
                  </div>
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage1} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila and his team highlight their exceptional skills in
                    Convolutional Neural Networks (CNN) and Machine Learning.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-14">
                    Mr Dalugama
                  </div>
                </div>
              </div>

              {/* card 4 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage5} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    I highly recommend Zynovatex. The team was not only
                    professional but also incredibly friendly and attentive to
                    my needs.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-14">
                    Shelomi Dewsirini
                  </div>
                </div>
              </div>

              {/* card 5 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage4} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[80%] mx-[10%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila is a dedicated engineer and has the capacity to work
                    in any domain and adapt to any technology.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-14">
                    Ruwan Liyanage <br />
                    Software Engineer at Hsenid Japan
                  </div>
                </div>
              </div>

              {/* Add more cards as needed */}
            </Slider>
            <div className="mt-5">
              <div className="absolute transform -translate-y-1/4 mt-[-220px] left-24 md:left-[120px] lg:left-[200px] max-sm:left-10 max-sm:w-10 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute mt-[-220px] transform -translate-y-1/4 right-24  md:right-[120px] lg:right-[200px] max-sm:right-10 max-sm:w-10">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="mt-10 pb-20 text-center text-white w-[100%] sm:w-[90%] sm:mx-[5%]">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage3} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[100%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Mr. akila and his team are very talented people with good
                    skills. They get the work Done on time and very successfully
                    and confidently. Highly recommended for any kind of hard
                    task.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base">
                    Nayoli
                  </div>
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px]">
                  <img src={ProfileImage2} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[100%%]  pt-20 pb-16 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    A committed, diligent team can successfully. Highly
                    recommended for any difficult work.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-[73px]">
                    Miss Siriwardana
                  </div>
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage1} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[100%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila and his team highlight their exceptional skills in
                    Convolutional Neural Networks (CNN) and Machine Learning.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-12">
                    Mr Dalugama
                  </div>
                </div>
              </div>

              {/* card 4 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage5} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[100%] pt-20 pb-10 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    I highly recommend Zynovatex. The team was not only
                    professional but also incredibly friendly and attentive to
                    my needs.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-12">
                    Shelomi Dewsirini
                  </div>
                </div>
              </div>

              {/* card 5 */}
              <div>
                <div className="flex items-center justify-center mb-[-80px] ">
                  <img src={ProfileImage4} alt="teammember1" className="" />
                </div>
                <div className="h-[100%] w-[100%] pt-20 pb-5 rounded-xl   border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <span className="flex justify-center gap-1 mt-5 text-yellow-400">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </span>
                  <div className="w-[80%] mx-[10%] mt-4 text-justify text-lg max-sm:text-base">
                    Akila is a dedicated engineer and has the capacity to work
                    in any domain and adapt to any technology.
                  </div>
                  <div className="mt-4 text-center text-white text-lg font-bold font-['Poppins'] capitalize leading-[27px] max-sm:text-base pb-12">
                    Ruwan Liyanage <br />
                    Software Engineer at Hsenid Japan
                  </div>
                </div>
              </div>

              {/* Add more cards as needed */}
            </Slider>
            <div>
              <div className="absolute w-10 mt-5 left-36 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute w-10 mt-5 transform right-36 ">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReactSlick5;

import React from 'react'
import BackendSection1 from "../../CareerViewPages/FrontEndVacancies/FrontEndSection1";
import CareerApplySection2 from "../careerApplySection2";

const FrontEndApply = () => {
  return (
    <div>
      <BackendSection1 />
      <CareerApplySection2 />
    </div>
  );
}

export default FrontEndApply

import React, { useState, useEffect, useRef } from 'react';
import Section3Backgroud from '../../Images/BlogImages/Section1Background.webp';
import Img1 from '../../Images/BlogImages/ReadMoreImages/Section1/GeminiImage.jpg';
import Img2 from '../../Images/BlogImages/ReadMoreImages/Section1/UiUxImage.webp';
import Img3 from '../../Images/BlogImages/CardImages/Blog3.webp';
import Img4 from "../../Images/BlogImages/ReadMoreImages/Section1/MarketingImage.png";
import Img5 from "../../Images/BlogImages/ReadMoreImages/Section1/ReactNativeImage.png";
import Img6 from "../../Images/BlogImages/ReadMoreImages/Section1/JavaImage.png";
import Img7 from "../../Images/BlogImages/ReadMoreImages/Section1/UiUxImage.png";

import '../../Assests/BlogStyle.css';
import { Paginator } from 'primereact/paginator';
import { Link } from 'react-router-dom';

const BlogSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6); // Set the initial number of rows
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setDeviceView("mobile");
      } else if (width <= 768) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setActivePage(event.page);
  };

  const template3 = {
    layout: " PrevPageLink PageLinks ... NextPageLink",
  };

  const getGridCols = () => {
    switch (deviceView) {
      case "mobile":
        return "grid-cols-1";
      case "tablet":
        return "grid-cols-2";
      case "web":
      default:
        return "grid-cols-3";
    }
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const yourCardComponents = [
    {
      id: 1,
      image: Img1,
      title:
        "Gemini-The New AI Model Poised to Revolutionize Search and Creativity",
      category: "gemini",
      path: "/bloggemini",
    },
    {
      id: 2,
      image: Img4,
      title:
        "Leveraging Conversational Marketing in Digital Marketing Strategies",
      category: "Marketing",
      path: "/marketingblog",
    },
    {
      id: 3,
      image: Img6,
      title: "Java: From Humble Beginnings to Brewing a Tech Empire",
      category: "Java",
      path: "/javablog",
    },

    {
      id: 4,
      image: Img7,
      title:
        "Creating Integrated Digital Experiences, A deep dive into UI/UX Mastery",
      category: " UI/Ux",
      path: "/uiuxblog",
    },
    {
      id: 5,
      image: Img5,
      title: "Empowering Mobile Apps: The Fusion of AI and React Native",
      category: "React Native",
      path: "/reactnativeblog",
    },
    {
      id: 6,
      image: Img2,
      title: "Introduction to UI and UX",
      category: "UI/Ux",
      path: "/uiuxintroblog",
    },

    {
      id: 7,
      image: Img2,
      title:
        "Artificial Intelligence: Simulation of human intelligence in machine...",
      category: "Artificial Intelligence",
      path: "",
    },
    // Add more cards as needed
  ];

  const paginatorClassName = 'custom-paginator font-["Poppins"] text-white mb-10 text-lg';
  const pageLinkClassName = 'custom-page-link mx-2';

  return (
    <div className="relative">
      <div
        style={{
          backgroundImage: `url(${Section3Backgroud})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "full",
          height: "100%",
          padding: "10%",
          
        }}
      >
        <section>
          <div
            className={`grid 
          lg:grid-cols-3 
          md:grid-cols-2 
          min-[425px]:grid-cols-1
          
          
          lg:mt-10
          md:ml-2
          min-[425px]:ml-2
          mb-10
          `}
          >
            {yourCardComponents.slice(first, first + rows).map((card) => (
              <div
                key={card.id}
                className="glass_frame w-[90%] mx-[5%] h-auto rounded-[0.8125rem] border-[2px] border-[#fff]/[.20]  mb-10  "
              >
                <section>
                  <div>
                    <img
                      src={card.image}
                      alt={`${card.id}`}
                      className="w-full rounded-xl"
                    />
                  </div>
                  <div>
                    <p className=" text-white text-xl font-['Poppins'] font-semibold leading-[normal] pl-4 pt-4 md:w-auto max-md:text-sm md:leading-[normal] md:pr-2">
                      {card.title}
                    </p>
                    <p className=" text-white font-['Poppins'] text-sm mt-5 font-semibold leading-[normal] uppercase pl-4 max-md:text-[10px]">
                      {card.category}
                    </p>
                    <div className="flex items-center mb-8 max-sm:mt-5 ">
                      <Link to={card.path}>
                        <p className="text-white font-['Poppins'] font-semibold leading-[normal] uppercase mr-auto pl-4 mt-[-5px] md:leading-[normal] md:font-semibold md:mt-10 hover:text-blue-300">
                          Read now
                        </p>
                      </Link>
                      <div className="w-6 h-6">
                        <svg
                          width={9}
                          height={16}
                          viewBox="0 0 9 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="md:mt-[25px] ml-[30%]"
                        >
                          <path
                            d="M1.65625 15L6.98686 8.78095C7.37205 8.33156 7.37205 7.66844 6.98686 7.21905L1.65625 1"
                            stroke="#FCF8F8"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))}
          </div>
          <div className="paginator-container">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={120}
              onPageChange={onPageChange}
              activePage={activePage}
              className={paginatorClassName}
              pageLinkClassName={pageLinkClassName}
              template={template3}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default BlogSection1;

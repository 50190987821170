import React, { useState, useEffect } from "react";
import Section5Backgrouund from "../../Images/HomeImages/Section5/Section5Background.webp";
import ReackSlick4 from "../../Assests/ReactSlick4";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const HomeSection5 = () => {
  const fontFamily = "Poppins";
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []);

  return (
    <div>
      <div
        className="-[100%]  h-full bg-cover bg-center bg-no-repeat  pb-16 "
        style={{ backgroundImage: `url(${Section5Backgrouund})` }}
      >
        <ScrollTrigger
          onEnter={() => setConterOn(true)}
          onExit={() => setConterOn(false)}
        >
          <div style={{ marginBottom: "-64px" }}>
            {counterOn && (
              <div>
                <Text text={"feedbacks"} deviceView={deviceView} />
              </div>
            )}
          </div>
          <div className="text-center">
            <h1
              className="font-semibold text-white text-5xl max-md:text-2xl max-md:mt-9 max-lg:text-3xl max-lg:mt-10 mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
              style={{ fontFamily: fontFamily }}
            >
              What Our Clients Says
            </h1>
          </div>
        </ScrollTrigger>
        <div className="w-[80%] mx-[10%]">
          <ReackSlick4 />
        </div>
        <div className="px-[10%] text-base lg:w-[80%] text-white text-center font-['Poppins'] lg:text-[1.3125rem] font-medium leading-[156%] capitalize mx-auto pt-5 md:text-md ">
          Ensuring absolute customer delight is our pledge, achieved through savvy collaboration with our clients. We thrive on a communal exchange of ideas, 
          granting you the power to articulate and shape precisely how you envision your website or app – your digital domain, your way.
        </div>
      </div>
    </div>
  );
};

export default HomeSection5;

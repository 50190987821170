import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import ArlicleImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/UiUxImage.webp";

const fontFamily = "Poppins";

const UiUxIntroSec02 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Introduction to UI and UX
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            In the digital realm, the terms UI and UX are often mentioned in
            tandem, but they refer to very different aspects of the product
            design process. Understanding these differences is crucial for
            anyone entering the design field or looking to create products that
            offer more than just superficial appeal.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Defining UI (User Interface) Design
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            User Interface (UI) design is crafting a product's visual layout and
            interactive elements. This includes everything a user can interact
            with — screen layout, transitions, interface animations, and every
            micro-interaction. Every animation, interaction, and visual
            component needs to be planned. Think of UI design as the bridge that
            allows users to interact with a product or service. It's all about
            aesthetics and ensuring that the product's interface is attractive,
            visually stimulating, and themed appropriately to match the purpose
            and personality of the product. Good UI design focuses on
            anticipating what users might need to do and ensuring that the
            interface has elements that are easy to access, understand, and use
            to facilitate those actions. UI designers ponder on colour schemes,
            button shapes, lines' width, and the text's fonts. They create the
            look and feel of a user's interaction with a product.
          </div>
        </div>

        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* description */}

        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Defining UX (User Experience) Design
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            On the other side of the coin is User Experience (UX) Design, which
            is a more analytical and technical field. The end-user's engagement
            with the business, its services, and its goods are all included in
            UX design. Creating simple, effective, relevant, and enjoyable user
            experiences is the main objective of UX design. This is achieved by
            creating a path logically flowing from one step to the next and
            labelled as the user's "journey". UX designers work on the
            behind-the-scenes research, planning, and prototyping process to
            create that journey. They examine the how, what, and why of using a
            product. The "why" involves the users' motives for adopting a
            product, whether they relate to a task they wish to perform with it
            or to values and views which users associate with the ownership and
            use of the product. The "what" addresses what people can do with a
            product—its functionality. Finally, the "how" relates to the
            functionality design in an accessible and aesthetically pleasant
            way. UX design is all about constructing the optimal experience for
            the user, with the ultimate aim of maximizing customer satisfaction.
          </div>
        </div>

        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          How UI and UX Work Together
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            While UI and UX design are different, they are interdependent, and
            the success of a product relies on the effective collaboration
            between UI and UX designers. The UI is the vehicle that delivers the
            UX. For instance, consider the UX as the engine that powers the car,
            while the UI is the bodywork and the paint job. Both must work
            harmoniously to create a product that users can enjoy. A beautiful
            interface can draw users in, but they need to be more intuitive and
            responsive to their needs for a better user experience. Conversely,
            a poor visual interface can undermine a great user experience,
            making interaction cumbersome or unenjoyable. Together, UI and UX
            design create a seamless, intuitive, and engaging product with which
            users can operate efficiently and form an emotional connection. The
            best user-centred products have a perfect balance of superb UI and
            UX.
          </div>
        </div>
        {/* blog second Images */}
        <div className="flex justify-center mt-10">
          <img src={ArlicleImage} alt="BlgImage" />
        </div>
        {/* Second description */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          The Role of UI:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          The Look and Feel
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            User interface (UI) design is essential in developing digital
            products. At its core, UI is responsible for the look and feel of a
            product, which encompasses the presentation and interactivity of a
            product. An effective UI design does not just focus on aesthetics;
            it also considers the form and function of the product, aiming to
            make user interactions as simple and efficient as possible.
          </div>
        </div>

        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Elements of UI:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          Color, Typography, and Imagery
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Choosing colours in UI design is more than just making the interface
            attractive. It's a critical tool for communication, influencing the
            user's mood, emotional responses, and even behaviours. Colour
            schemes must align with the brand's identity and the product's
            purpose, ensuring that users receive the right message and can
            navigate the product intuitively. <br /> <br /> Typography is
            another element that significantly impacts user interface design.
            It's not merely the font selection; it's also about ensuring text
            readability, hierarchy, and overall harmony with other design
            elements. Good typography will guide the user's eye and provide a
            clear information hierarchy, making the interface efficient and
            visually pleasing. <br /> <br />
            Imagery, which includes photographs, illustrations, videos, and all
            graphic visuals, is used to communicate a message, support content,
            and enhance the user experience. It is a visual treat that can break
            up text, explain concepts, and support user navigation.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Interactive Components:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          Buttons, Icons, and Sliders
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Interactive components such as buttons, icons, and sliders are the
            tools that allow users to navigate and interact with a product.
            Buttons must be designed to be easily recognizable and should prompt
            users to take action. They need to stand out so that their function
            is clear and easily accessible for users of all abilities. <br />
            <br /> Icons are visual representations that can communicate a
            function or content quickly and effectively. They should be
            intuitive and consistent throughout the product to minimize
            confusion and learning time for the user. <br />
            <br /> Sliders offer a different type of interaction, allowing users
            to make selections or adjust settings within a range. They should be
            smooth to operate and indicate the effect of the adjustment.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Layout and Spatial Relationships
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            The layout is the arrangement of elements on a screen. It directs
            the user's eye and dictates the flow of information. A good layout
            will naturally guide users through the content in a logical and
            accessible way. Spatial relationships between elements help the user
            understand how to interact with the UI. For example, closely grouped
            items are typically understood to be related. Effective use of space
            can contribute significantly to the user interface by creating focus
            points and reducing clutter. This helps highlight the most crucial
            parts of the app or website and improves usability and the overall
            user experience. Every part of the UI design, from layout to the
            individual interactive components, plays a vital role in the
            product's look and feel, directly influencing the user's experience
            and perception of the product's quality and reliability.
          </div>
        </div>

        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          The Role of UX:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          The Overall Experience
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            User Experience (UX) is about how a person feels when interacting
            with a digital product. It encompasses many user interactions, from
            the practical and functional aspects to the emotional responses
            elicited. The UX design process is deeply rooted in understanding
            and optimizing these experiences to create products that are not
            only functional but also enjoyable and memorable.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          User Research and Understanding the Audience
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            At the heart of UX is user research, a critical step in
            understanding the target audience for whom the product is being
            designed. This research can take many forms, from surveys and
            interviews to usability tests and field studies. The goal is to
            gather as much information as possible about the users' needs,
            goals, behaviours, and pain points. This data helps inform the
            design process, ensuring that decisions are made with the user's
            experiences in mind. A deep understanding of the audience enables
            designers to predict how users might interact with a product and to
            design for their specific needs and preferences.
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Developing User Personas and Scenarios
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            From the insights gained through user research, UX designers create
            user personas, fictional characters representing the different user
            types within a targeted demographic. Personas help humanize the
            research data, providing a clear picture of the users' expectations
            and likelihood of using the product. Scenarios and use cases are
            then developed around these personas to determine how the product
            will be used. This step is crucial for identifying and prioritizing
            the features and functionalities that the product must have to meet
            user needs.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Crafting User Journeys and Flows
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            The user journey is a visualization of the steps a user takes to
            accomplish a task within a product, from start to finish. Crafting
            these journeys requires a detailed understanding of the user's goals
            and the potential obstacles they might encounter. This process
            involves creating user flows, the paths users take to complete tasks
            on a website or app. These flows are mapped out in wireframes and
            diagrams that represent the skeletal framework of the product.{" "}
            <br /> <br /> User journeys and flows design ensures that users can
            achieve their goals and make the process as efficient and enjoyable
            as possible. It's about removing friction points and creating a
            seamless transition from one step to the next, ideally resulting in
            an intuitive and straightforward user experience. By focusing on the
            overall experience, UX design helps to bridge the gap between the
            user's needs and the product's functionality, ensuring that the end
            product is both usable and desirable.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Case Study Examples
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Examining successful case studies is an excellent way to understand
            the real-world application of UI and UX principles. These examples
            offer insights into designers' processes and strategies to create
            compelling interfaces and experiences.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Analyzing UI:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          A Closer Look at Successful Interfaces
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            One notable example of successful UI design is the interface of the
            popular design tool, Adobe Photoshop. The UI of Photoshop is
            complex, catering to a wide range of functionalities professionals
            need. Yet, despite this complexity, the interface is organized to
            allow users to find tools quickly. The use of icons and panels is
            consistent and logical, and the colour palette is subdued to let
            users focus on the creative work without distraction. The success of
            Photoshop's UI lies in its ability to present a vast array of tools
            in an organized manner that users can customize according to their
            workflows. <br /> <br /> Another example is the mobile app for
            Spotify. Its interface is clean and intuitive, with a strong focus
            on album artwork, making browsing for music a visually engaging
            experience. Using consistent iconography and swipe gestures creates
            a smooth navigation experience that feels natural on mobile devices.
            Spotify's UI design successfully marries functionality with
            aesthetics to create a memorable and easy-to-use application.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Analyzing UX:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          How Great User Experience Transcends Design
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            A prime example of a product with a great user experience is the
            ride-sharing app Uber. Uber's UX begins with a deep understanding of
            the user's needs: a fast, reliable, and easy way to get from point A
            to point B. The app's user journey is streamlined to minimize the
            time and effort required to book a ride. With a few taps, users can
            see the available cars, the price, and the estimated arrival time;
            this efficiency is crucial to Uber's UX success. <br /><br /> Another case study
            in effective UX is the e-commerce giant Amazon. Amazon's UX strategy
            focuses on making the shopping experience as frictionless as
            possible. Features like one-click ordering, personalized
            recommendations, and easy-to-navigate product categories are all
            designed for the user's convenience. The website provides a seamless
            transition from browsing to purchasing, which has been integral to
            Amazon's dominance in the online retail space. The UX transcends
            mere design by embedding these features into the very functionality
            of the site, making it a powerful tool for both the company and its
            customers. <br /><br /> These case studies illustrate how a well-thought-out UI
            can create a visually pleasing interface and a practical tool for
            users, while a focus on UX leads to a functional and enjoyable
            product, often creating a loyal user base and a strong brand.
          </div>
        </div>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          UI in Action: Creating Immediate Visual Impact
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            In digital products, the importance of creating an immediate visual
            impact through User Interface (UI) design cannot be overstated. A
            visually compelling UI captures attention and engages users from the
            first interaction. This is achieved through aesthetic appeal and
            clear visual communication, which create a powerful first impression
            that can influence a user's perception of the product's quality and
            value.
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiUxIntroSec02;

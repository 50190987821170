import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"; // Importing Font Awesome icons
import Avatar1 from "../../Images/TeamsImages/ProfileImage1.webp";
import Avatar2 from "../../Images/TeamsImages/ProfileImage2.webp";
import Avatar3 from "../../Images/TeamsImages/ProfileImage3.webp";
import Avatar4 from "../../Images/TeamsImages/ProfileImage4.webp";
import Avatar5 from "../../Images/TeamsImages/ProfileImage5.webp";
import { IoIosStar } from "react-icons/io";
import BackButton from "../../Images/HomeImages/Section5/BackButton.png";
import PreviousButton from "../../Images/HomeImages/Section5/PreviousButton.png";

class ProjectTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  handlePrev = () => {
    this.slider1.slickPrev();
  };

  handleNext = () => {
    this.slider1.slickNext();
  };

  render() {
    const sliderSettings = {
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
      slidesToShow: 5,
      swipeToSlide: true,
      focusOnSelect: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      centerMode: true,
      centerPadding: "0", // Adjust this value as needed
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            centerPadding: "0",
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
            centerPadding: "0",
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "0",
          },
        },
      ],
    };

    return (
      <div className="pb-20 max-lg:pb-40 max-sm:pb-20">
        {/* testimonials description */}
        <div>
          <div className="mt-20  text-center text-rose-600 text-[35px] font-bold font-['Poppins'] uppercase">
            Testimonials
          </div>

          <div className="w-[80%] mx-[10%] text-center text-white text-xl max-lg:text-lg max-sm:text-base mt-5 font-medium font-['Poppins']">
            Our travelers share their stories of success and satisfaction,
            reflecting the dedication we put into every aspect of the Travel
            Planner Platform. We take pride in delivering experiences that not
            only meet but exceed expectations
          </div>
        </div>

        {/* testimonial slider */}
        <div className="w-[80%] mx-[10%] mt-10 relative text-center">
          <Slider {...sliderSettings} className="mx-auto">
            <div className="mx-auto ">
              <img src={Avatar3} alt="avatar1" className="mx-auto" />
            </div>
            <div className="mx-auto">
              <img src={Avatar1} alt="avatar1" className="mx-auto" />
            </div>
            <div className="mx-auto">
              <img src={Avatar2} alt="avatar1" className="mx-auto" />
            </div>
            <div className="mx-auto">
              <img src={Avatar5} alt="avatar1" className="mx-auto" />
            </div>
            <div className="mx-auto">
              <img src={Avatar4} alt="avatar1" className="mx-auto" />
            </div>
            <div className="mx-auto">
              <img src={Avatar2} alt="avatar1" className="mx-auto" />
            </div>
          </Slider>

          {/* Second Slider */}
          <div>
            <Slider
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
              arrows={false}
              focusOnSelect={true}
            >
              {/* testimonial 01 */}
              <div>
                <div className=" p-10 max-sm:p-5 w-[60%] mt-10 max-lg:mt-8 max-md:mt-6 max-sm:mt-4 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    Mr. akila and his team are very talented people with good
                    skills. They get the work Done on time and very successfully
                    and confidently. Highly recommended for any kind of hard
                    task.
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">Nayoli</div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* testimonial 02 */}
              <div>
                <div className=" p-10 max-sm:p-5  w-[60%] mt-10 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    A committed, diligent team can successfully. Highly
                    recommended for any difficult work.
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">Miss Siriwardana</div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* testimonial 03 */}
              <div>
                <div className=" p-10 max-sm:p-5  w-[60%] mt-10 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    Akila and his team highlight their exceptional skills in
                    Convolutional Neural Networks (CNN) and Machine Learning.
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">Mr Dalugama</div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* testimonial 04 */}
              <div>
                <div className=" p-10 max-sm:p-5  w-[60%] mt-10 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    I highly recommend Zynovatex. The team was not only
                    professional but also incredibly friendly and attentive to
                    my needs.
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">Shelomi Dewsirini</div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* testimonial 05 */}
              <div>
                <div className=" p-10 max-sm:p-5  w-[60%] mt-10 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    Akila is a dedicated engineer and has the capacity to work
                    in any domain and adapt to any technology.
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">
                        Ruwan Liyanage <br />
                        <span className="text-sm">Software Engineer at Hsenid Japan</span>
                      </div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* testimonial 06 */}
              <div>
                <div className=" p-10 max-sm:p-5  w-[60%] mt-10 mx-[20%] border-[#fff]/[.30] bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
                  <div className=" text-center text-white text-xl max-lg:text-lg max-md:text-base max-sm:text-sm font-medium font-['Poppins']">
                    The Travel Planner Platform changed the way I plan my trips.
                    It's so much easier to collaborate with my friends and make
                    sure we're all on the same page!
                  </div>
                  <hr className=" w-[30%] mx-[35%] mt-4 " />

                  <span>
                    <div className="text-neutral-400  text-base justify-center font-semibold font-['Poppins']  mt-4 grid  md:grid-cols-3">
                      <div className="text-center ">Jessica Mitchell</div>
                      <div className="flex justify-center text-xl text-yellow-400">
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                      </div>
                      <div>
                        <div className="text-neutral-400 text-center  text-sm font-normal font-['Poppins']">
                          {/* Aug 16, 2023 */}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </Slider>
          </div>

          {/* Buttons */}
          <div className="flex justify-center gap-[70%] max-sm:gap-[80%] mt-[-10%] max-lg:mt-[-25%] max-sm:mt-[-35%] max-[500px]:mt-[-50%] relative">
            <div className="max-sm:w-10">
              <div onClick={this.handlePrev}>
                <img src={BackButton} alt="backbutton" />
              </div>
            </div>
            <div className="max-sm:w-10">
              <div onClick={this.handleNext}>
                <img src={PreviousButton} alt="previousbutton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectTestimonial;

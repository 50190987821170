import React, { useState, useEffect,useRef } from "react";
import Section2Background from "../../Images/FeedbackImages/Section2Background.webp";
import "../../Assests/homeSection2Style.css";
import { Typography } from "@material-tailwind/react";
import { InputText } from "primereact/inputtext";
import './rating.css'

const fontFamily = "Poppins";

const FeedbackSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const [value, setValue] = useState(null);

  const inputRef = useRef(null);

  const handleInput = () => {
    const { current } = inputRef;
    if (current) {
      current.style.height = "auto";
      current.style.height = `${current.scrollHeight}px`;
    }
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
  };

  return (
    <div>
      <div className="relative">
        <div
          className="w-[100%] font-['Poppins'] h-full bg-cover bg-center bg-no-repeat py-20  "
          style={{ backgroundImage: `url(${Section2Background})` }}
        >
          {/* feedback details */}
          <div className=" mx-[15%] pb-[3%] max-sm:font-medium max-sm:text-base max-[200px]:text-[10px] max-[320px]:text-[12px] max-[475px]:text-[15px] max-[768px]:text-xl max-[768px]:text-center  text-white text-3xl font-bold font-['Poppins'] capitalize leading-[34px] ">
            We would love to hear about your experience
          </div>
          <div className="w-[70%] max-sm:w-[90%] max-sm:mx-[5%] mx-[15%] h-full  border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
            <div className="grid gap-2 md:grid-cols-2">
              <div>
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light "
                >
                  First Name *
                </Typography>
                <InputText
                  id="firstName"
                  type="text"
                  className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                  placeholder="First Name"
                />
              </div>
              <div>
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
                >
                  Email*
                </Typography>
                <InputText
                  id="feedbackEmail"
                  type="text"
                  className="pl-2 py-2 max-sm:text-xs  border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                  placeholder="Email"
                />
              </div>
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Company Name
              </Typography>
              <InputText
                id="companyName"
                type="text"
                className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                placeholder="Company Name"
              />
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Your Rating
              </Typography>
              <div className="rating mt-[-15px] ">
                <input value="5" name="rating" id="star5" type="radio" />
                <label htmlFor="star5"></label>
                <input value="4" name="rating" id="star4" type="radio" />
                <label htmlFor="star4"></label>
                <input value="3" name="rating" id="star3" type="radio" />
                <label htmlFor="star3"></label>
                <input value="2" name="rating" id="star2" type="radio" />
                <label htmlFor="star2"></label>
                <input value="1" name="rating" id="star1" type="radio" />
                <label htmlFor="star1"></label>
              </div>
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-4 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Review Title
              </Typography>
              <InputText
                id="reviewTitle"
                type="text"
                className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                placeholder="Company Name"
              />
              <textarea
                id="feedbackInput"
                ref={inputRef}
                className=" mt-8 textarea pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%] bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                style={{
                  resize: "none", // Disable manual resizing
                  overflow: "hidden",
                  minHeight: "200px", // Set a minimum height
                }}
                onInput={handleInput}
                placeholder="Your Feedback Here"
              />
              <div id="checkBox" className="flex items-center mt-5">
                {/* Transparent checkbox with conditional OK icon */}
                <input
                  type="checkbox"
                  id="transparentCheckbox"
                  className="hidden"
                  checked={isChecked}
                  onChange={toggleCheckbox}
                />
                <label
                  htmlFor="transparentCheckbox"
                  className="flex items-center cursor-pointer"
                >
                  <div className="mt-1 mr-2 icon-container">
                    {isChecked ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 p-1 text-white border-2 border-white rounded-md inline-b max-sm:h-5 max-sm:w-5 lock"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={6}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block w-6 h-6 p-1 border-2 border-gray-500 rounded-md max-sm:h-5 max-sm:w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </div>
                  {/* checkbox content */}
                  <Typography
                    variant="h6"
                    color="white"
                    className="mt-4 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
                  >
                    We care about protecting your data. Here's our Privacy
                    Policy.
                  </Typography>
                </label>
              </div>
            </div>
            <div className="flex justify-center mt-6 text-center">
              <button className="px-20 max-sm:px-8 max-md:px-10 max-sm:py-1 max-md:py-2 max-sm:text-sm py-2 text-white rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#5882c1]/[.68] block hover:scale-105">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSection2;

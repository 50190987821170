import React from 'react'
import Section1 from './Section1';
import Section2 from "./Section2";
import Section3 from "../common/Section3";

const Project01 = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
      
    </div>
  )
}

export default Project01

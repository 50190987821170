import React from 'react'
import Section1 from './MLSection1';
import Section2 from "./MLSection2";
import Section3 from "./MLSection3";

const MLVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

export default MLVacancies

import React from 'react'
import Section1 from './UxUiSections/Section1';
import Section2 from './UxUiSections/Section2';
import Section3 from './UxUiSections/Section3';
import Section4 from './UxUiSections/Section4';
import Section5 from './UxUiSections/Section5';



const UiUxTeam = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
    </div>
  )
}

export default UiUxTeam

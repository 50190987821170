import React from 'react'
import Section1 from './MobileSection1';
import Section2 from './MobileSection2';
import Section3 from './MobileSection3';

const MobileVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

export default MobileVacancies

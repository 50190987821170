import React from 'react'
import Section1 from './DevOpsSections/Section1';
import Section2 from './DevOpsSections/Section2';
import Section3 from './DevOpsSections/Section3';
import Section4 from './DevOpsSections/Section4';
import Section5 from './DevOpsSections/Section5';



const DevOpsTeam = () => {
    return (
        <div>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </div>
    )
}

export default DevOpsTeam

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FooterBgImage from "../Component/Images/FooterImages/Bg.webp";
import FbIcon from "../Component/Images/FooterImages/Facebook.png";
import InstaIcon from "../Component/Images/FooterImages/Instagram.png";
import LinkedinIcon from "../Component/Images/FooterImages/LinkedIn.png";
import TiktokIcon from "../Component/Images/FooterImages/tiktok.png";
import GithubIcon from "../Component/Images/FooterImages/Github.png";
import DribbleIcon from "../Component/Images/FooterImages/Dribble.png";

const Footer = () => {
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  const isMobile = windowWidth <= 767;
  const isTablet = windowWidth > 767 && windowWidth <= 1024;

  const [isFbHovered, setIsFbHovered] = useState(false);
  const [isInstaHovered, setIsInstaHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);
  const [isGithubHovered, setIsGithubHovered] = useState(false);
  const [isDribbleHovered, setIsDribbleHovered] = useState(false);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.15)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  return (
    <footer
      id="footer"
      className="px-3 py-6 scale-100 bg-center bg-cover md:px-10 lg:px-20 xl:px-28 md:py-16 lg:py-10 pb-14"
      style={{
        backgroundImage: `url(${FooterBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${
          isTablet ? "2" : "3"
        } lg:grid-cols-4 gap-6 justify-center text-white`}
      >
        {/* Company */}
        <div
          className={`mt-${isMobile ? 4 : 0} ${isMobile ? "mb-4" : "mb-8"} ${
            isMobile ? "text-center" : "md:text-left"
          }`}
        >
          <div className="mx-auto">
            <h3
              className="inline-block pb-1 text-lg font-bold tracking-wider border-b border-gray-300 mb-7"
              style={fontFamilyStyle}
            >
              COMPANY
            </h3>
            <ul className="space-y-4" style={fontFamilyStyle}>
              <Link to="/faqs">
                <li className="transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                  <a>FAQs</a>
                </li>
              </Link>
              <Link to="/termsandcondtions">
                <li className="mt-4 transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                  <a>Terms & Conditions</a>
                </li>
              </Link>
              <Link to="/privacypolicy">
                <li className="mt-4 transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                  <a>Privacy Policy</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>

        {/* Updates */}
        <div
          className={`mt-${isMobile ? 4 : 0} ${isMobile ? "mb-4" : "mb-8"} ${
            isMobile ? "text-center" : "md:text-left"
          }`}
        >
          <h3
            className="inline-block pb-1 text-lg font-bold tracking-wider border-b border-gray-300 mb-7"
            style={fontFamilyStyle}
          >
            UPDATES
          </h3>
          <ul className="space-y-4" style={fontFamilyStyle}>
            <Link to="/blog">
              <li className="transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                Blog
              </li>
            </Link>
            <Link to="/career">
              <li className="mt-5 transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                Careers
              </li>
            </Link>
            <Link to="/newsupdate">
              <li className="mt-5 transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                News Updates
              </li>
            </Link>
          </ul>
        </div>

        {/* Contact Us */}
        <div
          className={`mt-${isMobile ? 4 : 0} ${isMobile ? "mb-4" : "mb-8"} ${
            isMobile ? "text-center" : "md:text-left"
          }`}
        >
          <h3
            className="inline-block pb-1 text-lg font-bold tracking-wider border-b border-gray-300 mb-7"
            style={fontFamilyStyle}
          >
            CONTACT - US
          </h3>
          <ul className="space-y-4" style={fontFamilyStyle}>
            <li className="transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="inline-block w-6 h-6 mr-2"
                style={{ cursor: "pointer" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              info.zx@zynovatex.com
            </li>

            <li className="transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="inline-block w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                />
              </svg>
              076 0581244
            </li>
            <Link to="/customerfeedback">
              <li className="pt-4 transition-all duration-300 ease-in-out cursor-pointer hover:text-blue-200 hover:glow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="inline-block w-6 h-6 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                Customer feedback
              </li>
            </Link>
          </ul>
        </div>

        {/* Subscribe Us */}
        <div
          className={`mt-${isMobile ? 4 : 0} ${isMobile ? "mb-4" : "mb-8"} ${
            isMobile ? "text-center" : "md:text-left"
          }`}
        >
          <h3
            className="inline-block pb-1 mb-6 text-lg font-bold tracking-wider border-b border-gray-300"
            style={fontFamilyStyle}
          >
            SUBSCRIBE - US
          </h3>
          <p style={fontFamilyStyle}>
            Sign up for our newsletter to know
            <br /> more about the latest vacancies and blog updates
          </p>
          <div className="mt-4">
            {isMobile ? null : <p style={fontFamilyStyle}>Email</p>}
            <div
              className={`mt-2 flex ${
                isMobile ? "justify-center" : "md:justify-start"
              }`}
            >
              <input
                id="subscribeButton"
                type="email"
                placeholder="Enter your email"
                className="p-2 border border-white rounded-l"
                style={{ color: "black", fontFamily: fontFamilyStyle }}
              />
              <button
                className="flex items-center p-2 text-sm text-white rounded-r"
                style={{
                  backgroundColor: "#5882c1",
                  backdropFilter: "blur(22px)",
                  ...fontFamilyStyle,
                }}
              >
                Subscribe
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 ml-2 align-text-top"
                  style={{ transform: "rotate(-30deg)", marginBottom: "5px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Connect with us via */}
      <div
        className={`flex justify-center items-center ${
          isMobile ? "mt-12" : "mt-8"
        }`}
        style={fontFamilyStyle}
      >
        <p
          className="pb-1 text-lg font-bold tracking-wider text-white border-b border-gray-300"
          style={fontFamilyStyle}
        >
          CONNECT WITH US
        </p>
      </div>

      {/* Social Media Icons */}
      <div className="flex justify-center mt-5">
        <div className="flex">
          <a
            href="https://www.facebook.com/profile.php?id=100092469660041&mibextid=zLoPMf"
            target="_blank"
          >
            <img
              src={FbIcon}
              alt="Facebook"
              className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
              style={{ ...(isFbHovered && iconHoverStyle), ...fontFamilyStyle }}
              onMouseEnter={() => setIsFbHovered(true)}
              onMouseLeave={() => setIsFbHovered(false)}
            />
          </a>
          <a
            href="https://www.instagram.com/zynovatex?igsh=MWowaW9veTM0YzdreQ=="
            target="_blank"
          >
            <img
              src={InstaIcon}
              alt="Instagram"
              className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
              style={{
                ...(isInstaHovered && iconHoverStyle),
                ...fontFamilyStyle,
              }}
              onMouseEnter={() => setIsInstaHovered(true)}
              onMouseLeave={() => setIsInstaHovered(false)}
            />
          </a>
          <a href="https://www.linkedin.com/company/zynovatex/" target="_blank">
            <img
              src={LinkedinIcon}
              alt="LinkedIn"
              className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
              style={{
                ...(isLinkedinHovered && iconHoverStyle),
                ...fontFamilyStyle,
              }}
              onMouseEnter={() => setIsLinkedinHovered(true)}
              onMouseLeave={() => setIsLinkedinHovered(false)}
            />
          </a>
          <a
            href="https://www.tiktok.com/@zynovatex.zx?_t=8ikSwOHaf46&_r=1"
            target="_blank"
          >
            <img
              src={TiktokIcon}
              alt="TikTok"
              className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
              style={{
                ...(isTikTokHovered && iconHoverStyle),
                ...fontFamilyStyle,
              }}
              onMouseEnter={() => setIsTikTokHovered(true)}
              onMouseLeave={() => setIsTikTokHovered(false)}
            />
          </a>
          <img
            src={GithubIcon}
            alt="GitHub"
            className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
            style={{
              ...(isGithubHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsGithubHovered(true)}
            onMouseLeave={() => setIsGithubHovered(false)}
          />

          <img
            src={DribbleIcon}
            alt="Dribbble"
            className="w-10 h-10 mx-2 transition-transform transform cursor-pointer"
            style={{
              ...(isDribbleHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsDribbleHovered(true)}
            onMouseLeave={() => setIsDribbleHovered(false)}
          />
        </div>
      </div>
      <div
        className={`flex flex-col items-center mt-5 ${
          isMobile || isTablet ? "text-center" : ""
        }`}
      >
        {/* Privacy Policy and Terms of Use */}
        <p className="mt-3 text-gray-500" style={fontFamilyStyle}>
          © Copyright 2024 Zynovatex (Pvt) Ltd. All Rights Reserved.&nbsp;
          <Link
            to="/privacypolicy"
            className="text-gray-500 hover:text-gray-400"
          >
            Privacy Policy
          </Link>
          &nbsp;&amp;&nbsp;
          <Link
            to="/termsandcondtions"
            className="text-gray-500 hover:text-gray-400"
          >
            Terms Of Use
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
import flutter from '../../../../Images/ServiceImages/ReadeMoreImages/Mobile/flutter.webp';
import java from '../../../../Images/ServiceImages/ReadeMoreImages/Mobile/java.webp';
import kotlin from '../../../../Images/ServiceImages/ReadeMoreImages/Mobile/kotlin.webp';
import react from '../../../../Images/ServiceImages/ReadeMoreImages/Mobile/React.webp';


const Mobile = () => {
    const swiperRef = useRef(null);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slidesPerView = () => {
        switch (deviceView) {
            case 'mobile':
                return 3;
            case 'tablet':
                return 4;
            case 'web':
            default:
                return 4;
        }
    };


    return (
        <div className='w-full'>
            <Swiper className=''
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                slidesPerView={slidesPerView()}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                
            >
                <div>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[35%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={flutter} alt="flutter" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[35%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={java} alt="java" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[40%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={kotlin} alt="kotlin" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[40%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={react} alt="react" />
                        </div>
                    </SwiperSlide>
                    
                </div>
            </Swiper>
        </div>
    );
};

export default Mobile;

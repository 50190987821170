import React, { useState, useEffect, useRef } from "react";
import Section2Background from "../../Images/ContactImages/Section2Background.webp";
import "../../Assests/homeSection2Style.css";
import { Typography } from "@material-tailwind/react";
import { InputText } from "primereact/inputtext";
import countiresData from "./Country.json"; // Make sure you provide the correct path

const fontFamily = "Poppins";

const ContactSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const [value, setValue] = useState(null);

  const inputRef = useRef(null);

  const handleInput = () => {
    const { current } = inputRef;
    if (current) {
      current.style.height = "auto";
      current.style.height = `${current.scrollHeight}px`;
    }
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
  };

  const MailIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="currentColor"
      className="w-6 h-5 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
      />
    </svg>
  );

  const MobileIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="currentColor"
      className="w-6 h-5 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0 6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
      />
    </svg>
  );

  const LocationIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="currentColor"
      className="w-6 h-6 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
      />
    </svg>
  );

  return (
    <div>
      <div className="relative">
        <div
          className="w-[100%] font-['Poppins'] h-full bg-cover bg-center bg-no-repeat py-20  "
          style={{ backgroundImage: `url(${Section2Background})` }}
        >
          {/* feedback details */}
          <div className="text-white text-center pb-8 max-sm:w-[80%] mx-[10%] max-sm:text-[20px] text-[25px] font-bold font-['Poppins'] capitalize leading-[34px] tracking-wide">
            Please fill out this form. We’ll get in touch shortly.
          </div>
          <div className="w-[70%] mx-[15%] max-sm:w-[80%] max-sm:mx-[10%] h-full  border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
            <div className="grid gap-2 md:grid-cols-2">
              <div>
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light "
                >
                  First Name *
                </Typography>
                <InputText
                  id="firstName"
                  type="text"
                  className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                  placeholder="First Name"
                />
              </div>
              <div>
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
                >
                  Email*
                </Typography>
                <InputText
                  id="feedbackEmail"
                  type="text"
                  className="pl-2 py-2 max-sm:text-xs  border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                  placeholder="Email"
                />
              </div>
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Email address*
              </Typography>
              <InputText
                id="companyName"
                type="text"
                className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                placeholder="Company Name"
              />
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Mobile number*
              </Typography>
              <InputText
                id="companyName"
                type="text"
                className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                placeholder="Company Name"
              />
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Country*
              </Typography>
              <select
                className="pl-1 rounded-lg border-[#fff]/[.30] bg-[#404040]/[.10] backdrop-blur-[40px] text-zinc-400"
                style={{
                  width: "100%",
                  height: "40px",
                  marginRight: "10px",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                }}
              >
                <option value="">Please Select Your Country</option>
                {countiresData.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Area of Interest
              </Typography>
              <InputText
                id="companyName"
                type="text"
                className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                placeholder="Company Name"
              />
            </div>
            <div>
              <Typography
                variant="h6"
                color="white"
                className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-xl max-sm:font-light"
              >
                Reason to Contact Us
              </Typography>
              <textarea
                id="feedbackInput"
                ref={inputRef}
                className="  textarea pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%] bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                style={{
                  resize: "none", // Disable manual resizing
                  overflow: "hidden",
                  minHeight: "200px", // Set a minimum height
                }}
                onInput={handleInput}
                placeholder="Your Feedback Here"
              />
            </div>
            <div className="flex justify-center mt-6 text-center">
              <button className="px-20 max-sm:px-8 max-md:px-10 max-sm:py-1 max-md:py-2 max-sm:text-sm py-2 text-white rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#5882c1]/[.68] block hover:scale-105">
                Submit
              </button>
            </div>
          </div>

          {/* location */}
          <div className="card mt-8 p-5 w-[70%] mx-[15%] rounded-[10px] border-2 border-[#fff]/[.30] bg-[#fff]/[.10] backdrop-blur-[20px]">
            <div>
              {/* 1 row */}
              <div className="grid max-sm:pb-5 sm:grid-cols-2 sm:pb-5">
                <div className="flex ml-2 pb-2 max-sm:text-sm max-sm:font-light text-white sm:justify-end justify-center text-lg font-medium font-['Poppins']">
                  <span className="mr-2 sm:mt-1 max-sm:mr-2">
                    <MailIcon />
                  </span>
                  Email
                  <span className="px-6">:</span>
                </div>
                <div className="text-white text-lg max-sm:text-base max-sm:font-normal font-medium font-['Poppins'] max-sm:text-center">
                  info.zx@zynovatex.com
                </div>
              </div>
              {/* 2 row */}
              <div className="grid sm:grid-cols-2 sm:pb-5 max-sm:pb-5">
                <div className="flex ml-2 pb-2 max-sm:text-sm max-sm:font-light text-white sm:justify-end justify-center text-lg font-medium font-['Poppins']">
                  <span className="mr-2 max-sm:mr-2">
                    <MobileIcon />
                  </span>
                  Mobile
                  <span className="px-6">:</span>
                </div>
                <div className="text-white text-lg max-sm:text-base max-sm:font-normal font-medium font-['Poppins'] max-sm:text-center">
                  076 058 1244
                </div>
              </div>
              {/* 3 row */}
              <div className="grid sm:grid-cols-2 ">
                <div className="flex ml-2 pb-2 max-sm:text-sm max-sm:font-light text-white sm:justify-end justify-center text-lg font-medium font-['Poppins']">
                  <span className="mr-2 max-sm:mr-2">
                    <LocationIcon />
                  </span>
                  Address
                  <span className="px-6">:</span>
                </div>
                <div className="text-white  text-lg max-sm:text-base max-sm:font-normal font-medium font-['Poppins'] max-sm:text-center">
                  77/5/A/1 Gothami Mawatha,Welewaththa, Wallampitiya, Colombo,
                  Sri Lanka
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection2;

import React, { useState, useEffect } from "react";
import ReadNewsBackground from "../../../Images/NewsImages/ReadNews/Section1Background.webp";
import ArticleImage from "../../../Images/NewsImages/ReadNews/ArticleImage.jpeg";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import NewsDetails from '../NewsDetails';

const ReadNews = () => {
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      className="bg-no-repeat bg-cover w-100% h-full px-[10%] py-[10%] max-sm:py-[25%] text-white font-['poppins'] text-justify"
      style={{ backgroundImage: `url(${ReadNewsBackground})` }}
    >
      <div className="text-white text-[38px] font-bold font-['Poppins'] max-sm:text-xl">
        ZYNOVATEX announces a new product launch
      </div>
      <div className="text-white text-[23px] max-sm:text-lg font-normal mt-8 font-['Poppins'] ">
        Introducing our new Wedding Planning Solution
      </div>
      <div className=" mt-8 text-white text-xl font-normal font-['Poppins'] max-sm:text-base">
        We're thrilled to present our latest innovation, the Wedding Planning
        Solution, designed to elevate your special day to new heights. This
        comprehensive solution gives couples a seamless and enjoyable wedding
        planning experience, both online and offline.
      </div>

      <div className="grid gap-8 mt-8 lg:grid-cols-2 max-sm:text-lg">
        {/* article image */}
        <div className="">
          <img src={ArticleImage} alt="project1" />
        </div>
        <div className=" text-white text-xl font-normal font-['Poppins'] max-sm:text-base">
          We're thrilled to present our latest innovation, the Wedding Planning
          Solution, designed to elevate your special day to new heights. This
          comprehensive solution gives couples a seamless and enjoyable wedding
          planning experience, both online and offline.
          <br />
          <br /> We're thrilled to present our latest innovation, the Wedding
          Planning Solution, designed to elevate your special day to new
          heights. This comprehensive solution gives couples a seamless and
          enjoyable wedding planning experience, both online and offline.
          <br />
          <br />
          We're thrilled to present our latest innovation, the Wedding Planning
          Solution,
        </div>
      </div>

      {/* article description */}

      <div className=" text-white text-xl mt-8 font-light font-['Poppins'] max-sm:text-base">
        We're thrilled to present our latest innovation, the Wedding Planning
        Solution, designed to elevate your special day to new heights. This
        comprehensive solution brings couples a seamless and enjoyable wedding
        planning experience, both online and offline. Our dedicated team has
        poured creativity and expertise into developing a solution that
        simplifies wedding planning. From venue selection to seating
        arrangements, our Wedding Planning Solution offers a comprehensive set
        of tools to make your journey to the altar unforgettable
        <br />
        <br /> Our dedicated team has poured creativity and expertise into
        developing a solution that simplifies wedding planning. From venue
        selection to seating arrangements, our Wedding Planning Solution offers
        a comprehensive set of tools to make your journey to the altar
        unforgettable.
        <br />
        <br /> We're thrilled to present our latest innovation, the Wedding
        Planning Solution, designed to elevate your special day to new heights.
        This comprehensive solution brings couples a seamless and enjoyable
        wedding planning experience, both online and offline. Our dedicated team
        has poured creativity and expertise into developing a solution that
        simplifies wedding planning. From venue selection to seating
        arrangements, our Wedding Planning Solution offers a comprehensive set
        of tools to make your journey to the altar unforgettable.
        <br />
        <br /> We're thrilled to present our latest innovation, the Wedding
        Planning Solution, designed to elevate your special day to new heights.
        This comprehensive solution brings couples a seamless and enjoyable
        wedding planning experience, both online and offline. Our dedicated team
        has poured creativity and expertise into developing a solution that
        simplifies wedding planning. From venue selection to seating
        arrangements, our Wedding Planning Solution offers a comprehensive set
        of tools to make your journey to the altar unforgettable.
        <br />
        <br />
        <span className="text-2xl font-bold"> Key features</span> <br />
        <br />
        Our Wedding Planning Solution serves as your personal companion, guiding
        you through each planning step. It offers an interactive planning
        dashboard to keep you on top of tasks, budgets, and timelines.
        Effortlessly communicate with vendors, compare quotes, and manage
        contracts. Track RSVPs, seating preferences, and dietary needs with
        ease. Choose from elegant themes that match your wedding's aesthetic.
        <br />
        <br />
        <span className="text-2xl font-bold">Join us</span> <br />
        <br />
        Join our live webinar on{" "}
        <span className="text-sky-500">August 20th</span> to discover the
        solution's features and gain expert insights into seamless wedding
        planning. Register now and step into your wedding journey with
        confidence.
        <br />
        <br /> <span className="text-2xl font-bold">Stay informed</span>
        <br />
        <br /> Stay connected to explore stories from couples who've
        successfully realized their wedding visions using our solution. Our news
        section keeps you updated on wedding trends, real-life experiences, and
        expert advice.
      </div>
      {/* like button */}
      <div className="border-[#fff]/[.50]  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <button onClick={handleLike} className="mr-4">
              {isLiked ? (
                <BiDislike className="w-6 h-6" />
              ) : (
                <BiLike className="w-6 h-6" />
              )}
            </button>
            <span>{likes} likes</span>
            <button onClick={handleComment} className="ml-4">
              {/* Use the imported Comment icon */}
              <FaRegCommentDots className="w-6 h-6" />
            </button>
          </div>
          <div className="flex items-center">
            <button onClick={handleSave} className="mr-4">
              {isSaved ? (
                <BsBookmarkX className="w-6 h-6" />
              ) : (
                <BsBookmarkCheck className="w-6 h-6" />
              )}
            </button>
            <button onClick={handleShare}>
              {/* Use the imported Share icon */}
              <PiShareFat className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-14 border-b border-[#fff]/[.50]"></div>
      <div className="text-white mt-10 mb-10 text-[38px] font-bold font-['Poppins']">
        Related News
      </div>
      <NewsDetails />
    </div>
  );
};

export default ReadNews;

import React from 'react'
import SocialMedia from '../Component/Common/SocialMedia';
import CareerSection1 from '../Component/Pages/CareerPage/CareerSection1';
import CareerSection2 from '../Component/Pages/CareerPage/CareerSection2';
import CareerSection3 from '../Component/Pages/CareerPage/CareerSection3';

const Career = () => {
  return (
    <div>
      <CareerSection1/>
      <CareerSection2/>
      <CareerSection3/>
      <SocialMedia/>
    </div>
  )
}

export default Career;

import React from 'react'
import BackendSection1 from "../../CareerViewPages/QAVacancies/QASection1";
import CareerApplySection2 from "../careerApplySection2";

const QAApply = () => {
  return (
    <div>
      <BackendSection1 />
      <CareerApplySection2 />
    </div>
  );
}

export default QAApply

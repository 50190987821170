import React from 'react'
import Section0001 from './Section0001';
import Section0002 from './Section0002';
import Section03 from '../common/Section3';

const Project04 = () => {
  return (
    <div>
      <Section0001/>
      <Section0002/>
      <Section03/>
    </div>
  )
}

export default Project04

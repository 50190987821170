import React from 'react'
import BackendSection1 from '../../CareerViewPages/BackEndVacancies/BackendSection1';
import CareerApplySection2 from '../careerApplySection2';

const BackEndApply = () => {
  return (
    <div>
      <BackendSection1/>
      <CareerApplySection2/>
    </div>
  )
}

export default BackEndApply

import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import ArlicleImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/UiUxImage.png";

const fontFamily = "Poppins";

const UiUxSec02 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Creating Integrated Digital Experiences, A deep dive into UI/UX
          Mastery
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Welcome to our exploration into the fascinating field of UI/UX
            design, where memorable digital experiences are created at the nexus
            of functionality and aesthetics. In a time when customer
            satisfaction is paramount, companies looking to make an impact on
            their customers must become experts in UI/UX design.
          </div>
        </div>
        {/* <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          : Simulation of human intelligence in machine learning
        </span> */}
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            The Fundamentals of UI/UX Design
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            The cornerstone of any successful digital product is the design of
            the user interface (UI) and user experience (UX). While UX is
            concerned with the overall user experience and satisfaction, UI
            concentrates on the visual elements to ensure a pleasing aesthetic.
            Great design is based on embracing clarity, consistency, and
            simplicity.
          </div>
        </div>
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Research's Function in UI/UX:
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Good design always stems from careful user research. Gaining an
            understanding of the audience through techniques like analytics,
            user interviews, and surveys yields priceless insights. By centering
            the design process around the needs of the user, we produce
            delightful and meaningful products.
          </div>
        </div>
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            UI/UX Trends for Seeing in 2024:
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Being ahead of trends is essential in the constantly changing field
            of UI/UX. We explore the trends influencing design strategies for
            2024, from the introduction of Voice User Interfaces (VUI) to the
            rise of dark mode interfaces for less eye strain. Keeping an eye on
            these developments guarantees that our designs stay current and user
            focused.
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* description */}
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Case Studies
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Let's demonstrate how UI/UX works in practice. It has been an honor
            for our team to work on projects that revolutionized user
            experiences. We demonstrate the difficulties encountered, the
            choices made in terms of design, and the observable advancements
            made through before and after snapshots.
          </div>
        </div>
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Design Thinking and Collaboration
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Excellent UI/UX design is a mindset that transcends aesthetics. Our
            design philosophy is based on design thinking, a human-centered
            approach to problem-solving. A holistic approach is ensured through
            collaboration between designers, developers, and stakeholders,
            leading to products that seamlessly combine form and function.
          </div>
        </div>
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Overcoming UI/UX Challenges
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            There are obstacles in the field of design. We discuss the typical
            problems encountered in UI/UX projects and the methods used to solve
            them. Our design process requires us to innovate and adapt to
            changing user expectations and tight deadlines.
          </div>
        </div>
        <div className="mt-8">
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            The UI/UX Setting of the Future{" "}
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Looking ahead, the UI/UX environment is still changing. Our team has
            made a commitment to embracing emerging technologies and design
            methodologies in order to anticipate the needs of users of the
            future. Our innovative approach puts us at the forefront of UI/UX
            innovation.
            <br />
            <br />
            In summary, user-centered design (UI/UX) is a dynamic process that
            combines creativity, research, and user needs. Our team navigates
            the design landscape by putting these principles first and creating
            digital experiences that captivate and resonate with users. View our
            portfolio to see UI/UX in action and its transformative power.
          </div>
        </div>
        {/* blog second Images */}
        <div className="flex justify-center mt-10">
          <img src={ArlicleImage} alt="BlgImage" />
        </div>
        {/* Second description */}

        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiUxSec02;

import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
import htmlImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/HTML.webp';
import cssImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/CSS.webp';
import JSImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/JS.webp';
import TSImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/TS.webp';
import ReactImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/React.webp';
import NextJsImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/Next.webp';
import BootSImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/Boot.webp';
import ReduxImg from '../../../../Images/ServiceImages/ReadeMoreImages/FrontEnd/Redux.webp';

const Frontend = () => {
    const swiperRef = useRef(null);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slidesPerView = () => {
        switch (deviceView) {
            case 'mobile':
                return 3;
            case 'tablet':
                return 5;
            case 'web':
            default:
                return 6;
        }
    };


    return (
        <div className='w-full'>
            <Swiper className=''
                modules={[Navigation, Autoplay]}
                spaceBetween={5}
                slidesPerView={slidesPerView()}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
            >
                <div>
                    <SwiperSlide>
                        <div className="flex-shrink-0 lg:w-[50%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={htmlImg} alt="Html" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[50%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={cssImg} alt="css" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[50%]  lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={JSImg} alt="Javascript"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[64%]  lg:ml-8 md:w-[60%] md:ml-2 w-[60%] ml-[20%]">
                            <img src={ReactImg} alt="React" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[56%]  lg:ml-10 md:w-[60%] md:ml-7 w-[60%] ml-[20%]">
                            <img src={TSImg} alt="Typescript" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[57%]  lg:ml-4 md:w-[60%] md:ml-[1px] w-[60%] ml-[20%]">
                            <img src={NextJsImg} alt="NextJs" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[80%] lg:ml-[-10px] md:w-[60%] md:ml-[-8px] w-[80%] ml-[5%]">
                            <img src={BootSImg} alt="Bootstrap" />
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[62%]  lg:ml-[-5px] md:w-[60%] md:ml-[-5px] w-[60%] ml-[10%]">
                            <img src={ReduxImg} alt="Redux" />
                        </div>
                    </SwiperSlide>
                </div>
            </Swiper>
        </div>
    );
};

export default Frontend;

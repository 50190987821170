import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import '../../Assests/homeSection2Style.css';
import Text from '../../Assests/style';
import ScrollTrigger from 'react-scroll-trigger';
import BackgroundImage from "../../Images/HomeImages/Section3/Section3Background.webp";
import Card3 from "../../Images/HomeImages/Section3/Card3.webp";
import Card4 from "../../Images/HomeImages/Section3/Card4.webp";
import Card5 from "../../Images/HomeImages/Section3/Card5.webp";
import Card6 from "../../Images/HomeImages/Section3/Card6.webp";
import Card1 from "../../Images/HomeImages/Section3/Card1.webp";
import Card2 from "../../Images/HomeImages/Section3/Card2.webp";
import NextBtn from "../../Images/HomeImages/Section3/Next.png";
import PreviousBtn from "../../Images/HomeImages/Section3/PreviousBtn.png";


const HomeSection3 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState('web');
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView('mobile');
      } else if (width <= 1024) {
        setDeviceView('tablet');
      } else {
        setDeviceView('web');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    nextArrow: null,
    prevArrow: null,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNextSlide = () => {
    swiperRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    swiperRef.current.slickPrev();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        padding: "10px",
      }}
    >
      <div>
        <div>
          <ScrollTrigger
            onEnter={() => setConterOn(true)}
            onExit={() => setConterOn(false)}
          >
            <section>
              <div
                style={{
                  marginBottom: counterOn ? "-30px" : "0",
                  marginTop: "30px",
                }}
              >
                {counterOn && (
                  <div>
                    <Text text={"Our Services"} deviceView={deviceView} />
                  </div>
                )}
              </div>
            </section>
          </ScrollTrigger>
        </div>
        <div>
          <div className="flex-shrink-0 w-auto sm:mt-[-20px] md:mt-[-60px]  text-center text-white font-['Poppins'] text-[1.375rem] font-bold leading-[normal] uppercase mb-10  md:text-3xl lg:text-5xl ">
            Services
          </div>
        </div>
      </div>
      <div className="w-[80%] mx-[10%] relative mt-auto mb-10 mr-10">
        <Slider {...settings} ref={swiperRef}>
          <Link to="/webservice">
            <div className="relative text-white">
              <img
                src={Card2}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 md:text-2xl md:mt-10">
                  Web <br />
                  Development
                </div>
                <div className="mt-1 lg:mt-3 text-center-justify md:mx-auto text-md lg:text-lg md:w-[100%] md:text-sm">
                  Creating dynamic websites and applications, combining
                  front-end and back-end development for a seamless online
                  experience.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/softwareservice">
            <div className="relative text-white">
              <img
                src={Card6}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-24 md:text-2xl md:mt-3">
                  Software <br />
                  Development
                </div>
                <div className="mt-3 text-center-justify md:mx-auto text-md lg:text-lg md:w-[100%] md:text-sm">
                  Designing, coding, and maintaining software applications to
                  address user needs through innovative solutions.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/uiuxservice">
            <div className="relative text-white">
              <img
                src={Card3}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-22 md:text-2xl md:mt-10">
                  UI and UX <br />
                  Design
                </div>
                <div className="mt-3 text-center-justify md:mx-auto text-md lg:text-lg md:w-[110%] md:text-sm">
                  Crafting visually appealing and user-friendly digital
                  experiences, focusing on both the look and feel (UI) and
                  seamless interaction (UX).
                </div>
              </div>
            </div>
          </Link>
          <Link to="/projectservice">
            <div className="relative text-white">
              <img
                src={Card4}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-22 md:text-2xl md:mt-9 max-sm:text-md">
                  Project <br />
                  Management
                </div>
                <div className="mt-1 text-center-justify md:mx-auto text-md lg:text-lg md:w-[100%] md:text-sm">
                  Planning, organizing, and overseeing projects to ensure timely
                  completion, budget adherence, and goal achievement.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/hrservice">
            <div className="relative text-white">
              <img
                src={Card5}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-22 md:text-2xl md:mt-6 max-sm:text-md">
                  HR <br />
                  Management
                </div>
                <div className="mt-1 text-center-justify md:mx-auto text-md lg:text-lg md:w-[100%] md:text-sm">
                  Overseeing an organization's human capital, including
                  recruitment, training, and fostering a positive work
                  environment.
                </div>
              </div>
            </div>
          </Link>
          <Link to="/ecommerceservice">
            <div className="relative text-white">
              <img
                src={Card1}
                alt="FrontEndImage"
                className="w-[80%] ml-[10%] rounded-xl"
              />
              <div className="absolute mt-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-full text-xl font-bold lg:text-4xl lg:mt-22 md:text-2xl md:mt-8 max-sm:text-md">
                  E-Commerce <br />
                  Solution
                </div>
                <div className="mt-1 text-center-justify md:mx-auto text-md lg:text-lg md:w-[100%] md:text-sm">
                  Providing platforms for businesses to sell products or
                  services online, optimizing the shopping experience for
                  seamless transactions.
                </div>
              </div>
            </div>
          </Link>
        </Slider>

        <button
          onClick={handlePrevSlide}
          className="absolute transform -translate-y-1/2 top-1/2 -left-4 focus:outline-none"
        >
          <img src={PreviousBtn} alt="Previous" className="w-9" />
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute transform -translate-y-1/2 top-1/2 -right-4 focus:outline-none"
        >
          <img src={NextBtn} alt="Next" className="w-9" />
        </button>
      </div>
    </div>
  );
};

export default HomeSection3;

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi"; // Import FiX for close button
import styled, { css } from "styled-components";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { GoHome } from "react-icons/go";
import { SlStar } from "react-icons/sl";
import { AiOutlineMessage } from "react-icons/ai";
import { AiOutlineFire } from "react-icons/ai";
import { BsBook } from "react-icons/bs";

const MenuItemLink = styled(Link)`
  color: #fff; /* Adjust the link color as needed */
  /* Other common styles for the link */
  /* ... */

  ${({ active, isMobile }) =>
    active &&
    css`
      /* Active link styles */
      /* ... */
    `}

  ${({ isMobile }) =>
    !isMobile &&
    css`
      position: relative;
      text-decoration: none;
      &:hover {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -6px; /* Adjust the space between text and underline */
          width: 100%;
          border-bottom: 2px solid #fff; /* Underline color */
        }
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      text-decoration: none;
      padding: 7px; /* Remove underline for mobile */
    `}
`;

const Menu = () => {

  
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const [deviceView, setDeviceView] = useState("web");
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [showMobileMenu, setShowMobileMenu] = useState(false); // State for mobile menu toggle

  useEffect(() => {

    
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 865) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLinkClick = (to) => {
    setActiveLink(to);
    scrollToTop();
    if (deviceView === "mobile") {
      setShowMobileMenu(false); // Hide mobile menu after clicking a link
    }
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu); // Toggle mobile menu visibility
  };

  const CloseButton = () => (
    <button
      className="absolute top-0 right-0 p-2 max-lg:mr-10 max-md:mr-[13%]"
      onClick={toggleMobileMenu}
    >
      <FiX size={24} />
    </button>
  );

  const MobileMenuIcon = () => (
    <button
      className={`cursor-pointer mt-[-30px] text-[30px] ${
        showMobileMenu ? "hidden" : ""
      }`}
      onClick={toggleMobileMenu}
    >
      <FiMenu />
    </button>
  );

  const mobileMenuStyles = {
    position: "fixed",
    marginTop: "-30px",
    right: "0",
    paddingTop: "50px",
    borderRadius: "30px 0 0 30px",
    width: "60%",
    height: "400px",
    zIndex: "999",
    backgroundColor: "rgba(88, 130, 193, 0.80)",
    border: "0px solid rgba(88, 130, 193, 0.49)", // Border color with opacity
    marginBottom: "10px", // Adjust this value as needed
    backdropFilter: "blur(100px)", // Blur when mobile menu is shown
  };

  const MenuItem = ({ to, label }) => {
    const isMobile = deviceView === "mobile";
    // Check if it's a mobile view
    let iconComponent;

    switch (to) {
      case "/":
        iconComponent = <GoHome />;
        break;
      case "/about":
        iconComponent = <HiOutlineUserGroup />;
        break;
      case "/project":
        iconComponent = <HiOutlineRocketLaunch />;
        break;
      case "/service":
        iconComponent = <SlStar />;
        break;
      case "/contact":
        iconComponent = <AiOutlineMessage />;
        break;
      case "/career":
        iconComponent = <AiOutlineFire />;
        break;
      case "/blog":
        iconComponent = <BsBook />;
        break;
      default:
        iconComponent = null;
        break;
    }

    return (
      <li className="mr-8 list-none">
        <div className="relative flex items-center">
          {iconComponent && isMobile && (
            <div className="mr-10">
              {iconComponent} {/* Display the icon */}
            </div>
          )}
          <MenuItemLink
            to={to}
            onClick={() => handleLinkClick(to)}
            active={activeLink === to}
            isMobile={isMobile}
          >
            {label}
          </MenuItemLink>
        </div>
      </li>
    );
  };

  return (
    <div>
      {deviceView === "mobile" && (
        <div className="text-center max-lg:mr-10 max-md:mr-[-1%] pt-5 text-white text-[16px] font-['Poppins']">
          <div className="flex items-center justify-center ">
            <MobileMenuIcon />
          </div>
          {showMobileMenu && (
            <div style={mobileMenuStyles}>
              <CloseButton /> {/* Add close button */}
              <div className="text-xl text-center ml-[20%] w-100 h-100 font-['poppins']">
                <ul>
                  <MenuItem to="/" label="Home" />
                  <MenuItem to="/about" label="About" />
                  <MenuItem to="/project" label="Projects" />
                  <MenuItem to="/service" label="Services" />
                  <MenuItem to="/contact" label="Contact" />
                  <MenuItem to="/career" label="Careers" />
                  <MenuItem to="/blog" label="Blog" />
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      
      {deviceView === "tablet" && (
        <div className="text-center font-medium text-white md:text-[18px] font-['poppins']">
          <ul className="flex flex-row mx-4 md:flex">
            <MenuItem to="/" label="Home" />
            <MenuItem to="/about" label="About" />
            <MenuItem to="/project" label="Projects" />
            <MenuItem to="/service" label="Services" />
            <MenuItem to="/contact" label="Contact" />
            <MenuItem to="/career" label="Careers" />
            <MenuItem to="/blog" label="Blog" />
          </ul>
        </div>
      )}

      {deviceView === "web" && (
        <div className="relative text-xl font-medium leading-tight font-['poppins'] text-center text-white">
          <ul className="flex-row hidden mt-[auto] mr-16 md:flex">
            <MenuItem to="/" label="Home" />
            <MenuItem to="/about" label="About" />
            <MenuItem to="/project" label="Projects" />
            <MenuItem to="/service" label="Services" />
            <MenuItem to="/contact" label="Contact" />
            <MenuItem to="/career" label="Careers" />
            <MenuItem to="/blog" label="Blog" />
          </ul>
        </div>
      )}
    </div>
  );
};

export default Menu;

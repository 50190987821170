import React from 'react'
import Section1 from './SAArchitecSection1';
import Section2 from "./SAArchitecSection2";
import Section3 from "./SAArchitecSection3";

const SAArchitecVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
      
    </div>
  )
}

export default SAArchitecVacancies

import React from 'react'
import Section1 from './DevopsSectoin1';
import Section2 from "./DevopsSectoin2";
import Section3 from "./DevopsSectoin3";

const DevopsVacancies = () => {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}

export default DevopsVacancies

import React from "react";
import Section2Background from "../../../Images/ProjectImages/ProjectSection2.webp";
import ProjectTestimonial from "../ProjectTestimonial";
 
const Section2 = () => {
  return (
    <div
      className="py-[5%] max-sm:py-[20%] bg-cover"
      style={{ backgroundImage: `url(${Section2Background})` }}
    >
      {/* project detaila */}
      <div className="w-[70%] max-sm:w-[90%] max-sm:mx-[5%] mx-[15%] max-md:pt-10 h-full   border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
        <div className="text-xl text-white max-md:text-lg max-sm:text-base">
          <span className="text-blue-500"> CupidCo</span> is our bespoke wedding
          proposal website, providing a personalized platform for couples to
          create and share their unique love stories. It's designed to make
          proposals unforgettable by combining technology with emotion.
          <br />
          <br />
          <span className="font-extrabold">Used Technologies</span>
          <br />
          <br /> React.js for front-end; Node.js for back-end; MongoDB for
          database; Cloud storage for multimedia; Security protocols for data
          protection. <br />
          <br />
          <span className="font-extrabold">Features</span>
          <br />
          <br />
          <p>1. Customizable Proposal Pages</p>
          <p className="mt-2">
            2. Multimedia Integration for Photos and Videos
          </p>
          <p className="mt-2">3. Secure User Accounts and Data Protection</p>
          <p className="mt-2">4. Emotional UI/UX Design</p>
          <p className="mt-2">5. Community Building and Engagement Features</p>
          <p className="mt-2">6. Integration with Social Media Platforms</p>
          <br />
          <br />
          <span className="font-extrabold">Challenges Faced</span>
          <br />
          <br />
          Creating the "CupidCo" wedding proposal website posed challenges in
          balancing personalized experiences with scalability and ensuring
          robust security for user data and multimedia content integration.
          <br />
          <br />
          <span className="font-extrabold">Solution Highlights</span>
          <br />
          <br />
          Our solution features a user-friendly interface for customizable
          proposal pages, robust security protocols, and optimized architecture
          for multimedia integration, ensuring scalability through modular
          design and cloud-based infrastructure.
          <br />
          <br />
          <span className="font-extrabold">Team's Roles</span>
          <br />
          <br />
          The development team coded features, UI/UX designers crafted an
          emotionally resonant interface, security experts ensured data
          protection, and project managers facilitated communication, while
          marketing specialists focused on engagement.
          <br />
          <br />
          <span className="font-extrabold">Results and Impacts</span>
          <br />
          <br />
          "CupidCo" has led to heartwarming proposals, offering couples a unique
          platform. Positive user engagement reflects the success of our
          solution in creating memorable moments.
          <br />
          <br />
          <span className="font-extrabold">Lessons Learned</span>
          <br />
          <br />
          Balancing personalization with scalability, implementing robust
          security measures, and emphasizing emotionally resonant design were
          key lessons learned during the development of "CupidCo."
          <br />
          <br />
          <span className="font-extrabold">Future Enhancements</span>
          <br />
          <br />
          Future plans include integrating more interactive features, expanding
          customization options, incorporating AI for personalized suggestions,
          fostering community building, and continually refining the platform
          based on user feedback for an enriched user experience.
        </div>
      </div>
      <ProjectTestimonial/>
    </div>
  );
};

export default Section2;

import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import Gemini from "../../../../Images/BlogImages/ReadMoreImages/Section1/GeminiImage.jpg";
import Gemini2 from "../../../../Images/BlogImages/ReadMoreImages/Section1/GeminiImage2.jpg";

const fontFamily = "Poppins";

const Sec02 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Gemini -
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          {" "}
          The New AI Model Poised to Revolutionize Search and Creativity
        </span>

        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* description */}
        <div className="mt-8">
          <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            Introduction
          </span>
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            The release of Google Gemini represents a major advancement in
            artificial intelligence. This powerful new model, unveiled by Google
            in late 2023, promises to revolutionize how we interact with
            information and create content. Unlike its predecessors, Gemini
            boasts unique capabilities that set it apart from other AI models
            like PaLM 2 and ChatGPT.
          </div>
          <div className=" mt-4 text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            At its core, Gemini stands out for its multimodal nature. It can
            seamlessly understand and process information across various
            modalities, including text, images, audio, videos, and code. This
            groundbreaking ability allows Gemini to grasp the nuances and
            complexities of information in a way that previous models simply
            could not.
          </div>
          {/* <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Artificial Intelligence
          </div> */}
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Further distinguishing Gemini is its exceptional performance on MMLU
            (Massive Multitask Language Understanding) benchmarks. Here, Gemini
            scored 90.0%, surpassing human experts for the first time. This
            achievement underscores Gemini's ability to understand vast amounts
            of information and utilize it for problem-solving and reasoning
            across diverse subject areas.
          </div>
        </div>
        {/* blog second Images */}
        <div className="flex justify-center mt-10 mb-10">
          <img src={Gemini} alt="BlgImage" />
        </div>
        {/* Second description */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Revolutionizing Search:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          {" "}
          A New Era of Information Retrieval
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            How we interact with information online is on the verge of a
            paradigm shift with the arrival of Google Gemini. This powerful AI
            model promises to revolutionize search by fundamentally changing how
            we find and access information. Let's delve into how Gemini will
            achieve this.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Firstly, Gemini's understanding of the context and intent behind
            search queries surpasses traditional search engines. Unlike their
            reliance solely on keywords and algorithms, Gemini can interpret the
            nuances of language, including user goals, emotions, and prior
            searches. This allows Gemini to deliver more relevant and accurate
            results that meet the user's needs. Imagine searching for "best
            hiking trails" and receiving results tailored to your current
            location, fitness level, and preferred scenery.
          </div>
          {/* <div className=" mt-8 text-white text-2xl font-bold  max-lg:text-xl max-md:text-lg font-['Poppins']">
            Artificial Intelligence
          </div> */}
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Furthermore, Gemini paves the way for personalized search
            experiences, catering to each user's interests and preferences.
            Gemini can deliver results that resonate deeply with each individual
            by analyzing user data such as search history and browsing
            behaviour. This saves time and effort and creates a more satisfying
            and engaging search experience. Imagine searching for "new book
            recommendations" and receiving a list explicitly curated to your
            literary tastes and reading habits.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Efficiency and effectiveness are cornerstones of information
            retrieval, and Gemini excels in this domain. Its ability to process
            information across various modalities, including text, images, and
            audio, allows for a more comprehensive and holistic understanding of
            search topics. This saves users time and improves comprehension by
            providing a more prosperous and more diverse range of information.
            Imagine searching for "car repair guides" and receiving text-based
            instructions, relevant video tutorials, and interactive diagrams.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            The potential of Gemini to revolutionize search extends beyond these
            examples. Imagine searching for a historical event and experiencing
            a virtual reality recreation that immerses you in the period.
            Imagine searching for a scientific concept and receiving an
            interactive explanation that adapts to your level of understanding.
            The possibilities are truly limitless.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Google Gemini ushers in a new era of information access by
            understanding context and intent, personalizing experiences, and
            facilitating efficient retrieval. No longer will searching be a
            frustrating and time-consuming endeavour. Instead, it will become a
            seamless and personalized journey of discovery, empowering users to
            access the information they need when needed. This revolution in
            search has the potential to reshape how we learn, work, and connect
            with the world around us, opening doors to a future where
            information is readily available and tailored to each individual's
            unique needs and desires.
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] mb-10 text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>

        <span className="text-white text-[38px]  max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Redefining Creativity:
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          {" "}
          Gemini's Potential to Empower and Democratize
        </span>
        <div className="flex justify-center mt-10 mb-10">
          <img src={Gemini2} alt="BlgImage" />
        </div>
        <div className="mt-8">
          <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            For creative professionals, Google Gemini presents an unparalleled
            opportunity to enhance their workflow, overcome creative roadblocks,
            and explore new avenues of artistic expression. Writers can leverage
            Gemini's language generation capabilities to break through writer's
            block, generate fresh ideas, refine their style, and even translate
            their works into different languages. Imagine crafting a captivating
            story outline in minutes, generating realistic dialogue for your
            characters, or receiving instant feedback on your writing to polish
            your work to perfection.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Gemini opens up a world of possibilities for experimentation and
            collaboration for artists. Visual artists can utilize Gemini to
            generate draft sketches, explore new artistic styles, and even co-
            create unique pieces with the AI. Imagine painting a landscape with
            Gemini's assistance, receiving suggestions for new colour
            combinations or brushstrokes, or collaborating with the AI to create
            an interactive piece that responds to viewers in real time.
          </div>
          {/* <div className=" mt-8 text-white text-2xl font-bold  max-lg:text-xl max-md:text-lg font-['Poppins']">
            Artificial Intelligence
          </div> */}
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Musicians can similarly benefit from Gemini's capabilities,
            composing melodies and harmonies, generating lyrics that resonate
            with their emotions, and experimenting with innovative musical
            formats. Imagine hearing a melody take shape as you hum a few notes,
            receiving feedback on your latest composition, or collaborating with
            Gemini to create a genre-bending piece that pushes the boundaries of
            musical expression.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Beyond assisting existing creative endeavours, Gemini's potential
            lies in its ability to generate entirely new content formats. Gemini
            can produce never-before-seen forms of creative expression by
            combining its language comprehension, knowledge access, and
            multimodal processing capabilities. Imagine interactive novels that
            adapt to your choices, personalized poetry that reflects your
            emotions, or immersive virtual reality experiences co-created with
            AI.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Perhaps the most significant impact of Gemini lies in its ability to
            democratize access to creative tools and resources. Traditionally,
            specialized skills and software were required to engage in creative
            pursuits. However, with Gemini's user-friendly interface and
            powerful capabilities, anyone can unleash their creativity
            regardless of their technical expertise. Imagine a young student
            composing their first song, a non-artist creating beautiful visual
            art, or a writer with limited English skills expressing themselves
            in a new language. By lowering the barriers to entry, Gemini
            empowers individuals from all backgrounds to participate in the
            creative process and contribute to a richer, more diverse cultural
            landscape.
          </div>
          <div className=" mt-4 text-white text-xl font-normal mb-10  max-lg:text-lg max-md:text-base font-['Poppins']">
            In essence, Gemini represents a paradigm shift in the realm of
            creativity. It empowers existing creators, fuels innovation, and
            allows new voices to be heard. This transformative power can enhance
            artistic expression, redefine creative boundaries, and enrich the
            world with a vibrant tapestry of new ideas and experiences. As we
            explore the vast potential of Gemini, we stand on the cusp of a new
            era of creativity, one where technology is a powerful tool for human
            expression, accessible to all.
          </div>

          <span className="text-white text-[38px]  max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
            Unveiling the Powerhouse:
          </span>
          <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
            {" "}
            Gemini's Technical Prowess
          </span>

          <div className="mt-8">
            <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
              Beneath the surface of Google Gemini lies a complex and
              intricately designed architecture. At its core is a powerful
              Transformer-based neural network, a proven technology in many
              leading AI models. However, Gemini goes beyond this foundation,
              incorporating various innovative techniques that push the
              boundaries of AI capabilities. <br />
              <br /> Multimodality is arguably the crown jewel of Gemini's
              technical prowess. Unlike previous AI models primarily relying on
              textual data, Gemini can seamlessly understand and process
              information across various modalities, including images, audio,
              video, and code. This is achieved by integrating specialized
              modules within the neural network, each tailored to handle
              specific data formats. This allows Gemini to derive insights and
              generate outputs that leverage the unique strengths of each
              modality, leading to richer and more comprehensive results. <br />{" "}
              <br /> Another critical feature of Gemini is multilingual support,
              empowering it to communicate and understand information across
              different languages. This is crucial for a globalized world where
              data needs to be accessible to diverse audiences. By leveraging a
              combination of supervised learning techniques and pre-trained
              language models for different languages, Gemini can translate
              languages accurately and generate creative text formats in various
              languages, breaking down language barriers and fostering global
              communication. <br /> <br />
              One of Gemini's most impressive capabilities is its ability to
              generate code. This opens up exciting possibilities for
              programmers and developers, who can utilize Gemini to automate
              tedious coding tasks, generate code based on natural language
              descriptions, and even collaborate with Gemini on complex coding
              projects. This increases productivity and provides a powerful tool
              for exploring new programming paradigms and pushing the boundaries
              of software development. <br /> <br /> Naturally, no technology is without its
              limitations. While Gemini represents a significant leap forward in
              AI capabilities, specific challenges remain. One area of ongoing
              research is bias detection and mitigation, as bias can
              inadvertently creep into the vast datasets used to train AI
              models. Another challenge is achieving a true human-level
              understanding of complex concepts and reasoning tasks. This
              requires further AI research and development advancements,
              particularly in common sense and causal reasoning. <br /> <br /> Despite these
              challenges, the technical prowess of Google Gemini represents a
              groundbreaking achievement in the field of Artificial
              Intelligence. Its unique capabilities and groundbreaking features
              pave the way for a future where AI seamlessly integrates into our
              lives, empowering us to be more creative, efficient, and
              connected. As research progresses and limitations are addressed,
              Gemini has the potential to revolutionize various industries and
              redefine the way we interact with technology. Its impact on the
              future of information access, creative expression, and
              technological advancement is truly remarkable, and its story is
              just beginning.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec02;

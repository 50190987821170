import React from "react";
import SESection1 from "./SESection1";
import SESection2 from "./SESection2";
import SESection3 from "./SESection3";

const SEVacancies = () => {
  return (
    <div>
      <SESection1 />
      <SESection2 />
      <SESection3/>
    </div>
  );
};

export default SEVacancies;

import React, { useState, useEffect } from "react";
import Section1Background from '../../../Images/CareerImages/Section1/Section1Background.webp';
import '../../../Assests/homeSection2Style.css'
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../../Assests/style';

const fontFamily = 'Poppins';

const Section1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-screen bg-cover bg-center bg-no-repeat  "
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"UI/UX Team"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    UI/UX Team
                  </h1>
                  <div
                    className="w-[850px]   text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    At Zynovatex, our UI/UX team stands as a vibrant and inventive powerhouse, 
                    dedicated to sculpting digital experiences that are both intuitive and visually captivating. 
                    Focused on delving into user behavior, this dynamic team adeptly transforms abstract concepts into interfaces that are not just 
                    user-friendly but consistently surpass expectations in our product designs.

                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-screen pb-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-44px", marginTop: "200px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"UI/UX Team"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    UI/UX Team
                  </h1>
                  <div
                    className="w-[80%]   text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    At Zynovatex, our UI/UX team stands as a vibrant and inventive powerhouse,
                    dedicated to sculpting digital experiences that are both intuitive and visually captivating.
                    Focused on delving into user behavior, this dynamic team adeptly transforms abstract concepts into interfaces that are not just
                    user-friendly but consistently surpass expectations in our product designs.
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-screen pb-[100px] bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-34px", marginTop: "200px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"UI/UX Team"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-2xl mt-[0] inline-block border-b-[3px] border-gray-300 pb-0 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    UI/UX Team
                  </h1>
                  <div
                    className="w-[80%]   text-center text-white text-sm font-normal capitalize leading-[25px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    At Zynovatex, our UI/UX team stands as a vibrant and inventive powerhouse,
                    dedicated to sculpting digital experiences that are both intuitive and visually captivating.
                    Focused on delving into user behavior, this dynamic team adeptly transforms abstract concepts into interfaces that are not just
                    user-friendly but consistently surpass expectations in our product designs.
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Section1;

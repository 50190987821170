import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/MainLayout';
import Home from './Pages/Home';
import About from './Pages/About';
import Project from './Pages/Project'
import Service from './Pages/Service';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import CareerApply from './Pages/CareerApply';
import Blog from './Pages/Blog';
import UiUxService from './Component/Pages/ServicePage/ServicesReadMore/UiUxService';
import WebService from './Component/Pages/ServicePage/ServicesReadMore/WebService';
import SoftwareService from './Component/Pages/ServicePage/ServicesReadMore/SoftwareService';
import ProjectService from './Component/Pages/ServicePage/ServicesReadMore/ProjectService';
import HrService from './Component/Pages/ServicePage/ServicesReadMore/HrService';
import ECommerce from './Component/Pages/ServicePage/ServicesReadMore/ECommerce';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import FAQs from './Pages/FAQ';
import Blog1 from './Component/Pages/BlogPage/BlogReadMore/Blog1';
import CustomerFeedBack from '../src/Pages/Feedback';
import ReadNews from './Component/Pages/NewsPage/ReadNewsPage/ReadNews';
import News from './Pages/News';
import UiUxTeam from './Component/Pages/TeamDetails/UiUxTeam';
import BackendTeam from './Component/Pages/TeamDetails/BackendTeam';
import AiMlTeam from './Component/Pages/TeamDetails/AiMlTeam';
import DevOpsTeam from './Component/Pages/TeamDetails/DevOpsTeam';
import FrontendTeam from './Component/Pages/TeamDetails/FrontendTeam';
import PmBaTeam from './Component/Pages/TeamDetails/PmBaTeam';
import Member1 from './Component/Pages/MemberDetails/Member1';
import BlogSubmission from './Pages/BlogSubmission';
import Project01 from '../src/Component/Pages/ProjectPage/Project01/Project01';
import Project02 from "../src/Component/Pages/ProjectPage/Project02/Project02";
import Project03 from './Component/Pages/ProjectPage/Project03/Project03';
import Project04 from "./Component/Pages/ProjectPage/Project04/Project04";
import SEVacancies from './Component/Pages/CareerViewPages/SEVacancies/SEVacancies';
import FrontEndVacancies from './Component/Pages/CareerViewPages/FrontEndVacancies/FrontEndVacancies';
import BackEndVacancies from './Component/Pages/CareerViewPages/BackEndVacancies/BackEndVacancies';
import FullStackVacancies from "./Component/Pages/CareerViewPages/FullStackVacancies/FullStackVacancies";
import MobileVacancies from "./Component/Pages/CareerViewPages/MobileVacancies/MobileVacancies";
import DevopsVacancies from "./Component/Pages/CareerViewPages/DevOpsVacancies/DevopsVacancies";
import QAVacancies from "./Component/Pages/CareerViewPages/QAVacancies/QAVacancies";
import SAArchitecVacancies from "./Component/Pages/CareerViewPages/SoftwareArchiteVacancies/SAArchitecVacancies";
import DataEngineerVacancies from "./Component/Pages/CareerViewPages/DataEngineerVacancies/DataEngineerVacancies";
import MLVacancies from "./Component/Pages/CareerViewPages/MLVacancies/MLVacancies";
import CareerSection2 from './Component/Pages/CareerPage/CareerSection2';
import BackEndApply from './Component/Pages/Career_Apply_Page/ApplyPages/BackEndApply';
import DataEngineerApply from "./Component/Pages/Career_Apply_Page/ApplyPages/DataEngineerApply";
import DevOpsApply from "./Component/Pages/Career_Apply_Page/ApplyPages/DevOpsApply";
import FrontEndApply from "./Component/Pages/Career_Apply_Page/ApplyPages/FrontEndApply";
import FullStackApply from "./Component/Pages/Career_Apply_Page/ApplyPages/FullStackApply";
import MlApply from "./Component/Pages/Career_Apply_Page/ApplyPages/MlApply";
import MobileApply from "./Component/Pages/Career_Apply_Page/ApplyPages/MobileApply";
import QAApply from "./Component/Pages/Career_Apply_Page/ApplyPages/QAApply";
import SEApply from "./Component/Pages/Career_Apply_Page/ApplyPages/SEApply";
import SoftwareArchitecApply from "./Component/Pages/Career_Apply_Page/ApplyPages/SoftwareArchitecApply";
import BlogGemini from './Component/Pages/BlogPage/BlogReadMore/Gemini/BlogGemini';
import Java from "./Component/Pages/BlogPage/BlogReadMore/Java/Java";
import Marketing from "./Component/Pages/BlogPage/BlogReadMore/Marketing/Marketing";
import ReactNative from "./Component/Pages/BlogPage/BlogReadMore/ReactNative/ReactNative";
import UiUx from "./Component/Pages/BlogPage/BlogReadMore/UiUx/UiUx";
import UiUxInro from "./Component/Pages/BlogPage/BlogReadMore/UiUxIntro/UiUxIntro";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={MainLayout}>
          <Route index Component={Home} />
          <Route path="about" element={<About />} />
          <Route path="project" element={<Project />} />
          <Route path="service" element={<Service />} />
          <Route path="contact" element={<Contact />} />
          <Route path="career" element={<Career />} />
          <Route path="opencareers" Component={CareerSection2} />
          <Route path="blog" element={<Blog />} />
          <Route path="uiuxservice" Component={UiUxService} />
          <Route path="webservice" Component={WebService} />
          <Route path="softwareservice" Component={SoftwareService} />
          <Route path="projectservice" Component={ProjectService} />
          <Route path="hrservice" Component={HrService} />
          <Route path="ecommerceservice" Component={ECommerce} />
          <Route path="careerapply" Component={CareerApply} />
          <Route path="termsandcondtions" Component={TermsAndConditions} />
          <Route path="privacypolicy" Component={PrivacyPolicy} />
          <Route path="faqs" Component={FAQs} />
          <Route path="blog1" Component={Blog1} />
          <Route path="bloggemini" Component={BlogGemini} />
          <Route path="javablog" Component={Java} />
          <Route path="marketingblog" Component={Marketing} />
          <Route path="reactnativeblog" Component={ReactNative} />
          <Route path="uiuxblog" Component={UiUx} />
          <Route path="uiuxintroblog" Component={UiUxInro} />

          <Route path="customerfeedback" Component={CustomerFeedBack} />
          <Route path="readnews" Component={ReadNews} />
          <Route path="newsupdate" Component={News} />
          <Route path="uiuxteam" Component={UiUxTeam} />
          <Route path="backendteam" Component={BackendTeam} />
          <Route path="aimlteam" Component={AiMlTeam} />
          <Route path="devopsteam" Component={DevOpsTeam} />
          <Route path="frontteam" Component={FrontendTeam} />
          <Route path="pmbateam" Component={PmBaTeam} />
          <Route path="member1" Component={Member1} />
          <Route path="blogsubmission" Component={BlogSubmission} />
          <Route path="project01" Component={Project01} />
          <Route path="project02" Component={Project02} />
          <Route path="project03" Component={Project03} />
          <Route path="project04" Component={Project04} />
          <Route path="sevacancies" Component={SEVacancies} />
          <Route path="frontendvacancies" Component={FrontEndVacancies} />
          <Route path="backendvacancies" Component={BackEndVacancies} />
          <Route path="fullstackvacancies" Component={FullStackVacancies} />
          <Route path="mobilevacancies" Component={MobileVacancies} />
          <Route path="devopsvacancies" Component={DevopsVacancies} />
          <Route path="qavacancies" Component={QAVacancies} />
          <Route path="saarchitecvacancies" Component={SAArchitecVacancies} />
          <Route path="mlvacancies" Component={MLVacancies} />
          <Route
            path="dataengineervacanicies"
            Component={DataEngineerVacancies}
          />
          <Route path="backendapply" Component={BackEndApply} />
          <Route path="dataengineerapply" Component={DataEngineerApply} />
          <Route path="devopsapply" Component={DevOpsApply} />
          <Route path="frontendapply" Component={FrontEndApply} />
          <Route path="fullstackapply" Component={FullStackApply} />
          <Route path="mlapply" Component={MlApply} />
          <Route path="mobileapply" Component={MobileApply} />
          <Route path="qaapply" Component={QAApply} />
          <Route path="seapply" Component={SEApply} />
          <Route
            path="softwarearchitecapply"
            Component={SoftwareArchitecApply}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

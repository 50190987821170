import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import ZxLogo from "../Component/Images/HeaderImages/ZxLogo.png";

const Header = () => {
  const [isTabletView, setIsTabletView] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsTabletView(screenWidth <= 1024); // Tablet breakpoint
      setIsMobileView(screenWidth < 768); // Mobile breakpoint (adjust as needed)
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 180);
    };

    handleResize();
    handleScroll();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClasses = `fixed w-full top-0 z-50 backdrop-filter lg:h-[110px] max-lg:h-[100px] max-sm:h-[80px] lg:pt-[2%] max-lg:pt-[0%] lg:pl-[4%] md:pl-[8%]`;
  const containerClasses = " mx-auto flex items-center justify-between";
  const menuClasses = isScrolled ? "bg-[#5882c1]/[0.6] backdrop-blur-[10px] " : "";
  
  const logoSize = isMobileView ? "w-[90px]" : isTabletView ? "w-[170px] " : "w-[190px]";
  const headerPadding = isMobileView ? "py-4 px-9 h-10" : isTabletView ? "py-1 pl-[-qppx]" : "pt-[20px] pb-[30px]";
  
  // Simplified logoMargin with a constant value
  const logoMargin = "mt-[-5px]";
  

  return (
    <header  className={`${headerClasses} ${menuClasses} ${headerPadding}`}>
      <div className={`${containerClasses} ${isTabletView ? "mt-8" : ""}`}>
        <a href="/" className={logoMargin}>
          <img src={ZxLogo} alt="logo" className={logoSize} />
        </a>
        <Menu />
      </div>
    </header>
  );
};

export default Header;

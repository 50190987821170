import React, { useState, useEffect } from "react";
import Section2Background from "../../Images/PrivacyPolicyImages/Section2Background.webp";
import { RxDotFilled } from "react-icons/rx";

const fontFamily = "Poppins";

const TermsSection2 = () => {
  const [deviceView, setDeviceView] = useState("web");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="w-[100%] h-full bg-cover bg-center py-[5%]  mt-[-5px]"
        style={{ backgroundImage: `url(${Section2Background})` }}
      >
        <div className="ml-[10%] w-[80%] max-sm:w-[90%] max-sm:mx-[5%] h-full border-[#fff]/[.10] p-20 max-sm:p-8 max-lg:p-16 bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
          <div>
            <h1
              className="text-white text-2xl max-lg:text-xl max-sm:text-lg font-bold leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              Welcome to Zynovatex!
            </h1>
            <p
              className="text-white text-xl max-lg:text-lg max-sm:text-base font-light leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              These terms and conditions outline the rules and regulations for
              the use of Zynovatex's Website, located at www.zynovatex.com.
              <br />
              <br />
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use zynovatex if you do not agree
              to take all of the terms and conditions stated on this page.
              <br />
              <br />
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company's terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client's needs in respect of
              provision of the Company's stated services, in accordance with and
              subject to, prevailing law of lk. Any use of the above terminology
              or other words in the singular, plural, capitalization and/or
              he/she or they, are taken as interchangeable and therefore as
              referring to same.
              <br />
              <br />
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              {" "}
              Cookies
            </h1>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Unless otherwise stated, Zynovatex and/or its licensors own the
              intellectual property rights for all material on zynovatex. All
              intellectual property rights are reserved. You may access this
              from zynovatex for your own personal use subjected to restrictions
              set in these terms and conditions.
              <br />
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              License
            </h1>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Unless otherwise stated, Company Name and/or its license own the
              intellectual property rights for all material on Website Name. All
              intellectual property rights are reserved. You may access this
              from the website name for your own personal use, subject to the
              restrictions set forth in these terms and conditions.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-bold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              You must not,
            </h1>
            <p
              className="text-white text-xl max-lg:text-lg max-sm:text-base  font-light leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Republish material from zynovatex
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Sell, rent or sub-license material from zynovatex
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Reproduce, duplicate or copy material from zynovatex
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Redistribute content from zynovatex
              </span>
            </p>
            <p
              className="text-white text-xl max-lg:text-lg max-sm:text-base  font-light leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              This Agreement shall begin on the date hereof. Our Terms and
              Conditions were created with the help of the Free Terms and
              Conditions Generator.
            </p>

            <p
              className="text-white text-xl max-lg:text-lg max-sm:text-base  font-light leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Parts of this website offer an opportunity for users to post and
              exchange opinions and information in certain areas of the website.
              Zynovatex does not filter, edit, publish or review Comments prior
              to their presence on the website. Comments do not reflect the
              views and opinions of Zynovatex,its agents and/or affiliates.
              Comments reflect the views and opinions of the person who post
              their views and opinions. To the extent permitted by applicable
              laws, Zynovatex shall not be liable for the Comments or for any
              liability, damages or expenses caused and/or suffered as a result
              of any use of and/or posting of and/or appearance of the Comments
              on this website.
            </p>

            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Zynovatex reserves the right to monitor all Comments and to remove
              any Comments which can be considered inappropriate, offensive or
              causes breach of these Terms and Conditions.
            </p>
          </div>
          <div>
            <h1
              className="text-white text-2xl font-bold  max-lg:text-xl max-sm:text-lg leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              You warrant and represent that:
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent or otherwise unlawful material which is an invasion of
                privacy
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
              </span>
            </p>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              You hereby grant Zynovatex a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit
              any of your Comments in any and all forms, formats or media.
            </p>
          </div>

          <div>
            <h1
              className="text-white text-2xl font-extrabold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Hyperlinking to our Content
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              The following organizations may link to our Website without prior
              written approval:
            </p>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Government agencies;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Search engines;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                News organizations;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                System wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </span>
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              These organizations may link to our home page, to publications or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party's site.
            </p>
          </div>
          <div>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We may consider and approve other link requests from the following
              types of organizations:
            </p>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                commonly-known consumer and/or business information sources;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                dot.com community sites;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                associations or other groups representing charities;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                online directory distributors;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                internet portals;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                accounting, law and consulting firms; and
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                educational institutions and trade associations.
              </span>
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We will approve link requests from these organizations if we
              decide that: (a) the link would not make us look unfavorably to
              ourselves or to our accredited businesses; (b) the organization
              does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of
              Zynovatex; and (d) the link is in the context of general resource
              information.
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              These organizations may link to our home page so long as the link:
              (a) is not in any way deceptive; (b) does not falsely imply
              sponsorship, endorsement or approval of the linking party and its
              products or services; and (c) fits within the context of the
              linking party's site.
            </p>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our website, you must inform us
              by sending an e-mail to Zynovatex. Please include your name, your
              organization name, contact information as well as the URL of your
              site, a list of any URLs from which you intend to link to our
              Website, and a list of the URLs on our site to which you would
              like to link. Wait 2-3 weeks for a response.
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Approved organizations may hyperlink to our Website as follows:
            </p>
          </div>

          <div>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                By use of our corporate name; or
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                By use of the uniform resource locator being linked to; or
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party's site.
              </span>
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              No use of Zynovatex's logo or other artwork will be allowed for
              linking absent a trademark license agreement.
            </p>

            <h1
              className="text-white text-2xl font-extrabold leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              iFrames
            </h1>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website.
            </p>
            <h1
              className="text-white text-2xl font-extrabold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Content Liability
            </h1>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We shall not be hold responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
            <h1
              className="text-white text-2xl font-extrabold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Reservation of Rights
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amen these terms and conditions and it's linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </p>
            <h1
              className="text-white text-2xl font-extrabold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Removal of links from our website
            </h1>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly.
            </p>
            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </p>
            <h1
              className="text-white text-2xl font-extrabold max-lg:text-xl max-sm:text-lg  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              Disclaimer
            </h1>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website. Nothing in this disclaimer will:
            </p>

            <p
              className="text-white text-xl font-light max-lg:text-lg max-sm:text-base  leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                limit or exclude our or your liability for death or personal
                injury;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </span>
              <span className="flex">
                <span className="pr-2 mt-2">
                  <RxDotFilled />
                </span>
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </span>
            </p>
            <p
              className="text-white text-xl font-light  max-lg:text-lg max-sm:text-base leading-[35px] text-justify"
              style={{ fontFamily: fontFamily }}
            >
              <br />
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsSection2;

import React, { useRef, useState, useEffect } from 'react';
import BackButton from '../../../../Images/ServiceImages/ReadeMoreImages/BackButton.png';
import ForwardButton from '../../../../Images/ServiceImages/ReadeMoreImages/ForwardButton.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
import c from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/c.webp';
import csharp from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/cshrp.webp';
import cpp from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/cpp.webp';
import css from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/CSS.webp';
import html from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/HTML.webp';
import java from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/java.webp';
import js from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/JS.webp';
import py from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/python.webp';
import ts from '../../../../Images/ServiceImages/ReadeMoreImages/Languages/TS.webp';


const Cloud = () => {
    const swiperRef = useRef(null);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slideNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const slidesPerView = () => {
        switch (deviceView) {
            case 'mobile':
                return 3;
            case 'tablet':
                return 5;
            case 'web':
            default:
                return 6;
        }
    };


    return (
        <div className='w-full'>
            <Swiper className=''
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                slidesPerView={slidesPerView()}
                navigation={{
                    nextEl: '.next-button',
                    prevEl: '.prev-button',
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                <div>
                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={c} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={csharp} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={cpp} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={css} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={html} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={java} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={js} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={py} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="lightgray flex-shrink-0 lg:w-[60%] lg:ml-10 md:w-[60%] md:ml-10 w-[60%] ml-[20%]">
                            <img src={ts} alt="Html Image" />
                        </div>
                    </SwiperSlide>

                </div>

                <div>
                    {/* Your existing code for navigation buttons */}
                    <div className="absolute top-[30px] left-2 z-50 ml-[-10px] mr-10 transition-all duration-300 ease-in-out hover:scale-100">
                        <div className="flex text-white next-button" onClick={slidePrev}>
                            <img src={BackButton} alt='back button' className='w-5 lg:w-10 md:w-8' />
                        </div>
                    </div>
                    <div className="absolute top-[30px] right-[5px] z-50 mr-[0px] transition-all duration-300 ease-in-out hover:scale-100">
                        <div className="text-white prev-button" onClick={slideNext}>
                            <img src={ForwardButton} alt='previous button' className='w-5 lg:w-10 md:w-8' />
                        </div>
                    </div>

                    {/* New styles for border glow effect */}
                    <style>
                        {`
                        .container .next-button:hover,
                        .container .prev-button:hover {
                            box-shadow: 0px 0px 10px 1px rgba(151, 216, 222, 0.5);
                            border: 0px solid rgba(255, 255, 255, 0.225);
                            border-radius: 50px;
                        }
                    `}
                    </style>
                </div>
            </Swiper>
        </div>
    );
};

export default Cloud;

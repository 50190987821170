import React from 'react'
import Section1 from './AiMlSections/Section1';
import Section2 from './AiMlSections/Section2';
import Section3 from './AiMlSections/Section3';
import Section4 from './AiMlSections/Section4';
import Section5 from './AiMlSections/Section5';



const AiMlTeam = () => {
    return (
        <div>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </div>
    )
}

export default AiMlTeam

import React from 'react'
import Section1 from './FrontEndSection1';
import Section2 from "./FrontEndSection2";
import Section3 from "./FrontEndSection3";

const FrontEndVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

export default FrontEndVacancies

import React from 'react'
import ReactNativeSec01 from "./ReactNativeSec01";
import ReactNativeSec02 from "./ReactNativeSec02";
const ReactNative = () => {
  return (
    <div>
      <ReactNativeSec01 />
      <ReactNativeSec02 />
    </div>
  );
}

export default ReactNative

import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import TeamMember1 from "../../../Images/AboutImages/Section3/Director02.png";
import TeamMember2 from "../../../Images/AboutImages/Section3/Director02.png";
import TeamMember3 from "../../../Images/AboutImages/Section3/Director03.png";
import PreviousButton from "../../../Images/HomeImages/Section5/PreviousButton.png";
import BackButton from "../../../Images/HomeImages/Section5/BackButton.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamSocialMedia from "../../../Assests/TeamSocialMedia";
import { Link } from "react-router-dom";

const UiUxTeam = () => {
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const sliderRef = useRef(null);

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      console.log("Slider component is mounted");
    }
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.1)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="text-center text-white">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="h-[100%] w-[80%] mx-[10%] pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[25px] font-bold font-['Poppins']">
                    Mr. Dilan Gunasekara
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  <Link to="/member1">
                    {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                      View More
                    </div> */}
                  </Link>
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="h-[100%] w-[80%] mx-[10%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[25px] font-bold font-['Poppins']">
                    Mr. Nadushka Janithl
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="h-[100%] w-[80%] mx-[10%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember3}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[25px] font-bold font-['Poppins']">
                    Mr. Kusal chathuranga
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 4 */}
              {/* <div>
                <div className="h-[100%] w-[80%] mx-[10%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[25px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* card 5 */}
              {/* <div>
                <div className="h-[100%] w-[80%] mx-[10%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[25px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* Add more cards as needed */}
            </Slider>
            {/* <div>
              <div className="absolute transform -translate-y-1/4 mt-[-250px] left-24 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute mt-[-250px] transform -translate-y-1/4 right-24">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div> */}
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="text-center text-white">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[20px] font-bold font-['Poppins']">
                    Mr. Dilan Gunasekara
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[20px] font-bold font-['Poppins']">
                    Mr. Nadushka Janithl
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember3}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[20px] font-bold font-['Poppins']">
                    Mr. Kusal chathuranga
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 4 */}
              {/* <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[20px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* card 5 */}
              {/* <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[20px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[15px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* Add more cards as needed */}
            </Slider>
            <div>
              <div className="absolute w-10 mt-5 left-80 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute w-10 mt-5 transform right-80 ">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="text-center text-white">
            <Slider
              ref={sliderRef}
              {...settings}
              className="flex justify-center"
            >
              {/* card 1 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[18px] font-bold font-['Poppins']">
                    Mr. Dilan Gunasekara
                  </div>
                  <div className="opacity-80 text-center text-white text-[13px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 2 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[18px] font-bold font-['Poppins']">
                    Mr. Nadushka Janithl
                  </div>
                  <div className="opacity-80 text-center text-white text-[13px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UI Designer
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 3 */}
              <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember3}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[18px] font-bold font-['Poppins']">
                    Mr. Kusal chathuranga
                  </div>
                  <div className="opacity-80 text-center text-white text-[13px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  {/* <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div> */}
                </div>
              </div>

              {/* card 4 */}
              {/* <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember1}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[18px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[13px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* card 5 */}
              {/* <div>
                <div className="h-[100%] w-[90%] mx-[5%]  pb-4 rounded-xl  border-2 border-[#fff]/[.29] bg-[#fff]/[.18]">
                  <img
                    src={TeamMember2}
                    alt="teammember1"
                    className="w-full rounded-xl"
                  />
                  <div className="text-center mt-4 text-white text-[18px] font-bold font-['Poppins']">
                    Mr. Nilan Fernando
                  </div>
                  <div className="opacity-80 text-center text-white text-[13px] font-normal font-['Poppins'] uppercase tracking-widest">
                    UX Researcher
                  </div>
                  <TeamSocialMedia />
                  <div className="text-center mt-4 text-white text-[11px] font-semibold font-['Poppins'] uppercase tracking-wider">
                    View More
                  </div>
                </div>
              </div> */}

              {/* Add more cards as needed */}
            </Slider>
            <div>
              <div className="absolute w-10 mt-5 left-36 ">
                <button onClick={previousSlide}>
                  <img
                    src={BackButton}
                    alt="Previous"
                    style={{
                      ...(isLinkedinHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsLinkedinHovered(true)}
                    onMouseLeave={() => setIsLinkedinHovered(false)}
                  />
                </button>
              </div>
              <div className="absolute w-10 mt-5 transform right-36 ">
                <button onClick={nextSlide}>
                  <img
                    src={PreviousButton}
                    alt="Next"
                    style={{
                      ...(isTikTokHovered && iconHoverStyle),
                      ...fontFamilyStyle,
                    }}
                    onMouseEnter={() => setIsTikTokHovered(true)}
                    onMouseLeave={() => setIsTikTokHovered(false)}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UiUxTeam;

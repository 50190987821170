import React, { useState, useEffect } from "react";
import { LuCircleDot } from "react-icons/lu";
import { FaRegCircleDot } from "react-icons/fa6";
import { GoChevronRight } from "react-icons/go";
import BgImage1 from "../../Images/HomeImages/Section1/Bg1.webp";
import BgImage2 from "../../Images/HomeImages/Section1/Bg2.webp";
import BgImage3 from "../../Images/HomeImages/Section1/Bg3.webp";
import { Link } from "react-router-dom";

const HomeSection1Example = () => {
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const [currentImage, setCurrentImage] = useState(0);
  const images = [BgImage1, BgImage2, BgImage3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4010);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const imageContent = [
    {
      heading: (
        <>
          AI-Powered Innovations,
          <br /> Transformative
          <br /> Solutions Unleashing
          <br /> Business Potential.
        </>
      ),
      description: "Data Dynamo",
      title: "IT Brilliance, AI Synergy, Software Mastery",
    },
    {
      heading: (
        <>
          Machine Learning Mastery,
          <br /> Crafting Futuristic
          <br /> Solutions Elevating
          <br /> Business Horizons.
        </>
      ),
      description: "Tech Vision",
      title: "IT Brilliance, AI Synergy, Software Mastery",
    },
    {
      heading: (
        <>
          Navigating the Future with AI,
          <br /> Unleash the Power
          <br /> of Data Elevating
          <br /> Businesses to New Heights.
        </>
      ),
      description: "Innovate Insight",
      title: "IT Brilliance, AI Synergy, Software Mastery",
    },
  ];

  const styles = `
      @keyframes typing {
        from {
          width: 0;
        }
      }
    
      @keyframes blink-caret {
        to {
          border-right-color: transparent;
        }
      }
    
      .animation {
        width: 20.20ch;
        white-space: nowrap;
        overflow: hidden;
        animation: typing 4s steps(40, end) infinite, blink-caret 0.5s step-end infinite alternate;
      }

      .slider {
      transition: background-image 0.5s ease-in-out;
    }
    `;

  const glowButton = `
        /* CSS for button glow effect */
        @keyframes glow {
          0% {
            box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.8);
          }
          50% {
            
          }
          100% {
            box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.8);
          }
        }
    
        /* Apply glow effect on hover */
        .glowButton:hover {
          animation: glow 1s infinite;
        }
      `;

  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      setIsMobileView(screenWidth <= 768); // Adjust the value based on your mobile breakpoint
      setIsTabletView(screenWidth > 768 && screenWidth <= 1024); // Adjust the values based on your tablet breakpoint
    };

    // Set initial state on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* Tablet View */}
      {isTabletView && (
        <div className="tablet-view">
          <div>
            <div className="relative">
              <style>{styles}</style>
              <style>{glowButton}</style>
              <div
                className="flex items-center w-full h-screen bg-center bg-cover slider"
                style={{
                  backgroundImage: `url(${images[currentImage]})`,
                }}
              >
                <div className="text-white px-48 xl:ml-0 lg:ml-[-108px] lg:mt-10 md:ml-[-130px] min-[425px]:ml-[-130px] ">
                  <p className="  text-white text-[26px] font-semibold font-['poppins'] capitalize leading-[49px]">
                    {imageContent[currentImage].heading}
                  </p>
                  <h1 className=" animation  w-[406.68px] text-white font-['poppins'] text-[13px] font-bold uppercase leading-[64.50px]">
                    {imageContent[currentImage].description}
                  </h1>
                  <p className="w-[415.78px] text-slate-300 text-xs font-['poppins'] font-semibold uppercase leading-[18.02px]">
                    {" "}
                    {imageContent[currentImage].title}
                  </p>
                  <Link to="/contact">
                    <div className="w-[148.71px] h-[35px] mt-4 pl-3 py-2.5 bg-gradient-to-l from-indigo-950 via-indigo-900 to-blue-900 rounded-[100px] justify-center items-center gap-[7px] inline-flex glowButton">
                      <div className="text-white text-sm font-medium capitalize leading-[21px]">
                        <span className="flex">
                          Get in touch
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/about">
                    <div className="w-[148.71px] h-[35px] ml-4 py-2.5 rounded-[100px] border-2 border-blue-900 justify-center items-center gap-[27px] inline-flex glowButton">
                      <div className="text-white text-sm font-medium capitalize leading-[21px]">
                        <span className="flex">
                          Learn more
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{
                                fontSize: "20px",
                                fontWeight: "extrabold",
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Bottom dots */}
              <div className="absolute flex space-x-2 transform -translate-x-1/2 bottom-4 left-1/2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`text-white ${
                      index === currentImage ? "text-[18px]" : ""
                    }`}
                  >
                    {index === currentImage ? (
                      <FaRegCircleDot />
                    ) : (
                      <LuCircleDot />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile View */}
      {isMobileView && (
        <div className="mobile-view">
          <div>
            <div className="relative">
              <style>{styles}</style>
              <style>{glowButton}</style>
              <div
                className="flex items-center w-full h-screen bg-center bg-cover slider"
                style={{
                  backgroundImage: `url(${images[currentImage]})`,
                }}
              >
                <div className="text-white pl-[10%] pr-4 pt- pb-8">
                  <p className="  text-white min-[320px]:text-[20px] font-['poppins'] min-[200px]:text-[15px] min-[100px]:leading-[30px] font-semibold  capitalize leading-[39px]">
                    {imageContent[currentImage].heading}
                  </p>
                  <h1 className=" animation  w-[80%] text-white font-['poppins'] min-[320px]:text-[20px] min-[200px]:text-[15px] min-[200px]:leading-[50px] font-bold uppercase leading-[74.50px]">
                    {imageContent[currentImage].description}
                  </h1>
                  <p className="w-[80%] text-slate-300 text-[10px] font-['poppins'] font-semibold uppercase leading-[18.02px]">
                    {" "}
                    {imageContent[currentImage].title}
                  </p>
                  <Link to="/contact">
                    <div className="w-[148.71px] h-[35px] mt-5 pl-3 py-2.5 bg-gradient-to-l from-indigo-950 via-indigo-900 to-blue-900 rounded-[100px] justify-center items-center  inline-flex glowButton">
                      <div className="text-white text-sm font-medium capitalize leading-[21px]">
                        <span className="flex">
                          Get in touch
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/about">
                    <div className="w-[148.71px] h-[35px] min-[375px]:ml-4 mt-4 py-2.5 rounded-[100px] border-2 border-blue-900 justify-center items-center gap-[27px] inline-flex glowButton">
                      <div className="text-white text-sm font-medium capitalize leading-[21px]">
                        <span className="flex">
                          Learn more
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{
                                fontSize: "20px",
                                fontWeight: "extrabold",
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* Add any content you want to display over the background here */}
              </div>

              {/* Bottom dots */}
              <div className="absolute flex space-x-2 transform -translate-x-1/2 bottom-4 left-1/2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`text-white ${
                      index === currentImage ? "text-[18px]" : ""
                    }`}
                  >
                    {index === currentImage ? (
                      <FaRegCircleDot />
                    ) : (
                      <LuCircleDot />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Web View */}
      {!isMobileView && !isTabletView && (
        <div className="desktop-view">
          <div>
            <div className="relative">
              <style>{styles}</style>
              <style>{glowButton}</style>
              <div
                className="flex items-center w-full h-screen bg-center bg-cover slider"
                style={{
                  backgroundImage: `url(${images[currentImage]})`,
                }}
              >
                <div className="text-white px-40 xl:ml-0 lg:ml-[-108px] lg:mt-10 md:ml-[-130px] min-[425px]:ml-[-130px] slider">
                  <p className="  text-[31px] mb-2 text-white font-medium font-['poppins'] capitalize leading-[46.50px] ">
                    {imageContent[currentImage].heading}
                  </p>
                  <h1 className=" animation  mb-2 text-white text-[50px] font-['poppins'] capitalize leading-[66.50px] text-6xl font-medium mt-5 ">
                    {imageContent[currentImage].description}
                  </h1>
                  <p className="text-slate-300 text-base font-semibold  font-['poppins'] uppercase leading-[60px] mt-5 mb-5">
                    {" "}
                    {imageContent[currentImage].title}
                  </p>

                  <Link to="/contact">
                    <div className="w-[206px] h-[49px] px-[33px] py-2.5 bg-gradient-to-l from-indigo-950 via-indigo-900 to-blue-900 rounded-[100px] justify-center items-center gap-2.5 inline-flex mr-4 glowButton cursor-pointer">
                      <div className="text-white text-lg font-medium capitalize leading-[27px]">
                        <span className="flex">
                          Get in touch
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{ fontSize: "24px", fontWeight: "bold" }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>

                  <Link to="/about">
                    <div className="w-[206px] h-[49px] px-[33px] py-2.5 rounded-[100px] border-2 border-blue-900 justify-center items-center gap-[27px] inline-flex glowButton cursor-pointer">
                      <div className="text-white text-lg font-medium capitalize leading-[27px]">
                        <span className="flex">
                          Learn More
                          <span className="mt-[2px]">
                            <GoChevronRight
                              style={{
                                fontSize: "24px",
                                fontWeight: "extrabold",
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* Add any content you want to display over the background here */}
              </div>

              {/* Bottom dots */}
              <div className="absolute flex space-x-2 transform -translate-x-1/2 bottom-4 left-1/2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`text-white ${
                      index === currentImage ? "text-[22px]" : ""
                    }`}
                  >
                    {index === currentImage ? (
                      <FaRegCircleDot />
                    ) : (
                      <LuCircleDot />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeSection1Example;

import React, { useState, useEffect } from "react";
import BgImage from "../../../Images/CareerImages/Section1/Section1Background.webp";

const fontFamily = "Poppins";

const DESection1 = () => {
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      setIsMobileView(screenWidth <= 768); // Adjust the value based on your mobile breakpoint
      setIsTabletView(screenWidth >= 768 && screenWidth <= 1024); // Adjust the values based on your tablet breakpoint
    };

    // Set initial state on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []);

  return (
    <>
      <div className="relative">
        <div
          className="flex justify-center h-screen bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${BgImage})` }}
        >
          <div className="text-center">
            <h1
              className="text-5xl font-bold tracking-wider text-white uppercase mt-80 max-lg:mt-60 max-lg:text-4xl max-md:text-2xl max-sm:text-lg"
              style={{ fontFamily: fontFamily }}
            >
              Data Engineer
            </h1>
            <div
              className=" text-center text-white text-lg max-md:text-base font-normal uppercase tracking-[3px] mx-auto mt-8"
              style={{ fontFamily: fontFamily }}
            >
              Full Time | Online
            </div>
            <div
              className=" w-[80%] text-center text-white text-lg max-md:text-base max-sm:text-sm font-normal capitalize leading-[32px] mx-auto mt-8"
              style={{ fontFamily: fontFamily }}
            >
              Works on the development, construction, testing, and maintenance of data architectures, 
              and such as databases and large-scale processing systems.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DESection1;

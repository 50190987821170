import React, { useState, useEffect } from "react";
import backgroundImage from "../../../Images/ProjectImages/ProjectSection1.webp";
import Project01 from "../../../Images/ProjectImages/Section2/ProjectImage2.webp";
import "../../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../../Assests/style";

const fontFamily = "Poppins";

const Section0001 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  
  return (
    <div
      className="p-[8%] max-sm:pt-[20%] max-md:pt-[10%] max-lg:pt-[12%] bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <ScrollTrigger
        onEnter={() => setConterOn(true)}
        onExit={() => setConterOn(false)}
      >
        <div style={{ marginBottom: "-64px" }}>
          {counterOn && (
            <div>
              <Text text={"WEBSITES "} deviceView={deviceView} />
            </div>
          )}
        </div>
        <div className="text-center">
          <h1
            className="font-semibold text-white text-5xl max-lg:text-3xl max-md:text-2xl max-lg:mt-4 max-md:mt-8 mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
            style={{ fontFamily: fontFamily }}
          >
            WEBSITES
          </h1>
        </div>
        <div className="flex justify-center mt-10 ">
          <img src={Project01} alt="project01" />
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default Section0001;

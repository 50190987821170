import React, { useState, useRef, useEffect } from 'react';
import '../../../../Assests/homeSection2Style.css';
import Img1 from '../../../../Images/BlogImages/CardImages/Blog1.webp';
import Img2 from '../../../../Images/BlogImages/CardImages/Blog2.webp';
import Img3 from '../../../../Images/BlogImages/CardImages/Blog3.webp';
import Section1Background from '../../../../Images/BlogImages/Section1Background.webp';
import NextBtn from "../../../../Images/ServiceImages/Section2/Next.png";
import PreviousBtn from "../../../../Images/ServiceImages/Section2/PreviousBtn.png";
import Rec from './RecBlog';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';


const BlogSec3 = () => {
  const swiperRef = useRef(null); // Added useRef for Swiper

  const cardsData = [
    {
      image: Img1,
      title:
        "Artificial Intelligence: Simulation of human intelligence in machine...",
      description: "Artificial Intelligence",
      path: "uiuxservice",
    },
    {
      image: Img2,
      title:
        "Artificial Intelligence: Simulation of human intelligence in machine...",
      description: "Artificial Intelligence",
      path: "uiuxservice",
    },
    {
      image: Img3,
      title:
        "Artificial Intelligence: Simulation of human intelligence in machine...",
      description: "Artificial Intelligence",
      path: "uiuxservice",
    },
  ];

  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      setIsMobileView(screenWidth < 768); // Adjust the value based on your mobile breakpoint
      setIsTabletView(screenWidth >= 768 && screenWidth <= 1050); // Adjust the values based on your tablet breakpoint
    };

    // Set initial state on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    nextArrow: null,
    prevArrow: null,
    Autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNextSlide = () => {
    swiperRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    swiperRef.current.slickPrev();
  };

  return (
    <>
      {!isMobileView && !isTabletView && (
        <div
          className="relative w-[100%] h-full -mt-2 pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
          style={{ backgroundImage: `url(${Section1Background})` }}
        >
          <div className="relative ">
            <div className="w-[80%] h-[22.37px] pt-10 text-white text-5xl font-bold font-['Poppins'] leading-tight pb-5 text-left px-[18%]">
              More From Nipun Fernando
            </div>

            <div className="flex justify-center">
              <div className="grid grid-cols-3 gap-16">
                {cardsData.map((card, index) => (
                  <div
                    className={`rounded-lg overflow-hidden shadow-lg ${
                      index >= 3 ? "mt-[50px]" : "mt-[100px]"
                    } transition-transform transform-gpu hover:scale-105 relative`}
                    style={{
                      boxShadow: "15px 15px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <div className="relative">
                      <img
                        src={card.image}
                        alt={"services"}
                        className="w-[359.74px] h-[507.17px] object-cover object-center"
                      />
                      <div className="absolute mt-12 ml-8 leading-6 text-center top-1/2">
                        <p className="text-left mr-5 w-auto text-white text-[15px]  font-semibold font-['Poppins'] capitalize pb-4">
                          {card.title}
                        </p>
                        <p className="text-left -mt-2 text-white text-[15px] font-normal font-['Poppins'] capitalize">
                          {card.description}
                        </p>
                        <div className="flex items-center mb-5">
                          <Link to="/blog1">
                            <p
                              className="
                                                    text-white 
                                                    font-['Poppins'] 
                                                    font-semibold 
                                                    leading-[normal] 
                                                    uppercase 
                                                    mr-auto 
                                                    pl-4 
                                                    mt-[-5px] 
                                                    md:leading-[normal] 
                                                    md:font-semibold 
                                                    md:mt-10 
                                                    hover:text-blue-300"
                            >
                              Read now
                            </p>
                          </Link>
                          <div className="w-6 h-6">
                            <svg
                              width={9}
                              height={16}
                              viewBox="0 0 9 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="md:mt-[25px] ml-[30%]"
                            >
                              <path
                                d="M1.65625 15L6.98686 8.78095C7.37205 8.33156 7.37205 7.66844 6.98686 7.21905L1.65625 1"
                                stroke="#FCF8F8"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Rec />
        </div>
      )}

      {/* Mobile View */}
      {isMobileView && (
        <div
          className="relative w-[100%] h-full -mt-2 pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
          style={{ backgroundImage: `url(${Section1Background})` }}
        >
          <div className="mb-5">
            <div className="w-[90%] h-[22.37px] pt-10 text-white text-2xl font-bold font-['Poppins'] leading-tight pb-5 text-left px-[10%]">
              More From Nipun Fernando
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation={{
                nextEl: ".custom-next-button",
                prevEl: ".custom-prev-button",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="max-w-[80%]"
              ref={swiperRef} // Added ref to Swiper
            >
              {cardsData.map((card, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/${card.path}`}>
                    <div
                      className={`rounded-lg overflow-hidden shadow-lg ${
                        index >= 3 ? "mt-[50px]" : "mt-[50px]"
                      } transition-transform hover:scale-105 relative`}
                    >
                      <div className="relative">
                        <img
                          src={card.image}
                          alt="images"
                          className="w-[420px] h-[480px] object-cover object-center"
                        />
                        <div className="absolute m-5 mt-12 leading-6 text-center top-1/2">
                          <p className="text-left text-white text-[13px] font-semibold font-['Poppins'] capitalize leading-8 pb-4">
                            {card.title}
                          </p>
                          <p className="text-left -mt-3 text-white text-[10px] font-normal font-['Poppins'] capitalize">
                            {card.description}
                          </p>
                          <div className="flex items-center mb-1">
                            <Link to="/blog1">
                              <p
                                className="
                                                    text-white 
                                                    font-['Poppins']  
                                                    leading-[normal] 
                                                    uppercase
                                                    font-semibold 
                                                    mt-5
                                                    text-[12px]
                                                    hover:text-blue-300"
                              >
                                Read now
                              </p>
                            </Link>
                            <div className="w-6 h-6 mt-7">
                              <svg
                                width={9}
                                height={16}
                                viewBox="0 0 9 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="md:mt-[25px] ml-[30%]"
                              >
                                <path
                                  d="M1.65625 15L6.98686 8.78095C7.37205 8.33156 7.37205 7.66844 6.98686 7.21905L1.65625 1"
                                  stroke="#FCF8F8"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Rec />
        </div>
      )}

      {/* Tablet View */}
      {isTabletView && (
        <div
          className="relative w-[100%] h-[100%] -mt-5 pb-20 bg-center bg-cover bg-gradient-to-b from-black to-black"
          style={{ backgroundImage: `url(${Section1Background})` }}
        >
          <div className="mb-5">
            <div className="w-[80%] h-[22.37px] pt-10 text-white text-3xl font-bold font-['Poppins'] leading-tight pb-5 text-left px-[10%]">
              More From Nipun Fernando
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={2}
              navigation={{
                nextEl: ".custom-next-button",
                prevEl: ".custom-prev-button",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="max-w-[80%]"
              ref={swiperRef} // Added ref to Swiper
            >
              {cardsData.map((card, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/${card.path}`}>
                    <div
                      className={`rounded-lg overflow-hidden shadow-lg ${
                        index >= 3 ? "mt-[50px]" : "mt-[50px]"
                      } transition-transform hover:scale-105 relative`}
                    >
                      <div className="relative">
                        <img
                          src={card.image}
                          alt="images"
                          className="w-[420px] h-[480px] object-cover object-center"
                        />
                        <div className="absolute m-5 mt-12 leading-6 text-center top-1/2">
                          <p className="text-left text-white text-[13px] font-semibold font-['Poppins'] capitalize leading-8 pb-4">
                            {card.title}
                          </p>
                          <p className="text-left -mt-3 text-white text-[10px] font-normal font-['Poppins'] capitalize">
                            {card.description}
                          </p>
                          <div className="flex items-center mb-1">
                            <Link to="/blog1">
                              <p
                                className="
                                                    text-white 
                                                    font-['Poppins']  
                                                    leading-[normal] 
                                                    uppercase
                                                    font-semibold 
                                                    mt-5
                                                    text-[12px]
                                                    hover:text-blue-300"
                              >
                                Read now
                              </p>
                            </Link>
                            <div className="w-6 h-6 -mt-5">
                              <svg
                                width={9}
                                height={16}
                                viewBox="0 0 9 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="md:mt-[25px] ml-[30%]"
                              >
                                <path
                                  d="M1.65625 15L6.98686 8.78095C7.37205 8.33156 7.37205 7.66844 6.98686 7.21905L1.65625 1"
                                  stroke="#FCF8F8"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Rec />
        </div>
      )}
    </>
  );
};

export default BlogSec3;

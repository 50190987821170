import React from 'react'
import Section1 from './DESection1';
import Section2 from "./DESection2";
import Section3 from "./DESection3";

const DataEngineerVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

export default DataEngineerVacancies

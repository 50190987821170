import React from 'react'
import BlogSubmissionSection1 from "../Component/Pages/BlogSubmission/BlogSubmissionSection1";
import BlogSubmissionSection2 from "../Component/Pages/BlogSubmission/BlogSubmissionSection2";

const BlogSubmission = () => {
    return (
        <div>
            <BlogSubmissionSection1/>
            <BlogSubmissionSection2/>
        </div>
    )
}

export default BlogSubmission

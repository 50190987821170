import React, { useState, useEffect } from "react";
import Section1Background from "../../Images/CareerImages/Section1/Section1Background.webp";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const fontFamily = "Poppins";

const ContactSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setDeviceView("mobile");
      } else if (width <= 769) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative">
      <div
        className="flex justify-center h-screen bg-center bg-no-repeat bg-cover max-sm:h-screen"
        style={{ backgroundImage: `url(${Section1Background})` }}
      >
        <ScrollTrigger
          onEnter={() => setConterOn(true)}
          onExit={() => setConterOn(false)}
        >
          <div style={{ marginBottom: "-64px", marginTop: "200px " }}>
            {counterOn && (
              <div>
                <Text text={"Contact"} deviceView={deviceView} />
              </div>
            )}
          </div>
          <section className="text-white font-[' Poppins'] text-center">
            <h1
              className='
                        font-semibold 
                        text-white
                        pb-0
                        mt-7
                        text-2xl
                        lg:text-5xl
                        lg:mt-0
                        lg:pb-0
                        md:text-4xl
                        md:mt-4
                        md:pb-0
                        md:tracking-widest 
                        inline-block 
                        border-b 
                        border-gray-300 
                        tracking-wider 
                        uppercase 
                        font-["Poppins"]'
            >
              Contact Us
            </h1>
          </section>
          <section className="grid gap-8 mt-8">
            <div
              className="
                                text-white 
                                text-center 
                                font-['Poppins']   
                                font-normal 
                                capitalize 
                                mx-auto 
                                mb-20
                                min-[320px]:text-sm
                                lg:text-lg 
                                md:text-lg
                                w-[60%]
                                max-lg:w-[80%]
                        "
            >
              Zynovatex has been in service for 6 years since 2018, and we have
              served many happy and satisfied customers throughout the years. In
              Zynovatex we provide several services
            </div>
          </section>
        </ScrollTrigger>
      </div>
    </div>
  );
};

export default ContactSection1;

import React from 'react'
import Section1 from './BackendSection1';
import Section2 from './BackendSection2';
import Section3 from "./BackendSection3";

const BackendVacancies = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

export default BackendVacancies

import React, { useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

const DropDown = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - index
  );

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  return (
    <div className="relative inline-block text-left w-[100%] mt-5">
      <div className="relative">
        <select
          value={selectedYear}
          onChange={handleYearChange}
          className= "block w-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:border-gray-500"
        >
          <option value="">Select year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
          <HiOutlineChevronDown />
        </div>
      </div>
      
    </div>
  );
};

export default DropDown;

import React, { useState, useEffect } from "react";
import Section2Background from '../../Images/CareerImages/Section2/Section2Background.webp';
import '../../Assests/homeSection2Style.css'
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../Assests/style';

const fontFamily = 'Poppins';

const CareerSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <div>
            {deviceView === 'web' && (
                // Web view
                <>
                <div className='relative'>
                    <div className=' h-full bg-cover bg-center bg-no-repeat mt-[-5px] pt-20 pb-20 ' style={{ backgroundImage: `url(${Section2Background})` }}>
                        <ScrollTrigger onEnter={() => setConterOn(true)} onExit={() => setConterOn(false)}>
                                <div style={{ marginBottom: '-64px', marginTop: '-50px '}}>
                                    {counterOn && (
                                    <div>
                                        <Text text={'vacancies'} deviceView={deviceView} />
                                    </div>
                                    )}
                                </div>
                        </ScrollTrigger>
                        <div className="flex justify-center text-4xl font-bold leading-normal text-white uppercase " style={{ fontFamily: fontFamily }}>vacancies</div>
                        
                        <div className="flex justify-center ">
                            <div className="  pr-4 w-[80%] h-[500px] rounded-lg overflow-y-auto">
                                {/* card */}
                                <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]"> UI/Ux Engineer </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]"> Project Manager </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Junior Web Developer </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-10 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <span className=" text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </span>
                                                <div className="ml-[450px] mt-[-43px] w-[25%] pt-[5px] h-12 rounded-[0.4375rem] border border-white">
                                                    <div className="  ml-4 text-white text-justify font-['Poppins'] text-xl font-medium leading-[2.1875rem]"><span>Full Time</span> <span className="ml-20"> Online</span></div>
                                                </div>
                                                <div className="mt-[-52px] ml-[850px]">
                                                        <button className=" px-8 py-2 mr-4  font-medium  text-white text-xl rounded border-4 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">View</button>
                                                        <button className=" px-8 py-2 font-medium  text-white text-xl rounded border-4 border-[#1F2C6E]/[.10] bg-[#1F2C6E]/[1]">Apply</button>
                                                    </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                </>
            )}

            {deviceView === 'tablet' && (
                // Tablet view
                <>
                <div className='relative'>
                    <div className=' h-full bg-cover bg-center bg-no-repeat mt-[5px] pb-16' style={{ backgroundImage: `url(${Section2Background})` }}>
                        <div className="flex justify-center text-4xl font-bold leading-normal text-white uppercase " style={{ fontFamily: fontFamily }}>vacancies</div>
                        
                        <div className="flex justify-center ">
                            <div className="  pr-4 w-[80%] h-[500px] rounded-lg overflow-y-auto">
                                {/* card */}
                                <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                </>
            )}

            {deviceView === 'mobile' && (
                // Mobile view
                <>
                <div className='relative'>
                    <div className=' h-full bg-cover bg-center bg-no-repeat mt-[5px] pb-16' style={{ backgroundImage: `url(${Section2Background})` }}>
                        <div className="flex justify-center text-4xl font-bold leading-normal text-white uppercase " style={{ fontFamily: fontFamily }}>vacancies</div>
                        
                        <div className="flex justify-center ">
                            <div className="  pr-4 w-[80%] h-[500px] rounded-lg overflow-y-auto">
                                {/* card */}
                                <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl  border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                                    <div  className="flex justify-center mt-5">
                                        <div class=" items-center p-8 w-[100%] h-28 rounded-xl border-3 border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                                                <div className="text-white text-justify font-['Poppins'] text-3xl font-medium leading-[2.1875rem]">Senior Software Engineer </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                </>
            )}
        </div>
    );
};

export default CareerSection2;

import React, { useState, useEffect } from "react";
import Section1Background from "../../Images/CareerImages/Section1/Section1Background.webp";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const fontFamily = "Poppins";

const FeedbackSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else if (width <= 350) {
        setDeviceView("small");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-screen bg-cover bg-center bg-no-repeat  "
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
                  {counterOn && (
                    <div>
                      <Text
                        text={"Customer Feedback"}
                        deviceView={deviceView}
                      />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Customer Feedback
                  </h1>
                  <div
                    className="w-[850px]   text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website info.zx@zynovatex.com and its
                    related services.
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-full pb-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-44px", marginTop: "200px " }}>
                  {counterOn && (
                    <div>
                      <Text
                        text={"Customer Feedback"}
                        deviceView={deviceView}
                      />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Customer Feedback
                  </h1>
                  <div
                    className="w-[80%]   text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website info.zx@zynovatex.com and its
                    related services.
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="relative">
            <div
              className="w-[100%] flex justify-center h-full pb-[100px] bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Section1Background})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-24px", marginTop: "200px " }}>
                  {counterOn && (
                    <div>
                      <Text
                        text={"Customer Feedback"}
                        deviceView={"extramobile"}
                      />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-2xl mt-[0] inline-block border-b border-gray-300 pb-0 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Customer
                  </h1>
                  <br />
                  <h1
                    className="font-semibold text-white text-2xl mt-[0] inline-block border-b border-gray-300 pb-0 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Feedback
                  </h1>
                  <div
                    className="w-[80%]   text-center text-white text-sm font-normal capitalize leading-[25px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website info.zx@zynovatex.com and its
                    related services.
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FeedbackSection1;

import React, { useState, useEffect } from "react";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";

const BlogSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6); // Set the initial number of rows
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setActivePage(event.page);
  };

  const template3 = {
    layout: " PrevPageLink PageLinks NextPageLink",
  };

  const getGridCols = () => {
    switch (deviceView) {
      case "mobile":
        return "grid-cols-1";
      case "tablet":
        return "grid-cols-2";
      case "web":
      default:
        return "grid-cols-3";
    }
  };

  const yourCardComponents = [
    {
      id: 1,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 2,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 3,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },

    {
      id: 4,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 5,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 6,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },

    {
      id: 7,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 8,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    {
      id: 9,
      title: "ZYNOVATEX announces new product launch",
      category: "June 15, 2023",
    },
    // Add more cards as needed
  ];

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="relative">
            <section>
              <div>
                {yourCardComponents
                  .slice(first, first + rows)
                  .map((card, index) => (
                    <section key={card.id}>
                      <div></div>
                      <div>
                        <p className="text-white text-2xl font-semibold font-['Poppins']">
                          {card.title}
                        </p>
                        <p className="text-neutral-500 text-[15px] font-medium font-['Poppins']">
                          {card.category}
                        </p>
                        <div className="">
                          <Link to="/readnews">
                            <div className="mt-3 text-cyan-600 text-base font-medium font-['Poppins'] flex">
                              <span>Read News</span>
                              <span className=" ml-1 mt-1 text-[20px]">
                                <GoArrowRight />
                              </span>
                            </div>
                          </Link>
                        </div>
                        {index < yourCardComponents.length - 1 && (
                          <hr className="my-5 border-t border-gray-700" />
                        )}
                      </div>
                    </section>
                  ))}
              </div>
              <div className="paginator-container">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={120}
                  onPageChange={onPageChange}
                  activePage={activePage}
                  className='custom-paginator font-["Poppins"] text-white mt-10 text-lg'
                  template={template3}
                />
              </div>
            </section>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="relative">
            <section>
              <div>
                {yourCardComponents
                  .slice(first, first + rows)
                  .map((card, index) => (
                    <section key={card.id}>
                      <div></div>
                      <div>
                        <p className="text-white text-xl font-semibold font-['Poppins']">
                          {card.title}
                        </p>
                        <p className="text-neutral-500 text-[12px] font-medium font-['Poppins']">
                          {card.category}
                        </p>
                        <div className="">
                          <Link to="/readnews">
                            <div className="mt-3 text-cyan-600 text-sm font-medium font-['Poppins'] flex">
                              <span>Read News</span>
                              <span className=" ml-1 mt-1 text-[15px]">
                                <GoArrowRight />
                              </span>
                            </div>
                          </Link>
                        </div>
                        {index < yourCardComponents.length - 1 && (
                          <hr className="mt-3 mb-5 border-t border-gray-700" />
                        )}
                      </div>
                    </section>
                  ))}
              </div>
              <div className="paginator-container">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={120}
                  onPageChange={onPageChange}
                  activePage={activePage}
                  className='custom-paginator font-["Poppins"] text-white mt-10 text-lg'
                  template={template3}
                />
              </div>
            </section>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="relative">
            <section>
              <div>
                {yourCardComponents
                  .slice(first, first + rows)
                  .map((card, index) => (
                    <section key={card.id}>
                      <div></div>
                      <div>
                        <p className="text-white text-base font-semibold font-['Poppins']">
                          {card.title}
                        </p>
                        <p className="text-neutral-500 text-[12px] font-medium font-['Poppins']">
                          {card.category}
                        </p>
                        <div className="">
                          <Link to="/readnews">
                            <div className="mt-3 text-cyan-600 text-sm font-medium font-['Poppins'] flex">
                              <span>Read News</span>
                              <span className=" ml-1 mt-1 text-[15px]">
                                <GoArrowRight />
                              </span>
                            </div>
                          </Link>
                        </div>
                        {index < yourCardComponents.length - 1 && (
                          <hr className="mt-3 mb-5 border-t border-gray-700" />
                        )}
                      </div>
                    </section>
                  ))}
              </div>
              <div className="paginator-container">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={120}
                  onPageChange={onPageChange}
                  activePage={activePage}
                  className='custom-paginator font-["Poppins"] text-white mt-10 text-base'
                  template={template3}
                />
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
};

export default BlogSection1;

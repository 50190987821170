import React from 'react'
import FeedbackSection1 from '../Component/Pages/FeedbackPage/FeedbackSection1'
import FeedbackSection2 from '../Component/Pages/FeedbackPage/FeedbackSection2'

const Feedback = () => {
  return (
    <div>
      <FeedbackSection1/>
      <FeedbackSection2/>
    </div>
  )
}

export default Feedback

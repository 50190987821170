import React, { useState, useEffect } from "react";
import Section2Background from "../../../Images/TeamsImages/Section5Background.webp";
import "../../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../../Assests/style";
import ReactSlick4 from "../../../Assests/ReactSlick4";

const Section5 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        className=" w-[100%] h-full bg-cover bg-center bg-no-repeat px-[10%] pb-[10%] max-md:pb-[20%] pt-10 "
        style={{ backgroundImage: `url(${Section2Background})` }}
      >
        <ScrollTrigger
          onEnter={() => setConterOn(true)}
          onExit={() => setConterOn(false)}
        >
          <div style={{ marginBottom: "-65px" }}>
            {counterOn && (
              <div>
                <Text text={"Client Testimonials "} deviceView={deviceView} />
              </div>
            )}
          </div>
          <div className="text-center max-sm:mt-10 max-md:mt-10 text-white text-[49px] font-bold font-['Poppins'] uppercase leading-tight max-lg:text-[35px] max-md:text-[30px]">
            Client Testimonials
          </div>
          <div className="text-center mt-[2%] max-sm:mt-5 mb-[3%] text-white text-lg font-medium font-['Poppins'] capitalize leading-[29px] max-lg:text-base max-md:text-sm ">
            At ZYNOVATEX, our UI/UX team excels in crafting captivating digital
            experiences that harmonize aesthetics and usability. Explore our
            accolades that reflect our dedication to transformative design and
            innovation
          </div>
        </ScrollTrigger>
        <div>
          <ReactSlick4 />
        </div>
      </div>
    </div>
  );
};

export default Section5;

import React from 'react'
import SocialMedia from '../Component/Common/SocialMedia';
import BlogSection1 from '../Component/Pages/BlogPage/BlogSection1';
import BlogSection2 from '../Component/Pages/BlogPage/BlogSection2';

const Blog = () => {
  return (
    <div>
      <BlogSection2 />
      <BlogSection1 />
      <SocialMedia />
    </div>
  )
}

export default Blog;

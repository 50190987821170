import React from 'react'
import ProjectSection1 from '../Component/Pages/ProjectPage/ProjectSection1';
import ProjectSection2 from '../Component/Pages/ProjectPage/ProjectSection2';
import SocialMedia from '../Component/Common/SocialMedia';


const Project = () => {
  return (
    <div>
      <ProjectSection1/>
      <ProjectSection2/>
      <SocialMedia/>
    </div>
  )
}

export default Project;

import React from 'react';
import BackendSection1 from "../../CareerViewPages/SoftwareArchiteVacancies/SAArchitecSection1";
import CareerApplySection2 from "../careerApplySection2";
const SoftwareArchitecApply = () => {
  return (
    <div>
      <BackendSection1 />
      <CareerApplySection2 />
    </div>
  );
}

export default SoftwareArchitecApply

import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import ArlicleImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/MarketingImage.png";

const fontFamily = "Poppins";

const MSec02 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Leveraging Conversational Marketing in Digital Marketing Strategies
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            In today's fast-paced digital landscape, marketers are constantly
            seeking innovative ways to engage with their audience and foster
            meaningful connections. One such approach that has been gaining
            significant traction is conversational marketing. By leveraging the
            power of dialogue, conversational marketing not only drives customer
            engagement but also paves the way for more personalized and
            impactful interactions. <br />
            <br /> In the realm of digital marketing, where competition for
            attention is fierce, traditional methods of one-sided communication
            are becoming less effective. Conversational marketing, on the other
            hand, flips the script by enabling brands to create dynamic, two-way
            conversations with their target audience. It leverages various
            channels such as chatbots, live chat, social media messaging, and
            voice assistants to facilitate real-time interactions.
          </div>
        </div>

        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* description */}
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          So, what makes conversational marketing a game-changer?
        </span>
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            01. Building Personal Connections: Conversational marketing allows
            brands to humanize their interactions by engaging customers in
            personalized conversations. By adopting a conversational tone,
            businesses can establish a sense of trust, empathy, and
            authenticity. This, in turn, fosters stronger connections, enhances
            customer loyalty, and drives long-term customer value.
          </div>
          <div className=" mt-4 text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            02. Instantaneous Response and Assistance: In today's era of instant
            gratification, customers expect prompt responses and immediate
            assistance. Conversational marketing tools, such as AI-powered
            chatbots, provide round-the-clock availability and instant responses
            to customer queries. This not only enhances customer satisfaction
            but also enables brands to address customer concerns in real time,
            increasing the likelihood of conversion.
          </div>

          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            03. Data-Driven Insights: Conversational marketing generates a
            wealth of valuable data that can be used to gain insights into
            customer preferences, pain points, and purchasing patterns. By
            analyzing these interactions, businesses can better understand their
            target audience and tailor their marketing strategies accordingly.
            These insights can inform product development, customer
            segmentation, and personalized marketing campaigns, leading to
            improved ROI.
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            04. Enhanced Customer Experience: Traditional marketing approaches
            often fail to deliver a seamless customer experience. Conversational
            marketing, however, focuses on creating frictionless interactions.
            By providing personalized recommendations, guiding customers through
            the purchase journey, and offering real-time support, brands can
            elevate the customer experience and create positive brand
            associations.
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            05. Lead Generation and Nurturing: Conversational marketing is a
            powerful tool for lead generation and nurturing. By engaging
            prospects in conversations, marketers can capture valuable
            information, qualify leads, and guide them toward conversion.
            Furthermore, personalized interactions enable businesses to nurture
            leads by providing relevant content, recommendations, and offers,
            ultimately driving more conversions and revenue.
          </div>
        </div>
        {/* blog second Images */}
        <div className="flex justify-center mt-10">
          <img src={ArlicleImage} alt="BlgImage" />
        </div>
        {/* Second description */}
        <div className="mt-8">
          <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            As conversational marketing continues to evolve, marketers need to
            adopt the right strategies to leverage its full potential. Here are
            a few best practices to consider:
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            01. Understand your audience: Gain a deep understanding of your
            target audience's preferences, pain points, and communication
            channels to create personalized conversational experiences.
            <br />
            <br />
            02. Implement chatbots wisely: AI-powered chatbots can handle
            routine queries and provide instant responses, but it's crucial to
            strike a balance between automation and human touch. Integrate
            chatbots seamlessly into the customer journey while offering human
            assistance when needed.
            <br />
            <br />
            03. Continuously optimize and iterate: Analyse data from
            conversational interactions to identify areas for improvement. Test
            different messaging strategies, optimize chatbot scripts, and refine
            your conversational marketing approach based on customer feedback
            and insights.
          </div>

          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Conversational marketing has emerged as a key driver in the realm of
            digital marketing, revolutionizing the way brands engage with their
            customers. By embracing this interactive and personalized approach,
            businesses can forge stronger connections, deliver exceptional
            customer experiences, and ultimately drive growth.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            So, are you ready to embark on a conversational marketing journey
            and unlock the full potential of your digital marketing efforts?
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSec02;

import React, { useState, useEffect } from 'react';
import BackgroundImage from "../../Images/AboutImages/Section1/Section1Background.webp";
import '../../Assests/homeSection2Style.css'
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../Assests/style';

const AboutSection1 = () => {
    const [counterOn, setConterOn] = useState(false);
    const [deviceView, setDeviceView] = useState('web'); // Default to web view

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
      <div className="relative">
        <div
          className="flex justify-center h-full bg-center bg-no-repeat bg-cover max-sm:h-full"
          style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
          <ScrollTrigger
            onEnter={() => setConterOn(true)}
            onExit={() => setConterOn(false)}
          >
            <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
              {counterOn && (
                <div>
                  <Text text={"About"} deviceView={deviceView} />
                </div>
              )}
            </div>
            <section className="text-white font-[' Poppins'] text-center">
              <h1
                className='
                        font-semibold 
                        text-white
                        pb-0
                        mt-7
                        text-2xl
                        lg:text-5xl
                        lg:mt-0
                        lg:pb-0
                        md:text-4xl
                        md:mt-4
                        md:pb-0
                        md:tracking-widest 
                        inline-block 
                        border-b 
                        border-gray-300 
                        tracking-wider 
                        uppercase 
                        font-["Poppins"]'
              >
                about
              </h1>
            </section>
            <section className="grid gap-8 mt-8">
              <div
                className="
                                text-white 
                                text-center 
                                font-['Poppins']   
                                font-normal 
                                capitalize 
                                mx-auto 
                                mb-20
                                min-[320px]:text-sm
                                lg:text-lg 
                                md:text-lg
                                w-[80%]
                                max-lg:w-[80%]
                        "
              >
                In the digital realm, Zynovatex emerges as a pioneer, embodying
                the fusion of synchronization and innovation. The name itself, a
                blend of "Zyn" for synchronization and "Nova" for novelty,
                mirrors our dedication to seamlessly merging human creativity
                with cutting-edge technology on a global scale. The addition of
                "Tex" underscores our unwavering commitment to leading the
                charge in innovation. Over the last six years, our dedicated
                team of over 40 professionals has been devoted to delivering
                excellence in software and UI/UX projects.
                <br />
                <br />
                Specializing in AI and ML ventures, web development, mobile app
                creation, and an array of IT pursuits, Zynovatex stands out as a
                beacon of innovation. Fueled by a fervor for pushing boundaries,
                our seasoned team ensures each project stands as a testament to
                our pursuit of excellence. More than just delivering solutions,
                we craft universal experiences that seamlessly connect humanity
                with technology. Come join us on a journey to redefine
                possibilities, where Zynovatex isn't merely a name but a
                commitment to groundbreaking solutions that harmonize human
                potential with the ever-evolving landscape of technology.
              </div>
            </section>
          </ScrollTrigger>
        </div>
      </div>
    );
};

export default AboutSection1;
import React, { useState, useEffect } from 'react';
import Section3image from '../../Images/HomeImages/Section3/Section3Background.webp';
import Section3CardImage1 from '../../Images/HomeImages/Section4/CardImage1.webp';
import Section3CardImage2 from "../../Images/HomeImages/Section4/CardImage2.webp";
import Section3CardImage3 from "../../Images/HomeImages/Section4/CardImage3.webp";
import ScrollTrigger from 'react-scroll-trigger';
import Slider from 'react-slick';
import { LuCircleDot } from 'react-icons/lu';
import { FaRegDotCircle } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Assests/dotclass.css';

const HomeSection4 = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [deviceView, setDeviceView] = useState('web'); // Default to web view

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    customPaging: function (i) {
      return (
        <div>
          {i === currentSlide ? (
            <LuCircleDot
              style={{
                color: '#fff',
                fontSize: '14px',
              }}
            />
          ) : (
            <FaRegDotCircle
              style={{
                color: '#fff',
                fontSize: '14px',
              }}
            />
          )}
        </div>
      );
    },
    dotsClass: 'custom-dots-class',
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    afterChange: (index) => setCurrentSlide(index),
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView('mobile');
      } else if (width <= 1024) {
        setDeviceView('tablet');
      } else {
        setDeviceView('web');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <div
          className="bg-cover font-['Poppins'] center relative"
          style={{ backgroundImage: `url(${Section3image})`, height: "100%" }}
        >
          <div className="mx-auto overflow-hidden center">
            <p
              className={`we_have_highly-tailored_it_solution text-white text-center  text-[2.0625rem] font-bold leading-[156%] underline uppercase mt-10`}
            >
              WE HAVE HIGHLY-TAILORED IT SOLUTION
            </p>
            <>
              {/* Card 1 */}
              <ScrollTrigger
                onEnter={() => setIsVisible1(true)}
                onExit={() => setIsVisible1(false)}
              >
                <div
                  className={`mx-auto w-[80%] h-full relative bg-[#5882c1]/[.28] bg-opacity-20 backdrop-filter backdrop-blur-md shadow-md rounded-md border-[3px] border-[#5882c1]/[.49] mt-10 transition-all duration-500 ease-out ${
                    isVisible1 ? "animate-card" : ""
                  }`}
                >
                  <img
                    className={`object-cover w-[30%] mx-auto float-left my-28 max-[1980px]:my-9 max-[1024px]:my-24 max-[1440px]:my-28 transition-transform transform ${
                      isVisible1
                        ? "translate-x-0 opacity-100"
                        : "-translate-x-full opacity-0"
                    } duration-500 ease-out`}
                    src={Section3CardImage1}
                    alt="Card Image"
                  />
                  <div className="flex flex-col justify-center p-4">
                    <h2
                      className={`education text-white text-center text-[1.75rem] font-bold leading-[156%]  underline uppercase transition-transform transform ${
                        isVisible1
                          ? "translate-x-0 opacity-100"
                          : "translate-x-full opacity-0"
                      } duration-500 ease-out`}
                    >
                      EDUCATION
                    </h2>
                    <p
                      className={`text-justify text-2xl leading-[156%] capitalize text-white max-xl:text-[20px] mb-5 mt-10 mr-10 max-xl:mt-5 transition-transform transform ${
                        isVisible1
                          ? "translate-x-0 opacity-100"
                          : "translate-x-full opacity-0"
                      } duration-500 ease-out text-justify whitespace-normal`}
                    >
                      Zynovatex is on the cutting edge, shaking up education
                      with customized IT solutions. We're your go-to experts,
                      diving deep into software development, AI/ML projects, and
                      crafting innovative web designs. Our focus is on arming
                      educational institutions with game-changing tech,
                      smoothing out processes, and sparking innovation for both
                      educators and learners. We're here to optimize, transform,
                      and bring a fresh wave of tech-savvy dynamism to the world
                      of education.
                    </p>
                  </div>
                </div>
              </ScrollTrigger>

              {/* Card 2 */}
              <ScrollTrigger
                onEnter={() => setIsVisible2(true)}
                onExit={() => setIsVisible2(false)}
              >
                <div
                  className={`mx-auto w-[80%] h-full  relative bg-[#5882c1]/[.28] bg-opacity-20 backdrop-filter backdrop-blur-md shadow-md rounded-md border-[3px] border-[#5882c1]/[.49] mt-10 transition-all duration-500 ease-out ${
                    isVisible2 ? "animate-card" : ""
                  }`}
                >
                  <img
                    className={`object-cover w-[30%] mx-auto float-right my-28 max-[1980px]:my-8 max-[1024px]:my-28 max-[1440px]:my-28 transition-transform transform ${
                      isVisible2
                        ? "-translate-x-0 opacity-100"
                        : "translate-x-full opacity-0"
                    } duration-500 ease-out`}
                    src={Section3CardImage2}
                    alt="Card Image"
                  />
                  <div className="p-4 ">
                    <h2
                      className={`business text-white text-center text-[2.0625rem] font-bold leading-[156%] underline uppercase transition-transform transform ${
                        isVisible2
                          ? "translate-x-0 opacity-100"
                          : "-translate-x-full opacity-0"
                      } duration-500 ease-out`}
                    >
                      BUSINESS
                    </h2>
                    <p
                      className={`w-full text-justify text-2xl leading-[156%] capitalize max-xl:text-[20px] text-white mb-5 mt-10 ml-10 transition-transform transform ${
                        isVisible2
                          ? "translate-x-0 opacity-100"
                          : "-translate-x-full opacity-0"
                      } duration-500 ease-out text-justify whitespace-normal`}
                    >
                      In the nexus of innovation and industry prowess, we sculpt
                      the future of business with years of IT finesse. Our forte
                      lies in crafting tailor-made software solutions,
                      avant-garde AI and ML projects, and cutting-edge web
                      designs, catapulting businesses into an era of heightened
                      efficiency and digital finesse. We pledge to redefine
                      industry benchmarks, providing bespoke IT solutions that
                      streamline processes, elevate productivity, and fuel
                      sustainable growth. Embark on a superior business journey
                      with us.
                    </p>
                  </div>
                </div>
              </ScrollTrigger>

              {/* Card 3 */}
              <ScrollTrigger
                onEnter={() => setIsVisible3(true)}
                onExit={() => setIsVisible3(false)}
              >
                <div
                  className={`mx-auto w-[80%] h-[420px]  relative bg-[#5882c1]/[.28] bg-opacity-20 backdrop-filter backdrop-blur-md shadow-md rounded-md border-[3px] border-[#5882c1]/[.49] mt-10 mb-[50px] transition-all duration-500 ease-out ${
                    isVisible3 ? "animate-card" : ""
                  }`}
                >
                  <img
                    className={`object-cover w-[30%] mx-auto float-left my-28 max-[1980px]:-my-2 max-[1024px]:my-24 max-[1440px]:my-24 transition-transform transform ${
                      isVisible3
                        ? "translate-x-0 opacity-100"
                        : "-translate-x-full opacity-0"
                    } duration-500 ease-out`}
                    src={Section3CardImage3}
                    alt="Card Image"
                  />
                  <div className="p-4 ">
                    <h2
                      className={`health_care text-white text-center text-[2.0625rem] font-bold leading-[156%] underline uppercase transition-transform transform ${
                        isVisible3
                          ? "translate-x-0 opacity-100"
                          : "translate-x-full opacity-0"
                      } duration-500 ease-out`}
                    >
                      HEALTH CARE
                    </h2>
                    <p
                      className={`text-justify font-['Poppins'] max-xl:text-[20px] text-2xl leading-[156%] capitalize text-white mb-5 mt-10 mr-10 transition-transform transform ${
                        isVisible3
                          ? "translate-x-0 opacity-100"
                          : "translate-x-full opacity-0"
                      } duration-500 ease-out text-justify whitespace-normal`}
                    >
                      Zynovatex, seasoned in the field, spearheads a healthcare
                      revolution with cutting-edge IT solutions. We're the
                      experts, honing our skills in software development, mobile
                      app crafting, web design wizardry, AI/ML projects, and
                      avant-garde designs. Our mission? To arm healthcare
                      providers with transformative tech, placing a premium on
                      efficiency, accuracy, and top-notch patient care.
                    </p>
                  </div>
                </div>
              </ScrollTrigger>
            </>
          </div>
        </div>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <div
          className="bg-cover font-['Poppins'] center pt-10"
          style={{ backgroundImage: `url(${Section3image})` }}
        >
          <p
            className={`we_have_highly-tailored_it_solution w-[80%] mx-[10%] text-white text-center mb-10  text-2xl font-bold leading-[156%] underline uppercase `}
          >
            WE HAVE HIGHLY-TAILORED IT SOLUTION
          </p>
          <Slider {...settings} className="w-[60%] mx-[20%] pb-20">
            {/* card 1 */}
            <div className="w-[70%] h-full rounded-[1.3125rem] border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative overflow-hidden">
              <div
                className="lightgray flex-shrink-0 w-[13rem] h-[13rem] mx-auto mt-5"
                style={{
                  background: `url(${Section3CardImage1}) no-repeat`,
                  backgroundSize: "129.33% 129.33%",
                  backgroundPosition: "-39.612px -37.628px",
                }}
              />
              <div className="inset-0 flex flex-col justify-center items-center text-center   mb-[5px]">
                <div className="education text-white text-2xl font-bold leading-[156%] underline uppercase mb-4">
                  Education
                </div>
                <div className="  w-[80%] pb-10 text-white text-justify text-base leading-[156%] capitalize">
                  Zynovatex is on the cutting edge, shaking up education with
                  customized IT solutions. We're your go-to experts, diving deep
                  into software development, AI/ML projects, and crafting
                  innovative web designs. Our focus is on arming educational
                  institutions with game-changing tech, smoothing out processes,
                  and sparking innovation for both educators and learners. We're
                  here to optimize, transform, and bring a fresh wave of
                  tech-savvy dynamism to the world of education.
                </div>
              </div>
            </div>

            {/* card 2 */}
            <div className="w-[70%] h-full rounded-[1.3125rem] border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
              <div
                className="lightgray flex-shrink-0 w-[13rem] h-[13rem] mx-auto mt-5"
                style={{
                  background: `url(${Section3CardImage2}) no-repeat`,
                  backgroundSize: "129.33% 129.33%",
                  backgroundPosition: "-39.612px -37.628px",
                }}
              />
              <div className="inset-0 flex flex-col justify-center items-center text-center   mb-[5px]">
                <div className="education text-white text-2xl font-bold leading-[156%] underline uppercase mb-4">
                  BUSINESS
                </div>
                <div className="w-[80%] pb-4 text-white text-justify text-base leading-[156%] capitalize">
                  In the nexus of innovation and industry prowess, we sculpt the
                  future of business with years of IT finesse. Our forte lies in
                  crafting tailor-made software solutions, avant-garde AI and ML
                  projects, and cutting-edge web designs, catapulting businesses
                  into an era of heightened efficiency and digital finesse. We
                  pledge to redefine industry benchmarks, providing bespoke IT
                  solutions that streamline processes, elevate productivity, and
                  fuel sustainable growth. Embark on a superior business journey
                  with us.
                </div>
              </div>
            </div>

            {/* card 3 */}
            <div className="w-[70%] h-full rounded-[1.3125rem] border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
              <div
                className="lightgray flex-shrink-0 w-[13rem] h-[13rem] mx-auto mt-5"
                style={{
                  background: `url(${Section3CardImage3}) no-repeat`,
                  backgroundSize: "129.33% 129.33%",
                  backgroundPosition: "-39.612px -37.628px",
                }}
              />
              <div className="inset-0 flex flex-col justify-center items-center text-center  mb-[5px]">
                <div className="education text-white text-2xl font-bold leading-[156%] underline uppercase mb-4">
                  HEALTH CARE
                </div>
                <div className="w-[80%] pb-[90px] text-white text-justify text-base leading-[156%] capitalize">
                  Zynovatex, seasoned in the field, spearheads a healthcare
                  revolution with cutting-edge IT solutions. We're the experts,
                  honing our skills in software development, mobile app
                  crafting, web design wizardry, AI/ML projects, avant-garde
                  designs. Our mission? To arm healthcare providers with
                  transformative tech, placing a premium on efficiency,
                  accuracy, and top-notch patient care.
                </div>
              </div>
            </div>
          </Slider>
        </div>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <div
          className="bg-cover font-['Poppins'] center pt-10 "
          style={{ backgroundImage: `url(${Section3image})`, height: "100%" }}
        >
          <p
            className={`we_have_highly-tailored_it_solution text-white text-center mb-10  text-xl font-semibold w-[80%] mx-[10%] leading-[156%] underline uppercase `}
          >
            WE HAVE HIGHLY-TAILORED IT SOLUTION
          </p>
          <Slider {...settings} className="w-[80%] mx-[10%] pb-20">
            {/* card 1 */}
            <div className="w-auto h-auto rounded-[1.3125rem] pb-8 border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative overflow-hidden">
              <img
                className="w-[60%] mx-[20%] mt-[-20px]  "
                src={Section3CardImage1}
                alt="Card Image"
              />
              <div className="inset-0 flex flex-col items-center justify-center text-center ">
                <div className="mb-2 mt-[-20px] text-base  font-bold text-white underline uppercase education">
                  Education
                </div>
                <div className="w-[80%] max-sm:tex-sm text-white text-justify text-base max-[400px]:text-sm leading-[156%] max-sm:leading-[130%] capitalize">
                  Zynovatex is on the cutting edge, shaking up education with
                  customized IT solutions. We're your go-to experts, diving deep
                  into software development, AI/ML projects, and crafting
                  innovative web designs. Our focus is on arming educational
                  institutions with game-changing tech, smoothing out processes,
                  and sparking innovation for both educators and learners. We're
                  here to optimize, transform, and bring a fresh wave of
                  tech-savvy dynamism to the world of education.
                </div>
              </div>
            </div>

            {/* card 2 */}
            <div className="w-auto h-auto rounded-[1.3125rem] pb-8 max-sm:pb-4 border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative overflow-hidden">
              <img
                className="w-[60%] mx-[20%] mt-[-20px]  "
                src={Section3CardImage2}
                alt="Card Image"
              />
              <div className="inset-0 flex flex-col items-center justify-center text-center ">
                <div className="mb-2 mt-[-20px] text-base font-bold text-white underline uppercase education">
                  Business
                </div>
                <div className="w-[80%] max-sm:tex-sm text-white text-justify text-base  max-[400px]:text-sm leading-[156%] max-sm:leading-[130%] capitalize">
                  In the nexus of innovation and industry prowess, we sculpt the
                  future of business with years of IT finesse. Our forte lies in
                  crafting tailor-made software solutions, avant-garde AI and ML
                  projects, and cutting-edge web designs, catapulting businesses
                  into an era of heightened efficiency and digital finesse. We
                  pledge to redefine industry benchmarks, providing bespoke IT
                  solutions that streamline processes, elevate productivity, and
                  fuel sustainable growth. Embark on a superior business journey
                  with us.
                </div>
              </div>
            </div>

            {/* card 3 */}
            <div className="w-auto h-auto rounded-[1.3125rem]  border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative overflow-hidden">
              <img
                className="w-[60%] mx-[20%] mt-[-20px]  "
                src={Section3CardImage3}
                alt="Card Image"
              />
              <div className="inset-0 flex flex-col items-center justify-center text-center ">
                <div className="mb-2 mt-[-20px] text-base font-bold text-white underline uppercase education">
                  HEALTH CARE
                </div>
                <div className="w-[80%] max-sm:tex-sm pb-20 max-[600px]:pb-24 text-white text-justify text-base max-[400px]:text-sm leading-[156%] max-sm:leading-[130%] capitalize">
                  Zynovatex, seasoned in the field, spearheads a healthcare
                  revolution with cutting-edge IT solutions. We're the experts,
                  honing our skills in software development, mobile app
                  crafting, web design wizardry, AI/ML projects, and avant-garde
                  designs. Our mission? To arm healthcare providers with
                  transformative tech, placing a premium on efficiency,
                  accuracy, and top-notch patient care.
                </div>
              </div>
            </div>
            {/* Repeat similar structure for other slides */}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default HomeSection4;

import React, { useState, useEffect } from "react";
import Section3Backgroud from "../../../../Images/BlogImages/Section1Background.webp";
import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkX } from "react-icons/bs";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import { FaRegCommentDots } from "react-icons/fa6";
import ArlicleImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/BlogImage2.webp";

const fontFamily = "Poppins";

const BlogSec2 = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [deviceView] = useState("web"); // Default to web view
  

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleLike = () => {
    setLikes(isLiked ? likes - 1 : likes + 1);
    setIsLiked(!isLiked);
  };

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleShare = () => {
    // Implement share functionality (e.g., open a share dialog)
    console.log("Sharing...");
  };

  const handleComment = () => {
    // Implement comment functionality
    console.log("Opening comments...");
  };

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div
      style={{
        backgroundImage: `url(${Section3Backgroud})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        paddingInline: "10%",
        paddingTop: "5%",
        paddingBottom: "10%",
      }}
    >
      <div className="text-justify">
        {/* title */}
        <span className="text-white text-[38px] max-lg:text-3xl max-md:text-2xl font-bold font-['Poppins']">
          Artificial Intelligence
        </span>
        <span className="text-white text-[38px] max-lg:text-2xl max-md:text-xl font-normal font-['Poppins']">
          : Simulation of human intelligence in machine learning
        </span>


        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6 " />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* description */}
        <div className="mt-8">
          <div className=" text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.
          </div>
          <div className=" mt-4 text-white text-xl max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.Artificial Intelligence: Simulation of human
            intelligence in machine learning.Artificial Intelligence: Simulation
            of human intelligence in machine learning.Artificial Intelligence:
            Simulation of human intelligence in machine learning.
          </div>
          <div className=" mt-8 text-white text-2xl  max-lg:text-xl max-md:text-lg font-bold font-['Poppins']">
            Artificial Intelligence
          </div>
          <div className=" mt-4 text-white text-xl  max-lg:text-lg font-normal max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.Artificial Intelligence: Simulation of human
            intelligence in machine learning.Artificial Intelligence: Simulation
            of human intelligence in machine learning.Artificial Intelligence:
            Simulation of human intelligence in machine learning.
          </div>
        </div>
        {/* blog second Images */}
        <div className="flex justify-center mt-10">
          <img src={ArlicleImage} alt="BlgImage" />
        </div>
        {/* Second description */}
        <div className="mt-8">
          <div className=" text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.Artificial Intelligence: Simulation of human
            intelligence in machine learning.Artificial Intelligence: Simulation
            of human intelligence in machine learning.Artificial Intelligence:
            Simulation of human intelligence in machine learning.
          </div>
          <div className=" mt-8 text-white text-2xl font-bold  max-lg:text-xl max-md:text-lg font-['Poppins']">
            Artificial Intelligence
          </div>
          <div className=" mt-4 text-white text-xl font-normal  max-lg:text-lg max-md:text-base font-['Poppins']">
            Artificial Intelligence: Simulation of human intelligence in machine
            learning.Artificial Intelligence: Simulation of human intelligence
            in machine learning.Artificial Intelligence: Simulation of human
            intelligence in machine learning.Artificial Intelligence: Simulation
            of human intelligence in machine learning.Artificial Intelligence:
            Simulation of human intelligence in machine learning.
          </div>
        </div>
        {/* like button */}
        <div className="border-[#fff]/[.50] text-white  mt-8 bg-[#fff]/[.10] px-[3%] py-[1%] rounded-[10px] border-2 backdrop-blur-[50px]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              <button onClick={handleLike} className="mr-4">
                {isLiked ? (
                  <BiDislike className="w-6 h-6" />
                ) : (
                  <BiLike className="w-6 h-6" />
                )}
              </button>
              <span>{likes} likes</span>
              <button onClick={handleComment} className="ml-4">
                {/* Use the imported Comment icon */}
                <FaRegCommentDots className="w-6 h-6" />
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={handleSave} className="mr-4">
                {isSaved ? (
                  <BsBookmarkX className="w-6 h-6" />
                ) : (
                  <BsBookmarkCheck className="w-6 h-6" />
                )}
              </button>
              <button onClick={handleShare}>
                {/* Use the imported Share icon */}
                <PiShareFat className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSec2;

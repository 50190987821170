import React, { useState, useEffect, useRef } from "react";
import FaqSection2Background from "../../Images/PrivacyPolicyImages/Section2Background.webp";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";
import "./ScrollPanelDemo.css";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

const initialState = [false, false];

const FaqSection2 = () => {
  const [deviceView, setDeviceView] = useState("web");
  const [panel1Collapsed, setPanel1Collapsed] = useState(true);
  const [panel2Collapsed, setPanel2Collapsed] = useState(true);
  const [panel3Collapsed, setPanel3Collapsed] = useState(true);
  const [panel4Collapsed, setPanel4Collapsed] = useState(true);
  const [panel5Collapsed, setPanel5Collapsed] = useState(true);
  const [panel6Collapsed, setPanel6Collapsed] = useState(true);
  const [panel7Collapsed, setPanel7Collapsed] = useState(true);
  const [panel8Collapsed, setPanel8Collapsed] = useState(true);
  const [panel9Collapsed, setPanel9Collapsed] = useState(true);
  const [panel10Collapsed, setPanel10Collapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeAllPanels = (exceptPanel) => {
    if (exceptPanel !== 1) setPanel1Collapsed(true);
    if (exceptPanel !== 2) setPanel2Collapsed(true);
    if (exceptPanel !== 3) setPanel3Collapsed(true);
    if (exceptPanel !== 4) setPanel4Collapsed(true);
    if (exceptPanel !== 5) setPanel5Collapsed(true);
    if (exceptPanel !== 6) setPanel6Collapsed(true);
    if (exceptPanel !== 7) setPanel7Collapsed(true);
    if (exceptPanel !== 8) setPanel8Collapsed(true);
    if (exceptPanel !== 9) setPanel9Collapsed(true);
    if (exceptPanel !== 10) setPanel10Collapsed(true);
  };

  const togglePanel1 = () => {
    closeAllPanels(1);
    setPanel1Collapsed(!panel1Collapsed);
  };

  const togglePanel2 = () => {
    closeAllPanels(2);
    setPanel2Collapsed(!panel2Collapsed);
  };

  const togglePanel3 = () => {
    closeAllPanels(3);
    setPanel3Collapsed(!panel3Collapsed);
  };

  const togglePanel4 = () => {
    closeAllPanels(4);
    setPanel4Collapsed(!panel4Collapsed);
  };

  const togglePanel5 = () => {
    closeAllPanels(5);
    setPanel5Collapsed(!panel5Collapsed);
  };

  const togglePanel6 = () => {
    closeAllPanels(6);
    setPanel6Collapsed(!panel6Collapsed);
  };

  const togglePanel7 = () => {
    closeAllPanels(7);
    setPanel7Collapsed(!panel7Collapsed);
  };

  const togglePanel8 = () => {
    closeAllPanels(8);
    setPanel8Collapsed(!panel8Collapsed);
  };

  const togglePanel9 = () => {
    closeAllPanels(9);
    setPanel9Collapsed(!panel9Collapsed);
  };
  
  const togglePanel10 = () => {
    closeAllPanels(10);
    setPanel10Collapsed(!panel10Collapsed);
  };

  const [expanded, setExpanded] = useState(initialState);

  const toggleExpansion = (index) => {
    const newExpandedState = [...expanded];
    newExpandedState[index] = !newExpandedState[index];

    // Close other panels when one is expanded
    for (let i = 0; i < newExpandedState.length; i++) {
      if (i !== index) {
        newExpandedState[i] = false;
      }
    }

    setExpanded(newExpandedState);
  };



  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div
            className="w-full h-full bg-cover bg-center p-[5%]"
            style={{ backgroundImage: `url(${FaqSection2Background})` }}
          >
            <div className="flex justify-center text-white font-['Poppins'] text-[40px] font-bold leading-[normal] uppercase">
              FAQs
            </div>
            <div className=" pt-6 pb-10 flex justify-center text-center text-white font-['Poppins'] text-[1.5625rem] font-normal leading-[normal] capitalize">
              Frequently Asked Questions (FAQs)
            </div>
            <div className="card scrollpanel-demo">
              <div className="flex gap-5 flex-column md:flex-row">
                <div className="flex-auto">
                  <ScrollPanel
                    style={{ width: "90%", height: "700px", marginLeft: "5%" }} // Set the height as needed
                    className="custombar1"
                  >
                    {/* data box 1 */}
                    <div className=" flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What is your expertise in the IT field?"
                        toggleable
                        collapsed={panel1Collapsed}
                        onToggle={togglePanel1}
                        toggleIcon={
                          panel1Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our expertise in IT spans web development, software
                          development, AI and ML projects, and cybersecurity. We
                          excel in creating innovative solutions that seamlessly
                          integrate technology into various domains.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 2 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Can you provide an overview of your app development services?"
                        toggleable
                        collapsed={panel2Collapsed}
                        onToggle={togglePanel2}
                        toggleIcon={
                          panel2Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Certainly! We specialize in developing mobile
                          applications across platforms, ensuring a smooth user
                          experience, scalability, and adherence to the latest
                          industry standards. Our team is proficient in both
                          native and cross-platform app development.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 3 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you approach cybersecurity in your IT solutions?"
                        toggleable
                        collapsed={panel3Collapsed}
                        onToggle={togglePanel3}
                        toggleIcon={
                          panel3Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Security is paramount in our IT solutions. We
                          implement robust security measures, including
                          encryption, authentication, and continuous monitoring,
                          to safeguard data and protect against potential cyber
                          threats.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 4 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What technologies do you use for web development?"
                        toggleable
                        collapsed={panel4Collapsed}
                        onToggle={togglePanel4}
                        toggleIcon={
                          panel4Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our web development leverages a variety of
                          technologies including HTML, CSS, JavaScript, React,
                          Node.js, and other frameworks. We prioritize
                          responsive design, user-friendly interfaces, and
                          secure web practices.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 5 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Can you share insights into your AI and ML projects?"
                        toggleable
                        collapsed={panel5Collapsed}
                        onToggle={togglePanel5}
                        toggleIcon={
                          panel5Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our AI and ML projects involve algorithm
                          implementation, data analysis, and real-world
                          applications. We use technologies like Python,
                          TensorFlow, and Scikit-learn to create intelligent
                          solutions that meet academic and industry standards.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 6 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you ensure the success of your university projects?"
                        toggleable
                        collapsed={panel6Collapsed}
                        onToggle={togglePanel6}
                        toggleIcon={
                          panel6Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Success in university projects is ensured through
                          adherence to academic standards, real-time feedback
                          loops, and collaboration with domain experts. Our
                          scalable and modular designs cater to diverse project
                          requirements.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 7 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Tell us more about your wedding proposal website, CupidCo."
                        toggleable
                        collapsed={panel7Collapsed}
                        onToggle={togglePanel7}
                        toggleIcon={
                          panel7Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          CupidCo is a bespoke platform for creating
                          personalized wedding proposals. It features
                          customizable pages, multimedia integration, and a
                          secure environment. Emotional UI/UX design and
                          community engagement enhance the overall experience.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 8 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What measures do you take to ensure data security in your projects?"
                        toggleable
                        collapsed={panel8Collapsed}
                        onToggle={togglePanel8}
                        toggleIcon={
                          panel8Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Data security is a top priority. We implement secure
                          coding practices, use encryption protocols, conduct
                          regular security audits, and stay updated on the
                          latest cybersecurity trends to safeguard sensitive
                          information.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 9 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you manage project timelines and resources?"
                        toggleable
                        collapsed={panel9Collapsed}
                        onToggle={togglePanel9}
                        toggleIcon={
                          panel9Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our project management involves meticulous planning,
                          risk assessment, and agile methodologies. We allocate
                          resources efficiently, ensuring effective
                          communication, collaboration, and adherence to
                          timelines.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 10 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What are your future plans for technology enhancements?"
                        toggleable
                        collapsed={panel10Collapsed}
                        onToggle={togglePanel10}
                        toggleIcon={
                          panel10Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          We are committed to staying at the forefront of
                          technology. Future plans include integrating advanced
                          AI models, exploring emerging technologies, enhancing
                          user experiences, and continual improvement based on
                          client feedback and industry trends.
                        </p>
                      </Panel>
                    </div>
                  </ScrollPanel>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div
            className="w-full h-full bg-cover bg-center p-[5%]"
            style={{ backgroundImage: `url(${FaqSection2Background})` }}
          >
            <div className="flex justify-center text-white font-['Poppins'] text-[40px] font-bold leading-[normal] uppercase">
              FAQs
            </div>
            <div className=" pt-6 pb-10 flex justify-center text-center text-white font-['Poppins'] text-[1.5625rem] font-normal leading-[normal] capitalize">
              Frequently asked questions with answers from our team.
            </div>
            <div className="card scrollpanel-demo">
              <div className="flex gap-5 flex-column md:flex-row">
                <div className="flex-auto">
                  <ScrollPanel
                    style={{ width: "90%", height: "700px", marginLeft: "5%" }} // Set the height as needed
                    className="custombar1"
                  >
                    {/* data box 1 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What is your expertise in the IT field?"
                        toggleable
                        collapsed={panel1Collapsed}
                        onToggle={togglePanel1}
                        toggleIcon={
                          panel1Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our expertise in IT spans web development, software
                          development, AI and ML projects, and cybersecurity. We
                          excel in creating innovative solutions that seamlessly
                          integrate technology into various domains.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 2 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Can you provide an overview of your app development services?"
                        toggleable
                        collapsed={panel2Collapsed}
                        onToggle={togglePanel2}
                        toggleIcon={
                          panel2Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Certainly! We specialize in developing mobile
                          applications across platforms, ensuring a smooth user
                          experience, scalability, and adherence to the latest
                          industry standards. Our team is proficient in both
                          native and cross-platform app development.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 3 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you approach cybersecurity in your IT solutions?"
                        toggleable
                        collapsed={panel3Collapsed}
                        onToggle={togglePanel3}
                        toggleIcon={
                          panel3Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Security is paramount in our IT solutions. We
                          implement robust security measures, including
                          encryption, authentication, and continuous monitoring,
                          to safeguard data and protect against potential cyber
                          threats.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 4 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What technologies do you use for web development?"
                        toggleable
                        collapsed={panel4Collapsed}
                        onToggle={togglePanel4}
                        toggleIcon={
                          panel4Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our web development leverages a variety of
                          technologies including HTML, CSS, JavaScript, React,
                          Node.js, and other frameworks. We prioritize
                          responsive design, user-friendly interfaces, and
                          secure web practices.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 5 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Can you share insights into your AI and ML projects?"
                        toggleable
                        collapsed={panel5Collapsed}
                        onToggle={togglePanel5}
                        toggleIcon={
                          panel5Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our AI and ML projects involve algorithm
                          implementation, data analysis, and real-world
                          applications. We use technologies like Python,
                          TensorFlow, and Scikit-learn to create intelligent
                          solutions that meet academic and industry standards.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 6 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you ensure the success of your university projects?"
                        toggleable
                        collapsed={panel6Collapsed}
                        onToggle={togglePanel6}
                        toggleIcon={
                          panel6Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Success in university projects is ensured through
                          adherence to academic standards, real-time feedback
                          loops, and collaboration with domain experts. Our
                          scalable and modular designs cater to diverse project
                          requirements.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 7 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="Tell us more about your wedding proposal website, CupidCo."
                        toggleable
                        collapsed={panel7Collapsed}
                        onToggle={togglePanel7}
                        toggleIcon={
                          panel7Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          CupidCo is a bespoke platform for creating
                          personalized wedding proposals. It features
                          customizable pages, multimedia integration, and a
                          secure environment. Emotional UI/UX design and
                          community engagement enhance the overall experience.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 8 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What measures do you take to ensure data security in your projects?"
                        toggleable
                        collapsed={panel8Collapsed}
                        onToggle={togglePanel8}
                        toggleIcon={
                          panel8Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Data security is a top priority. We implement secure
                          coding practices, use encryption protocols, conduct
                          regular security audits, and stay updated on the
                          latest cybersecurity trends to safeguard sensitive
                          information.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 9 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="How do you manage project timelines and resources?"
                        toggleable
                        collapsed={panel9Collapsed}
                        onToggle={togglePanel9}
                        toggleIcon={
                          panel9Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          Our project management involves meticulous planning,
                          risk assessment, and agile methodologies. We allocate
                          resources efficiently, ensuring effective
                          communication, collaboration, and adherence to
                          timelines.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 10 */}
                    <div className="mt-6 flex items-center pt-6 pb-6 px-9 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28]">
                      <Panel
                        header="What are your future plans for technology enhancements?"
                        toggleable
                        collapsed={panel10Collapsed}
                        onToggle={togglePanel10}
                        toggleIcon={
                          panel10Collapsed ? <BsChevronDown /> : <BsChevronUp />
                        }
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "28px",
                        }}
                      >
                        <hr className="my-4 border-t border-gray-300" />
                        <p className="m-2 text-lg ">
                          We are committed to staying at the forefront of
                          technology. Future plans include integrating advanced
                          AI models, exploring emerging technologies, enhancing
                          user experiences, and continual improvement based on
                          client feedback and industry trends.
                        </p>
                      </Panel>
                    </div>
                  </ScrollPanel>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div
            className="w-full h-full bg-cover bg-center p-[5%]"
            style={{ backgroundImage: `url(${FaqSection2Background})` }}
          >
            <div className="flex justify-center text-white font-['Poppins'] text-[25px] font-bold leading-[normal] uppercase">
              FAQs
            </div>
            <div className=" pt-3 pb-6 flex justify-center text-center text-white font-['Poppins'] text-[18px] font-normal leading-[normal] capitalize">
              Frequently asked questions with answers from our team.
            </div>
            <div className="card scrollpanel-demo">
              <div className="flex gap-5 flex-column md:flex-row">
                <div className="flex-auto">
                  <ScrollPanel
                    style={{ width: "90%", height: "700px", marginLeft: "5%" }}
                    className="custombar1"
                  >
                    {/* data box 1 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(0)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[0] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="What is your expertise in the IT field?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[0] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Our expertise in IT spans web development, software
                          development, AI and ML projects, and cybersecurity. We
                          excel in creating innovative solutions that seamlessly
                          integrate technology into various domains.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 2 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(1)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[1] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="Can you provide an overview of your app development services?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[1] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Certainly! We specialize in developing mobile
                          applications across platforms, ensuring a smooth user
                          experience, scalability, and adherence to the latest
                          industry standards. Our team is proficient in both
                          native and cross-platform app development.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 3 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(3)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[3] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="How do you approach cybersecurity in your IT solutions?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[3] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Security is paramount in our IT solutions. We
                          implement robust security measures, including
                          encryption, authentication, and continuous monitoring,
                          to safeguard data and protect against potential cyber
                          threats.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 4 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(4)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[4] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="What technologies do you use for web development?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[4] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Our web development leverages a variety of
                          technologies including HTML, CSS, JavaScript, React,
                          Node.js, and other frameworks. We prioritize
                          responsive design, user-friendly interfaces, and
                          secure web practices.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 5 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(5)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[5] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header=" Can you share insights into your AI and ML projects?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[5] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Our AI and ML projects involve algorithm
                          implementation, data analysis, and real-world
                          applications. We use technologies like Python,
                          TensorFlow, and Scikit-learn to create intelligent
                          solutions that meet academic and industry standards.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 6 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(6)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[6] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="How do you ensure the success of your university projects?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[6] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Success in university projects is ensured through
                          adherence to academic standards, real-time feedback
                          loops, and collaboration with domain experts. Our
                          scalable and modular designs cater to diverse project
                          requirements.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 7 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(7)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[7] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="Tell us more about your wedding proposal website, CupidCo."
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[7] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          CupidCo is a bespoke platform for creating
                          personalized wedding proposals. It features
                          customizable pages, multimedia integration, and a
                          secure environment. Emotional UI/UX design and
                          community engagement enhance the overall experience.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 8 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(8)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[8] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="What measures do you take to ensure data security in your projects?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[8] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Data security is a top priority. We implement secure
                          coding practices, use encryption protocols, conduct
                          regular security audits, and stay updated on the
                          latest cybersecurity trends to safeguard sensitive
                          information.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 9 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(9)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[9] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="How do you manage project timelines and resources?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[9] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          Our project management involves meticulous planning,
                          risk assessment, and agile methodologies. We allocate
                          resources efficiently, ensuring effective
                          communication, collaboration, and adherence to
                          timelines.
                        </p>
                      </Panel>
                    </div>

                    {/* data box 10 */}
                    <div className="mt-6 text-center text-white flex items-center pt-5 pb-10 px-5 w-[100%] rounded-lg border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative">
                      <div
                        onClick={() => toggleExpansion(10)} // Pass an index to identify the section
                        className="absolute top-[98%] text-2xl pb-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      >
                        {/* Your icon here */}
                        {expanded[10] ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                      <Panel
                        className="text-lg"
                        header="What are your future plans for technology enhancements?"
                      >
                        <p
                          className={`text-base m-0 text-center ${
                            expanded[10] ? "block" : "hidden"
                          }`}
                        >
                          {/* Conditionally show this content based on 'expanded' state */}
                          <hr className="my-4 border-t border-gray-300" />
                          We are committed to staying at the forefront of
                          technology. Future plans include integrating advanced
                          AI models, exploring emerging technologies, enhancing
                          user experiences, and continual improvement based on
                          client feedback and industry trends.
                        </p>
                      </Panel>
                    </div>
                  </ScrollPanel>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FaqSection2;

import React from 'react'
import UiUxSec01 from './UiUxSec01'
import UiUxSec02 from './UiUxSec02'

const UiUx = () => {
  return (
    <div>
      <UiUxSec01/>
      <UiUxSec02/>
    </div>
  )
}

export default UiUx

import React from 'react'
import PrivacyPolicySection1 from '../Component/Pages/PrivacyPolicy/PrivacyPolicySection1'
import PrivacyPolicySection2 from '../Component/Pages/PrivacyPolicy/PrivacyPolicySection2'
import SocialMedia from '../Component/Common/SocialMedia';

const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyPolicySection1/>
      <PrivacyPolicySection2/>
      <SocialMedia/>
    </div>
  )
}

export default PrivacyPolicy

import React, { useState, useEffect } from "react";
import BgImage from "../../Images/CareerApplyImages/Section2Background.webp";
import { Typography } from "@material-tailwind/react";
import { InputText } from "primereact/inputtext";

const CareerApplySection2 = () => {
    const [deviceView, setDeviceView] = useState('web');
    const [resumeFile, setResumeFile] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setDeviceView('mobile');
            } else if (width <= 1024) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResumeChange = (e) => {
        const file = e.target.files[0];
        setResumeFile(file);
    };

    const scrollToTop = () => {
        // Scroll smoothly to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        scrollToTop();
    }, []);

    return (
        <div style={{ fontFamily: 'Poppins' }}>
            {deviceView === 'web' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h3" color="white" className="mb-8 tracking-wide text-center uppercase">
                                Application Form
                            </Typography>

                            <div className="card w-[60%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">
                                    <div className="grid gap-2 md:grid-cols-2">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light "
                                            >
                                                First Name *
                                            </Typography>
                                            <InputText
                                                id="fName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light"
                                            >
                                                Last Name *
                                            </Typography>
                                            <InputText
                                                id="lName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs  border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Email Address *
                                        </Typography>
                                        <InputText
                                            id="mail"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Mobile Number *
                                        </Typography>
                                        <InputText
                                            id="number"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Mobile Number"
                                        />
                                    </div>

                                    <Typography
                                        variant="h6"
                                        color="white"
                                        className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                    >
                                        Upload Your Resume *
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                        type="file"
                                        id="resumeInput"
                                        onChange={handleResumeChange}
                                        style={{ height: '150px' }}
                                    />

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Why do you think you would be a good fit for our team?
                                        </Typography>
                                        <InputText
                                            id="reason"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Your Answer Here"
                                        />
                                    </div>
                                    <button className="px-8 py-2 mt-10 mx-auto font-sm w-[200px] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#5882c1]/[.68] block hover:scale-105">
                                        Apply Now
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {deviceView === 'tablet' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h3" color="white" className="mb-8 tracking-wide text-center uppercase">
                                Application Form
                            </Typography>

                            <div className="card w-[80%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">
                                    <div className="grid gap-2 md:grid-cols-2">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light "
                                            >
                                                First Name *
                                            </Typography>
                                            <InputText
                                                id="fName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light"
                                            >
                                                Last Name *
                                            </Typography>
                                            <InputText
                                                id="lName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs  border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Email Address *
                                        </Typography>
                                        <InputText
                                            id="mail"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Mobile Number *
                                        </Typography>
                                        <InputText
                                            id="number"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Mobile Number"
                                        />
                                    </div>

                                    <Typography
                                        variant="h6"
                                        color="white"
                                        className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                    >
                                        Upload Your Resume *
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                        type="file"
                                        id="resumeInput"
                                        onChange={handleResumeChange}
                                        style={{ height: '150px' }}
                                    />

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Why do you think you would be a good fit for our team?
                                        </Typography>
                                        <InputText
                                            id="reason"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Your Answer Here"
                                        />
                                    </div>
                                    <button className="px-8 py-2 mt-10 mx-auto font-sm w-[200px] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#5882c1]/[.68] block hover:scale-105">
                                        Apply Now
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {deviceView === 'mobile' && (
                <>
                    <div className='relative'>
                        <div className='flex flex-col items-center h-[auto] pt-10 pb-20 bg-cover bg-center bg-no-repeat mt-[-5px]' style={{ backgroundImage: `url(${BgImage})` }}>

                            <Typography variant="h3" color="white" className="mb-8 tracking-wide text-center uppercase">
                                Application Form
                            </Typography>

                            <div className="card w-[60%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.59] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                                <form className="mt-8 w-[90%] mb-8">
                                    <div className="grid gap-2 md:grid-cols-2">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light "
                                            >
                                                First Name *
                                            </Typography>
                                            <InputText
                                                id="fName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs max-[]: border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg  border-2 backdrop-blur-[50px] text-white"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                className="mb-2 sm:text-lg max-[425px]:text-[13px] max-sm:font-light"
                                            >
                                                Last Name *
                                            </Typography>
                                            <InputText
                                                id="lName"
                                                type="text"
                                                className="pl-2 py-2 max-sm:text-xs  border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Email Address *
                                        </Typography>
                                        <InputText
                                            id="mail"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Mobile Number *
                                        </Typography>
                                        <InputText
                                            id="number"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Mobile Number"
                                        />
                                    </div>

                                    <Typography
                                        variant="h6"
                                        color="white"
                                        className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                    >
                                        Upload Your Resume *
                                    </Typography>
                                    <InputText className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                        type="file"
                                        id="resumeInput"
                                        onChange={handleResumeChange}
                                        style={{ height: '150px', cursor: 'pointer' }}
                                    />

                                    <div>
                                        <Typography
                                            variant="h6"
                                            color="white"
                                            className="mt-8 mb-2 max-[425px]:text-[13px] sm:text-lg max-sm:font-light"
                                        >
                                            Why do you think you would be a good fit for our team?
                                        </Typography>
                                        <InputText
                                            id="reason"
                                            type="text"
                                            className="pl-2 py-2 max-sm:text-xs border-[#fff]/[.30] w-[100%]  bg-[#404040]/[.10] rounded-lg shadow-inner border-2 backdrop-blur-[50px] text-white"
                                            placeholder="Your Answer Here"
                                        />
                                    </div>
                                    <button className="px-8 py-2 mt-10 mx-auto font-sm w-[200px] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#5882c1]/[.68] block hover:scale-105">
                                        Apply Now
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CareerApplySection2;

import React, { useState, useEffect, useRef } from "react";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";
import section3Background from '../../Images/CareerImages/Section3/Section3Background.webp';
import Section3CardImage from '../../Images/CareerImages/Section3/Section3Image.webp';
import { GoShieldCheck } from "react-icons/go";

const fontFamily = "Poppins";

const AboutSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative ">
      <div
        style={{
          backgroundImage: `url(${section3Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          padding: "10px",
        }}
      >
        <section>
          <ScrollTrigger
            onEnter={() => setConterOn(true)}
            onExit={() => setConterOn(false)}
          >
            <section>
              <div
                style={{
                  marginBottom: counterOn ? "-70px" : "0",
                  marginTop: "50px",
                }}
              >
                {counterOn && (
                  <div>
                    <Text text={"peaks and benefits"} deviceView={deviceView} />
                  </div>
                )}
              </div>
            </section>
          </ScrollTrigger>

          <section className="text-white font-[' Poppins']">
            <div
              className="
                                    text-center 
                                    font-extrabold 
                                    leading-[156%] 
                                    uppercase 
                                    mt-1
                                    lg:text-6xl 
                                    md:text-5xl 
                                    min-[320px]:text-2xl 
                                    min-[320px]:mt-10
                                    md:mt-5
                                    lg:mt-1
                                    pb-10
                                    "
            >
              peaks and benefits
            </div>
          </section>
          <section className="pb-10">
            <div
              className="
                        glass_frame
                        w-[80%] 
                        h-auto 
                        rounded-[0.8125rem] 
                        border
                        mt-7 
                        mb-7 
                        ml-[10%] 
                        grid gap-8 
                        lg:grid-cols-2
                        lg:text-left
                        "
            >
              <div>
                <img
                  src={Section3CardImage}
                  alt="Founder Image"
                  className="
                                w-full 
                                h-100% 
                                lg:w-auto
                                lg:h-[100%]
                                "
                />
              </div>
              <div>
                <div>
                  <p
                    className="    lg:mt-10
                                    w-[80%]
                                    text-white 
                                    text-center 
                                    font-['Poppins'] 
                                    text-sm 
                                    font-medium 
                                    leading-[1.5625rem] 
                                    mx-auto 
                                    mb-5 
                                    md:w-[100%] 
                                    lg:w-[100%] 
                                    md:pl-5
                                    md:pr-5
                                    
                                    lg:pr-10
                                    md:text-[20px]
                                    "
                  >
                    Rewards that Inspire: Unlock a world of perks and benefits
                    tailored to your success. Competitive compensation, flexible
                    work, growth opportunities, and more await you. Join us and
                    thrive in an environment built for your professional
                    satisfaction
                  </p>
                </div>
                <div
                  className="text-white 
                                    lg:mt-10 
                                    
                                    w-[80%]
                                    font-['Poppins'] 
                                    text-sm 
                                    font-medium 
                                    min-[320px]:font-normal
                                    leading-[1.5625rem] 
                                    mx-auto 
                                    mb-5 
                                    md:w-[100%] 
                                    lg:w-[100%] 
                                    md:pl-5
                                    md:pr-5
                                    
                                    lg:pr-10
                                    md:text-[20px]
                                    
                                    "
                  style={{ fontFamily }}
                >
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Flexible Work Arrangements
                  </p>
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Team Building Activities
                  </p>
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Comprehensive Health Coverage
                  </p>
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Generous Paid Time Off
                  </p>
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Retirement Planning
                  </p>
                  <p className="flex gap-4 pb-5 max-sm:pb-0">
                    <GoShieldCheck />
                    Competitive Compensation
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default AboutSection2;

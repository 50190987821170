import React from 'react'
import Section001 from './Section001'
import Section002 from './Section002'
import Section03 from "../common/Section3";

const Project03 = () => {
  return (
    <div>
      <Section001/>
      <Section002/>
      <Section03/>
    </div>
  )
}

export default Project03

// SocialMedia.js

import { useState, useEffect } from "react";
import FbIcon from "../Images/FooterImages/Facebook.png";
import InstaIcon from "../Images/FooterImages/Instagram.png";
import LinkedinIcon from "../Images/FooterImages/LinkedIn.png";
import TiktokIcon from "../Images/FooterImages/tiktok.png";
import GithubIcon from "../Images/FooterImages/Github.png";
import DribbleIcon from "../Images/FooterImages/Dribble.png";
import "./socialmedia.css"; 
const SocialMedia = () => {
  const fontFamilyStyle = { fontFamily: "Poppins, sans-serif" };

  const [isFbHovered, setIsFbHovered] = useState(false);
  const [isInstaHovered, setIsInstaHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);
  const [isGithubHovered, setIsGithubHovered] = useState(false);
  const [isDribbleHovered, setIsDribbleHovered] = useState(false);

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Adjust the threshold as needed
      const hideThreshold = windowHeight / 0;

      const footer = document.getElementById("footer");
      const footerPosition = footer ? footer.getBoundingClientRect().top : 0;

      // Check if the scroll position is past the hide threshold or near the footer
      setIsHidden(
        scrollPosition > hideThreshold || footerPosition <= windowHeight
      );
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.15)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const socialMediaStyles = {
    position: "fixed",
    top: "50%",
    right: isHidden ? "-100%" : "3%", // Slide to the right when hidden
    transform: "translateY(-50%)",
    transition: "right 0.5s ease-in-out, opacity 0.5s ease-in-out",
    opacity: isHidden ? 0 : 1,
  };

  return (
    <div
      style={{ ...socialMediaStyles }}
      className={`social-media-container ${isHidden ? "hidden" : ""}`}
    >
      {/* Social Media Icons */}

      <div className="flex flex-col items-end">
        <a
          href="https://www.facebook.com/profile.php?id=100092469660041&mibextid=zLoPMf"
          target="_blank"
        >
          <img
            src={FbIcon}
            alt="Facebook"
            className="w-10 h-10 mx-2 transition-transform transform cursor-pointer max-sm:hidden"
            style={{ ...(isFbHovered && iconHoverStyle), ...fontFamilyStyle }}
            onMouseEnter={() => setIsFbHovered(true)}
            onMouseLeave={() => setIsFbHovered(false)}
          />
        </a>
        <a
          href="https://www.instagram.com/zynovatex?igsh=MWowaW9veTM0YzdreQ=="
          target="_blank"
        >
          <img
            src={InstaIcon}
            alt="Instagram"
            className="w-10 h-10 mx-2 mt-3 transition-transform transform cursor-pointer max-sm:hidden"
            style={{
              ...(isInstaHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsInstaHovered(true)}
            onMouseLeave={() => setIsInstaHovered(false)}
          />
        </a>
        <a href="https://www.linkedin.com/company/zynovatex/" target="_blank">
          <img
            src={LinkedinIcon}
            alt="LinkedIn"
            className="w-10 h-10 mx-2 mt-3 transition-transform transform cursor-pointer max-sm:hidden"
            style={{
              ...(isLinkedinHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsLinkedinHovered(true)}
            onMouseLeave={() => setIsLinkedinHovered(false)}
          />
        </a>
        <a
          href="https://www.tiktok.com/@zynovatex.zx?_t=8ikSwOHaf46&_r=1"
          target="_blank"
        >
          <img
            src={TiktokIcon}
            alt="TikTok"
            className="w-10 h-10 mx-2 mt-3 transition-transform transform cursor-pointer max-sm:hidden"
            style={{
              ...(isTikTokHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsTikTokHovered(true)}
            onMouseLeave={() => setIsTikTokHovered(false)}
          />
        </a>
        <img
          src={GithubIcon}
          alt="GitHub"
          className="w-10 h-10 mx-2 mt-3 transition-transform transform cursor-pointer max-sm:hidden"
          style={{ ...(isGithubHovered && iconHoverStyle), ...fontFamilyStyle }}
          onMouseEnter={() => setIsGithubHovered(true)}
          onMouseLeave={() => setIsGithubHovered(false)}
        />

        <img
          src={DribbleIcon}
          alt="Dribbble"
          className="w-10 h-10 mx-2 mt-3 transition-transform transform cursor-pointer max-sm:hidden"
          style={{
            ...(isDribbleHovered && iconHoverStyle),
            ...fontFamilyStyle,
          }}
          onMouseEnter={() => setIsDribbleHovered(true)}
          onMouseLeave={() => setIsDribbleHovered(false)}
        />
      </div>
    </div>
  );
};

export default SocialMedia;

import React, { useState, useEffect } from "react";
import BgImage from "../../Images/CareerImages/Section1/Section1Background.webp";

const fontFamily = 'Poppins';

const BlogSubmissionSection1 = () => {
    const [deviceView, setDeviceView] = useState('web'); // Default to web view

    const [isMobileView, setIsMobileView] = useState(false);
    const [isTabletView, setIsTabletView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            setIsMobileView(screenWidth <= 768); // Adjust the value based on your mobile breakpoint
            setIsTabletView(screenWidth >= 768 && screenWidth <= 1024); // Adjust the values based on your tablet breakpoint
        };

        // Set initial state on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (

                <>
                    <div className='relative'>
                        <div className='flex justify-center h-screen bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${BgImage})` }}>
                            <div className="text-center">
                                <h1 className='text-5xl font-bold tracking-wider text-white uppercase max-lg:text-5xl max-sm:text-3xl mt-60' style={{ fontFamily: fontFamily }}>Employee Blog Contributions</h1>
                                <div className="w-[80%] text-center text-white text-lg max-sm:text-base font-normal capitalize leading-[32px] mx-auto mt-8  max-[500px]:text-sm" style={{ fontFamily: fontFamily }}>
                                    As a Senior Software Engineer at ZYNOVATEX , you will play a pivotal role in driving our technical initiatives and contributing to the growth of our innovative projects. We are seeking highly skilled and experienced individuals who can bring their expertise to the table and lead by example.
                                </div>

                            </div>
                        </div>
                    </div>
                </>
       
    );
};

export default BlogSubmissionSection1;

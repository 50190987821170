import React from 'react'
import Section1 from "./QASection1";
import Section2 from "./QASection2";
import Section3 from "./QASection3";

const QAVacancies = () => {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}

export default QAVacancies

import React from 'react'
import ServiceSection2 from '../Component/Pages/ServicePage/ServiceSection2';
import SocialMedia from '../Component/Common/SocialMedia';
import ServiceSection1 from '../Component/Pages/ServicePage/ServiceSection1';
const Service = () => {
  return (
    <div>
        <ServiceSection1/>
        <ServiceSection2/>
        <SocialMedia/>
    </div>
  )
}

export default Service;
import React from 'react'
import CareerApplySection1 from '../Component/Pages/Career_Apply_Page/careerApplySection1'
import CareerApplySection2 from '../Component/Pages/Career_Apply_Page/careerApplySection2'

const CareerApply = () => {
    return (
        <div>
            <CareerApplySection1 />
            <CareerApplySection2/>
        </div>
    )
}

export default CareerApply

import React, { useState, useEffect } from "react";
import BackgroundImage from "../../Images/AboutImages/Section1/Section1Background.webp";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const fontFamily = "Poppins";

const ProjectSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setDeviceView("mobile");
      } else if (width <= 769) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative">
      <div
        className="flex justify-center h-screen bg-center bg-no-repeat bg-cover max-sm:h-screen"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <ScrollTrigger
          onEnter={() => setConterOn(true)}
          onExit={() => setConterOn(false)}
        >
          <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
            {counterOn && (
              <div>
                <Text text={"projects"} deviceView={deviceView} />
              </div>
            )}
          </div>
          <section className="text-white font-[' Poppins'] text-center">
            <h1
              className='
                        font-semibold 
                        text-white
                        pb-0
                        mt-7
                        text-2xl
                        lg:text-5xl
                        lg:mt-0
                        lg:pb-0
                        md:text-4xl
                        md:mt-4
                        md:pb-0
                        md:tracking-widest 
                        inline-block 
                        border-b 
                        border-gray-300 
                        tracking-wider 
                        uppercase 
                        font-["Poppins"]'
            >
              our projects
            </h1>
          </section>
          <section className="grid gap-8 mt-8">
            <div
              className="
                                text-white 
                                text-center 
                                font-['Poppins']   
                                font-normal 
                                capitalize 
                                mx-auto 
                                mb-20
                                min-[320px]:text-sm
                                lg:text-lg 
                                md:text-lg
                                w-[60%]
                                max-lg:w-[80%]
                        "
            >
              Explore a collection of our successful and diverse projects that
              showcase our expertise and creativity. From stunning website
              designs to cutting-edge mobile apps, each project reflects our
              commitment to excellence and innovation. Get inspired by our work
              and envision the possibilities for your next venture.
              <br /><br /> We take
              pride in delivering top-notch solutions that exceed expectations
              and create lasting impressions. Discover how our expertise can
              elevate your business to new heights.
            </div>
          </section>
        </ScrollTrigger>
      </div>
    </div>
  );
};

export default ProjectSection1;

import React, { useState, useEffect } from "react";
import BgImage from "../../../Images/CareerImages/CareerView/Section 2/section2background.webp";

const FullStackSection3 = () => {
  const [deviceView, setDeviceView] = useState("web");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1025) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ fontFamily: "Poppins" }}>
      {deviceView === "web" && (
        <>
          <div className="relative">
            <div
              className="flex flex-col items-center h-[screen] pt-10 pb-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <div className="card w-[70%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] pl-20 mt-[3%]">
                <div className="my-[5%] text-center text-white">
                  <h2 className="mb-4 text-3xl font-bold text-left">
                    Responsibilities :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-justify list-disc text-[20px]">
                    <li>
                      Work on both front-end and back-end development tasks.
                    </li>
                    <li>
                      Collaborate with cross-functional teams to design, develop, and deploy end-to-end solutions.
                    </li>
                    <li>
                      Ensure seamless integration between front-end and back-end systems.
                    </li>
                    <li>
                      Optimize application performance and troubleshoot issues across the entire stack.
                    </li>
                    <li>
                      Stay informed about advancements in both front-end and back-end technologies.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card w-[70%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] pl-20 mt-[3%]">
                <div className="my-[5%] text-center text-white">
                  <h2 className="mb-4 text-3xl font-bold text-left">
                    Requirements :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-justify list-disc text-[20px]">
                    <li>
                      Bachelor's or Master's degree in Computer Science or a related field
                    </li>
                    <li>
                      2+ years of professional experience in software development
                    </li>
                    <li>
                      Proficiency in multiple programming languages, such as Java, C++, or Python
                    </li>
                    <li>
                      Strong knowledge of software development methodologies and best practices
                    </li>
                    <li>
                      Experience with software architecture and design principles
                    </li>
                    <li>
                      Demonstrated ability to lead and mentor a team
                    </li>
                    <li>
                      Excellent problem-solving and analytical skills
                    </li>
                    <li>
                      Effective communication and collabaration abilities
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-[5%] my-[3%] w-[70%] text-[20px] text-center text-white">
                Join our team of talented software engineers and contribute to
                our cutting-edge projects. As a Senior Software Engineer, you
                will have the opportunity to make a significant impact, drive
                technical excellence, and shape the future of our company.
              </div>
              <div className="mx-[5%] w-[70%] text-[20px] text-center text-white">
                Apply now and take your career to new heights with ZYNOVATEX.
              </div>
              <div className="mx-[5%] w-[70%] text-[20px] text-center text-white">
                <button className="px-8 py-2 mt-10 mx-auto font-sm w-[100%] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#1F2D6E]/[.99] block hover:scale-105">
                  {" "}
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        <>
          <div className="relative">
            <div
              className="flex flex-col items-center h-[screen] pt-10 pb-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <div className="card w-[70%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                <div className="mx-[5%] my-[5%] text-center text-white">
                  <h2 className="mb-4 text-3xl font-bold text-left">
                    Responsibilities :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-justify list-disc text-[20px]">
                    <li>
                      Work on both front-end and back-end development tasks.
                    </li>
                    <li>
                      Collaborate with cross-functional teams to design, develop, and deploy end-to-end solutions.
                    </li>
                    <li>
                      Ensure seamless integration between front-end and back-end systems.
                    </li>
                    <li>
                      Optimize application performance and troubleshoot issues across the entire stack.
                    </li>
                    <li>
                      Stay informed about advancements in both front-end and back-end technologies.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card w-[70%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-[3%]">
                <div className="mx-[5%] my-[5%] text-center text-white">
                  <h2 className="mb-4 text-3xl font-bold text-left">
                    Requirements :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-justify list-disc text-[20px]">
                    <li>
                      Bachelor's or Master's degree in Computer Science or a related field
                    </li>
                    <li>
                      2+ years of professional experience in software development
                    </li>
                    <li>
                      Proficiency in multiple programming languages, such as Java, C++, or Python
                    </li>
                    <li>
                      Strong knowledge of software development methodologies and best practices
                    </li>
                    <li>
                      Experience with software architecture and design principles
                    </li>
                    <li>
                      Demonstrated ability to lead and mentor a team
                    </li>
                    <li>
                      Excellent problem-solving and analytical skills
                    </li>
                    <li>
                      Effective communication and collabaration abilities
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-[5%] my-[3%] w-[70%] text-[20px] text-center text-white">
                Join our team of talented software engineers and contribute to
                our cutting-edge projects. As a Senior Software Engineer, you
                will have the opportunity to make a significant impact, drive
                technical excellence, and shape the future of our company.
              </div>
              <div className="mx-[5%] w-[70%] text-[20px] text-center text-white">
                Apply now and take your career to new heights with ZYNOVATEX.
              </div>
              <div className="mx-[5%] w-[70%] text-[20px] text-center text-white">
                <button className="px-8 py-2 mt-10 mx-auto font-sm w-[100%] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#1F2D6E]/[.99] block hover:scale-105">
                  {" "}
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        <>
          <div className="relative">
            <div
              className="flex flex-col items-center h-screen pt-10 pb-20 bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${BgImage})`, height: "auto" }}
            >
              <div className="card w-[80%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-4">
                <div className="mx-[5%] my-[5%] text-center text-white">
                  <h2 className="mb-4 text-xl font-bold text-left">
                    Responsibilities :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-left list-disc text-[15px]">
                    <li>
                      Work on both front-end and back-end development tasks.
                    </li>
                    <li>
                      Collaborate with cross-functional teams to design, develop, and deploy end-to-end solutions.
                    </li>
                    <li>
                      Ensure seamless integration between front-end and back-end systems.
                    </li>
                    <li>
                      Optimize application performance and troubleshoot issues across the entire stack.
                    </li>
                    <li>
                      Stay informed about advancements in both front-end and back-end technologies.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card w-[80%] h-[auto] rounded-[15px] border-2 border-[#fff]/[.15] bg-[#fff]/[.10] backdrop-blur-[20px] flex justify-center mt-[3%]">
                <div className="mx-[5%] my-[5%] text-center text-white">
                  <h2 className="mb-4 text-xl font-bold text-left">
                    Requirements :
                  </h2>
                  <ul className="ml-[3%] space-y-5 text-left list-disc text-[15px]">
                    <li>
                      Bachelor's or Master's degree in Computer Science or a related field
                    </li>
                    <li>
                      2+ years of professional experience in software development
                    </li>
                    <li>
                      Proficiency in multiple programming languages, such as Java, C++, or Python
                    </li>
                    <li>
                      Strong knowledge of software development methodologies and best practices
                    </li>
                    <li>
                      Experience with software architecture and design principles
                    </li>
                    <li>
                      Demonstrated ability to lead and mentor a team
                    </li>
                    <li>
                      Excellent problem-solving and analytical skills
                    </li>
                    <li>
                      Effective communication and collabaration abilities
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-[5%] my-[3%] w-[70%] text-[16px] text-center text-white">
                Join our team of talented software engineers and contribute to
                our cutting-edge projects. As a Senior Software Engineer, you
                will have the opportunity to make a significant impact, drive
                technical excellence, and shape the future of our company.
              </div>
              <div className="mx-[5%] w-[70%] text-[16px] text-center text-white">
                Apply now and take your career to new heights with ZYNOVATEX.
              </div>
              <div className="mx-[5%] w-[70%] text-[20px] text-center text-white">
                <button className="px-8 py-2 mt-10 mx-auto font-sm w-[100%] text-white text-md rounded-[5px] border-1 border-[#5882c1]/[.49] bg-[#1F2D6E]/[.99] block hover:scale-105">
                  {" "}
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FullStackSection3;

import React from 'react'
import NewsSection1 from '../Component/Pages/NewsPage/NewsSection1'
import NewsSection2 from '../Component/Pages/NewsPage/NewsSection2'

const News = () => {
  return (
    <div>
      <NewsSection1/>
      <NewsSection2/>
    </div>
  )
}

export default News

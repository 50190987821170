import React from 'react'
import HomeSection1 from '../Component/Pages/HomePage/HomeSection1';
import HomeSection2 from '../Component/Pages/HomePage/HomeSection2';
import HomeSection3 from '../Component/Pages/HomePage/HomeSection3';
import HomeSection4 from '../Component/Pages/HomePage/HomeSection4';
import HomeSection5 from '../Component/Pages/HomePage/HomeSection5';
import SocialMedia from '../Component/Common/SocialMedia';

const Home = () => {
  return (
    <div>
      <HomeSection1/>
      <HomeSection2/>
      <HomeSection3/>
      <HomeSection4/>
      <HomeSection5/>
      <SocialMedia/>
    </div>
  )
}

export default Home;

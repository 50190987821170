import React, { useState, useEffect } from "react";
import section3Background from "../../../Images/CareerImages/CareerView/Section 2/Section1background.webp";
import Section3CardImage from "../../../Images/CareerImages/CareerView/Section 2/section2image.webp";

const DevopsSectoin2 = () => {
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative font-[Poppins]">
      <div
        style={{
          backgroundImage: `url(${section3Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          padding: "10px",
        }}
      >
        <section>
          <section className="text-white">
            <div
              className="
                                    text-center 
                                    font-bold 
                                    leading-[156%] 
                                    uppercase 
                                    mt-1
                                    lg:text-4xl 
                                    md:text-4xl 
                                    min-[320px]:text-2xl 
                                    min-[320px]:mt-10
                                    md:mt-8
                                    lg:mt-8
                                    pb-10
                                    "
            >
              About This Position
            </div>
          </section>
          <section className="pb-10">
            <div
              className="
                        glass_frame
                        w-[80%] 
                        h-auto 
                        rounded-[0.8125rem]
                        border-2 
                        border-[#fff]/[.15]
                        mt-7 
                        mb-7 
                        ml-[10%] 
                        grid gap-8 
                        lg:grid-cols-2
                        lg:text-left
                        "
            >
              <div>
                <img
                  src={Section3CardImage}
                  alt="Founder"
                  className="
                                w-full 
                                rounded-lg
                                h-100% 
                                lg:w-auto
                                lg:h-[100%]
                                max-sm:rounded-lg
                                max-md:rounded-lg
                                "
                />
              </div>
              <div>
                <div>
                  <p
                    className="    lg:mt-10
                                    w-[80%]
                                    text-white 
                                    text-center 
                                    text-xl 
                                    font-medium 
                                    leading-[1.5625rem] 
                                    mx-auto 
                                    mb-5 
                                    md:w-[100%] 
                                    lg:w-[100%] 
                                    md:pl-5
                                    md:pr-5
                                    
                                    lg:pr-10
                                    max-md:text-[17px]
                                    "
                  >
                    DevOps Engineer
                  </p>
                </div>
                <div>
                  <p
                    className="    lg:mt-10
                                    w-[80%]
                                    text-white 
                                    text-justify 
                                    text-lg 
                                    font-medium 
                                    leading-[1.5625rem] 
                                    mx-auto 
                                    mb-5 
                                    md:w-[90%] 
                                    lg:w-[100%] 
                                    md:pl-5
                                    md:pr-5
                                    lg:pr-10
                                    max-md:text-[15px]
                                    max-sm:text-base
                                    max-sm:font-normal
                                    "
                  >
                    Automate deployment, monitoring, and scaling of applications
                    while ensuring smooth collaboration between development and
                    operations teams. Focus on infrastructure optimization and
                    system reliability.
                  </p>
                </div>
              </div>
            </div>
            {/* <div
              className="
                        glass_frame
                        w-[80%] 
                        h-auto 
                        rounded-md
                        border-2 
                        border-[#fff]/[.15]
                        mt-7 
                        mb-7 
                        ml-[10%] 
                        py-5
                    
                        "
            >
              <div className="text-center max-md:text-lg max-sm:text-base w-[80%] mx-[10%]">
                <span className="text-white font-semibold font-['Poppins'] leading-[34px]">
                  Date Published:{" "}
                </span>
                <span className="text-white  font-medium font-['Poppins'] leading-[34px]">
                  July 13, 2023
                </span>
                <br />
                <span className="text-white  font-semibold font-['Poppins'] leading-[34px]">
                  Application Deadline:
                </span>
                <span className="text-white  font-medium font-['Poppins'] leading-[34px]">
                  {" "}
                  September 15, 2023
                </span>
              </div>
            </div> */}
          </section>
        </section>
      </div>
    </div>
  );
};

export default DevopsSectoin2;

import React from "react";
import Section2Background from "../../../Images/ProjectImages/ProjectSection2.webp";
import ProjectTestimonial from "../ProjectTestimonial";

const Section02 = () => {
  return (
    <div
      className="py-[5%] max-sm:py-[20%] bg-cover"
      style={{ backgroundImage: `url(${Section2Background})` }}
    >
      {/* project detaila */}
      <div className="w-[70%] max-sm:w-[90%] max-sm:mx-[5%] mx-[15%] max-md:pt-10 h-full   border-[#fff]/[.30] p-[3%]  bg-[#fff]/[.10] rounded-[15px]  border-2 backdrop-blur-[50px]">
        <div className="text-xl text-white max-md:text-lg max-sm:text-base">
          Welcome to our POS System Project,
          <br />
          <br />
          Are you seeking a comprehensive and efficient Point of Sale
          <span className="text-blue-500"> (POS)</span> system that seamlessly
          integrates essential features for your business management? Look no
          further! Our POS system offers a user-friendly experience with a
          myriad of functionalities to streamline your operations.
          <br />
          <br />
          <span className="font-extrabold">Technologies Used :</span>
          <br />
          <br /> JAVA(System Functions), Python(Email Sending, QR/Barcode
          Reading),MySQL(Database), Firebase(Backups).
          <br />
          <br />
          <span className="font-extrabold">Innovative Features:</span>
          <br />
          <br />
          Discover a cutting-edge POS system that revolutionizes business
          management. Boasting user-friendly login interfaces, intuitive
          dashboards, and robust features like invoice generation, inventory
          management, and detailed analytics, our system prioritizes
          accessibility and efficiency. Seamlessly handle sales, debts, and
          reports while effortlessly managing suppliers and customers. Tailor
          the system to your needs with customizable settings. With additional
          functionalities like expense recording and return processing, our POS
          system is more than a tool—it's a comprehensive solution ushering in a
          new era of streamlined business processes. Embrace efficiency and
          convenience with our innovative POS system, where simplicity meets
          powerful functionality.
          <br />
          <br />
          <span className="font-extrabold">Challenges Faced</span>
          <br />
          <br />
          Developing our POS system posed challenges in interoperability,
          hardware compatibility, and security; addressing diverse client needs
          while maintaining user-friendly interfaces proved demanding.
          <br />
          <br />
          <span className="font-extrabold">Solution Highlights</span>
          <br />
          <br />
          Our POS system triumphed with a seamless integration approach, robust
          security measures, and an adaptable UI/UX design, ensuring
          compatibility and enhancing user experiences.
          <br />
          <br />
          <span className="font-extrabold">Team's Roles</span>
          <br />
          <br />
          The development team coded the system, UI/UX designers crafted an
          intuitive interface, QA rigorously tested, and project managers
          facilitated effective communication, culminating in a successful
          project.
          <br />
          <br />
          <span className="font-extrabold">Results and Impacts</span>
          <br />
          <br />
          Implementation led to heightened operational efficiency, streamlined
          sales processes, and improved customer experiences, empowering
          businesses with real-time insights and bolstering data security.
          <br />
          <br />
          <span className="font-extrabold">Lessons Learned</span>
          <br />
          <br />
          Effective communication, flexibility in adapting to client needs, and
          thorough testing at every stage emerged as crucial lessons,
          emphasizing the importance of early issue identification and
          resolution.
          <br />
          <br />
          <span className="font-extrabold">Future Enhancements</span>
          <br />
          <br />
          Future plans involve incorporating advanced analytics for sales
          trends, exploring integration with emerging payment technologies, and
          ensuring scalability to meet evolving business needs.
        </div>
      </div>
      <ProjectTestimonial/>
    </div>
  );
};

export default Section02;

import React, { useState, useEffect } from "react";
import BgImage from "../../Images/BlogImages/BlogSubmission/Section1Background.webp";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";
import { Link } from "react-router-dom";

const fontFamily = "Poppins";

const BlogSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="relative">
            <div
              className="flex justify-center h-screen bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"Blogs"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-5xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Blog
                  </h1>
                  <div
                    className="w-[850px] h-[auto] text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website
                    <a
                      href="mailto:info.zx@zynovatex.com"
                      className=" hover:underline hover:text-blue-500"
                    >
                      {" "}
                      info.zx@zynovatex.com{" "}
                    </a>
                    and its related services.
                  </div>
                  {/* <Link to="/blogsubmission">
                    <div className="flex justify-center mt-10 ">
                      <div className=" w-[30%] h-full pb-1 rounded-lg shadow-inner border-2 border-blue-900 backdrop-blur-[50px]">
                        <div className="text-center items-center text-white text-[20px] mt-1 font-medium font-['Poppins'] cursor-pointer">Share Your Blogs</div>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="relative">
            <div
              className="flex justify-center h-screen bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-44px", marginTop: "120px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"Blogs"} deviceView={"tablet"} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-4xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase "
                    style={{ fontFamily: fontFamily }}
                  >
                    Blog
                  </h1>
                  <div
                    className="w-[80%] h-[auto] text-center text-white text-lg font-normal capitalize leading-[32px] mx-auto mt-0"
                    style={{ fontFamily: fontFamily }}
                  >
                    <br />
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website
                    <a
                      href="mailto:info.zx@zynovatex.com"
                      className=" hover:underline hover:text-blue-500"
                    >
                      {" "}
                      info.zx@zynovatex.com{" "}
                    </a>
                    and its related services.
                  </div>
                  {/* <Link to="/blogsubmission">
                    <div className="flex justify-center mt-10 ">
                      <div className=" w-[30%] h-full pb-1 rounded-lg shadow-inner border-2 border-blue-900 backdrop-blur-[50px]">
                        <div className="text-center items-center text-white text-[20px] mt-1 font-medium font-['Poppins'] cursor-pointer">Share Your Blogs</div>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="relative">
            <div
              className="flex justify-center h-screen pb-10 bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <ScrollTrigger
                onEnter={() => setConterOn(true)}
                onExit={() => setConterOn(false)}
              >
                <div style={{ marginBottom: "-64px", marginTop: "190px " }}>
                  {counterOn && (
                    <div>
                      <Text text={"Blogs"} deviceView={"mobile"} />
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <h1
                    className="font-semibold text-white text-[31px] mt-[30px] inline-block border-b border-gray-300  tracking-wider uppercase"
                    style={{ fontFamily: fontFamily }}
                  >
                    Blog
                  </h1>

                  <div
                    className="w-[90%] text-center text-white text-sm font-normal capitalize leading-[32px] mx-auto mt-8"
                    style={{ fontFamily: fontFamily }}
                  >
                    Welcome to ZYNOVATEX. This Privacy Policy explains how we
                    collect, use, share, and protect your personal information
                    when you use our website
                    <a
                      href="mailto:info.zx@zynovatex.com"
                      className=" hover:underline hover:text-blue-500"
                    >
                      {" "}
                      info.zx@zynovatex.com{" "}
                    </a>
                    and its related services.
                  </div>
                  {/* <Link to="/blogsubmission">
                    <div className="flex justify-center mt-10 ">
                      <div className=" w-[50%] h-full pb-1 rounded-lg shadow-inner border-2 border-blue-900 backdrop-blur-[50px]">
                        <div className="text-center items-center text-white text-[15px] mt-1 font-medium font-['Poppins'] cursor-pointer">Share Your Blogs</div>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BlogSection2;

import React from 'react'
import FaqSection1 from '../Component/Pages/FaqPage/FaqSection1';
import FaqSection2 from '../Component/Pages/FaqPage/FaqSection2';
import SocialMedia from '../Component/Common/SocialMedia';

const Faq = () => {
  return (
    <div>
      <FaqSection1/>
      <FaqSection2/>
      <SocialMedia/>
    </div>
  )
}

export default Faq

import React, {useEffect} from 'react';
import Section3Backgroud from '../../../../Images/BlogImages/Section1Background.webp';
import '../../../../Assests/homeSection2Style.css'
import BlgImage from "../../../../Images/BlogImages/ReadMoreImages/Section1/BlogImage1.webp";

const BlogSec1 = () => {
  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
  }, []); // The empty dependency
  
  return (
    <div className="relative">
      <div
        className="w-[100%] flex justify-center h-full bg-cover bg-center bg-no-repeat "
        style={{ backgroundImage: `url(${Section3Backgroud})` }}
      >
        <div className="relative justify-center py-[10%] px-[20px]">
          <img src={BlgImage} alt="BlgImage" />
        </div>
      </div>
    </div>
  );
};

export default BlogSec1;

import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Section2Background from "../../Images/ProjectImages/Section2/Section2Background.webp";
import ProjectImage1 from "../../Images/ProjectImages/Section2/ProjectImage1.webp";
import ProjectImage2 from "../../Images/ProjectImages/Section2/ProjectImage2.webp";
import ProjectImage3 from "../../Images/ProjectImages/Section2/ProjectImage3.webp";
import ProjectImage4 from "../../Images/ProjectImages/Section2/ProjectImage4.webp";
import Project1 from "../../Images/ProjectImages/Section2/Project1.png";
import Project2 from "../../Images/ProjectImages/Section2/Project2.png";
import Project3 from "../../Images/ProjectImages/Section2/Project3.png";
import Project4 from "../../Images/ProjectImages/Section2/Project4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const ProjectExample = () => {
  const [ref1, isVisible1] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref2, isVisible2] = useInView({
    triggerOnce: false, // Only trigger once
  });

  const [ref3, isVisible3] = useInView({
    triggerOnce: false, // Only trigger once
  });

  const [ref4, isVisible4] = useInView({
    triggerOnce: false, // Only trigger once
  });

  const [ref5, isVisible5] = useInView({
    triggerOnce: false, // Only trigger once
  });

  const [ref6, isVisible6] = useInView({
    triggerOnce: false, // Only trigger once
  });

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div>
            <div
              className="relative w-[100%]  h-full bg-center bg-cover bg-gradient-to-b from-black to-black p-[8%]"
              style={{ backgroundImage: `url(${Section2Background})` }}
            >
              {/* project1 */}
              <div className="grid md:grid-cols-2">
                {/* project Image */}
                <div
                  className={`transition-transform transform ${
                    isVisible1
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref1}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage3}
                      alt="projects"
                    />
                  </div>
                </div>
                <div
                  className={`transition-transform transform ${
                    isVisible1
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref1}
                >
                  {/* project description */}
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[50%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project1} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      CUPIDCO
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      CupidCo is our bespoke wedding proposal website, providing
                      a personalized platform for couples to create and share
                      their unique love stories. It's designed to make proposals
                      unforgettable by combining technology with emotion.
                    </div>
                    <Link to="/project01">
                      <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                        <p className="p-1 text-center text-white lg:text-lg">
                          See More
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* project2 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}
                <div
                  className={`transition-transform transform ${
                    isVisible2
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref2}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage1}
                      alt="projects"
                    />
                  </div>
                </div>
                <div
                  className={`transition-transform transform ${
                    isVisible2
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref2}
                >
                  {/* project description */}
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[50%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project2} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      POS System
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      Are you seeking a comprehensive and efficient Point of
                      Sale (POS) system that seamlessly integrates essential
                      features for your business management? Look no further!
                      Our POS system offers a user-friendly experience with a
                      myriad of functionalities to streamline your operations.
                    </div>
                    <Link to="/project02">
                      <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                        <p className="p-1 text-center text-white lg:text-lg">
                          See More
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* project3 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}
                <div
                  className={`transition-transform transform ${
                    isVisible3
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref3}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage4}
                      alt="projects"
                    />
                  </div>
                </div>

                {/* project description */}
                <div
                  className={`transition-transform transform ${
                    isVisible3
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref3}
                >
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[50%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project3} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      UNIVERSITY PROJECTS
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      Our expertise in university projects encompasses a wide
                      array of AI and ML applications, offering innovative
                      solutions tailored to academic requirements. From data
                      analysis to algorithm implementation, we bring academic
                      projects to life.
                    </div>
                    <Link to="/project03">
                      <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                        <p className="p-1 text-center text-white lg:text-lg">
                          See More
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* project4 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}
                <div
                  className={`transition-transform transform ${
                    isVisible4
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref4}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage2}
                      alt="projects"
                    />
                  </div>
                </div>

                {/* project description */}
                <div
                  className={`transition-transform transform ${
                    isVisible4
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref4}
                >
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project4} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      WEBSITES
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      Our website development service focuses on creating
                      dynamic, responsive, and user-friendly websites tailored
                      to your specific needs. From sleek corporate sites to
                      engaging e-commerce platforms, we ensure an online
                      presence that captivates your audience.
                    </div>
                    <Link to="/project04">
                      <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                        <p className="p-1 text-center text-white lg:text-lg">
                          See More
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* project5*/}
              {/* <div className="grid mt-36 md:grid-cols-2">
                
                <div
                  className={`transition-transform transform ${
                    isVisible5
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref5}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage1}
                      alt="projects"
                    />
                  </div>
                </div>

                
                <div
                  className={`transition-transform transform ${
                    isVisible5
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref5}
                >
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project5} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      ZYNOVATEX PORTFOLIO
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      Introducing a revolutionary POS system with user-friendly
                      interfaces, intuitive dashboards, and robust features.
                      Manage sales, debts, and reports effortlessly while
                      handling inventory and customers. Customize settings to
                      tailor the system to your needs. Experience the future of
                      business management.
                    </div>
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* project6*/}
              {/* <div className="grid mt-36 md:grid-cols-2">
                
                <div
                  className={`transition-transform transform ${
                    isVisible6
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref6}
                >
                  <div>
                    <img
                      className={`w-[100%] h-full rounded-[19px]`}
                      src={ProjectImage1}
                      alt="projects"
                    />
                  </div>
                </div>

                
                <div
                  className={`transition-transform transform ${
                    isVisible6
                      ? "translate-x-0 opacity-100"
                      : "translate-x-full opacity-0"
                  } duration-500 ease-out`}
                  ref={ref6}
                >
                  <div className="lg:mt-10">
                    <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[25%] xl:mt-4">
                      <img src={Project6} alt="Project 1" />
                    </div>
                    <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                      ZYNOVATEX PORTFOLIO
                    </div>
                    <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                      Introducing a revolutionary POS system with user-friendly
                      interfaces, intuitive dashboards, and robust features.
                      Manage sales, debts, and reports effortlessly while
                      handling inventory and customers. Customize settings to
                      tailor the system to your needs. Experience the future of
                      business management.
                    </div>
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div>
            <div
              className="relative w-[100%]  h-full bg-center bg-cover bg-gradient-to-b from-black to-black p-[7%]"
              style={{ backgroundImage: `url(${Section2Background})` }}
            >
              {/* project1 */}
              <div className="grid md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage3}
                    alt="projects"
                  />
                </div>

                {/* project description */}
                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project1} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    CUPIDCO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    CupidCo is our bespoke wedding proposal website, providing a
                    personalized platform for couples to create and share their
                    unique love stories. It's designed to make proposals
                    unforgettable by combining technology with emotion.
                  </div>
                  <Link to="/project01">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project2 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                {/* project description */}
                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project2} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    POS System
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Are you seeking a comprehensive and efficient Point of Sale
                    (POS) system that seamlessly integrates essential features
                    for your business management? Look no further! Our POS
                    system offers a user-friendly experience with a myriad of
                    functionalities to streamline your operations.
                  </div>
                  <Link to="/project02">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project3 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                {/* project description */}

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project3} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    UNIVERSITY PROJECTS
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Our expertise in university projects encompasses a wide
                    array of AI and ML applications, offering innovative
                    solutions tailored to academic requirements. From data
                    analysis to algorithm implementation, we bring academic
                    projects to life.
                  </div>
                  <Link to="/project03">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project4 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage4}
                    alt="projects"
                  />
                </div>

                {/* project description */}

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project4} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    WEBSITES
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Our website development service focuses on creating dynamic,
                    responsive, and user-friendly websites tailored to your
                    specific needs. From sleek corporate sites to engaging
                    e-commerce platforms, we ensure an online presence that
                    captivates your audience.
                  </div>
                  <Link to="/project04">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project5*/}

              {/* <div className="grid mt-36 md:grid-cols-2">
                

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project5} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    ZYNOVATEX PORTFOLIO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Introducing a revolutionary POS system with user-friendly
                    interfaces, intuitive dashboards, and robust features.
                    Manage sales, debts, and reports effortlessly while handling
                    inventory and customers. Customize settings to tailor the
                    system to your needs. Experience the future of business
                    management.
                  </div>
                  <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                    <p className="p-1 text-center text-white lg:text-lg">
                      See More
                    </p>
                  </div>
                </div>
              </div> */}

              {/* project6 */}
              {/* <div className="grid mt-36 md:grid-cols-2">
                

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project6} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    ZYNOVATEX PORTFOLIO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Introducing a revolutionary POS system with user-friendly
                    interfaces, intuitive dashboards, and robust features.
                    Manage sales, debts, and reports effortlessly while handling
                    inventory and customers. Customize settings to tailor the
                    system to your needs. Experience the future of business
                    management.
                  </div>
                  <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                    <p className="p-1 text-center text-white lg:text-lg">
                      See More
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div>
            <div
              className="relative w-[100%] h-full bg-center bg-cover bg-gradient-to-b from-black to-black p-[10%]"
              style={{ backgroundImage: `url(${Section2Background})` }}
            >
              {/* project1 */}
              <div className="grid md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage3}
                    alt="projects"
                  />
                </div>

                {/* project description */}
                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project1} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    CUPIDCO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    CupidCo is our bespoke wedding proposal website, providing a
                    personalized platform for couples to create and share their
                    unique love stories. It's designed to make proposals
                    unforgettable by combining technology with emotion.
                  </div>
                  <Link to="/project01">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project2 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                {/* project description */}
                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project2} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    POS System
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Are you seeking a comprehensive and efficient Point of Sale
                    (POS) system that seamlessly integrates essential features
                    for your business management? Look no further! Our POS
                    system offers a user-friendly experience with a myriad of
                    functionalities to streamline your operations.
                  </div>
                  <Link to="/project02">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project3 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage4}
                    alt="projects"
                  />
                </div>

                {/* project description */}

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project3} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    UNIVERSITY PROJECTS
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Our expertise in university projects encompasses a wide
                    array of AI and ML applications, offering innovative
                    solutions tailored to academic requirements. From data
                    analysis to algorithm implementation, we bring academic
                    projects to life.
                  </div>
                  <Link to="/project03">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project4 */}
              <div className="grid mt-36 md:grid-cols-2">
                {/* project Image */}

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage2}
                    alt="projects"
                  />
                </div>

                {/* project description */}

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project4} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    WEBSITES
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Our website development service focuses on creating dynamic,
                    responsive, and user-friendly websites tailored to your
                    specific needs. From sleek corporate sites to engaging
                    e-commerce platforms, we ensure an online presence that
                    captivates your audience.
                  </div>
                  <Link to="/project04">
                    <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                      <p className="p-1 text-center text-white lg:text-lg">
                        See More
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* project5*/}
              {/* <div className="grid mt-36 md:grid-cols-2">
                

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project5} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    ZYNOVATEX PORTFOLIO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Introducing a revolutionary POS system with user-friendly
                    interfaces, intuitive dashboards, and robust features.
                    Manage sales, debts, and reports effortlessly while handling
                    inventory and customers. Customize settings to tailor the
                    system to your needs. Experience the future of business
                    management.
                  </div>
                  <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                    <p className="p-1 text-center text-white lg:text-lg">
                      See More
                    </p>
                  </div>
                </div>
              </div> */}

              {/* project6 */}
              {/* <div className="grid mt-36 md:grid-cols-2">
                

                <div>
                  <img
                    className={`w-[100%] h-full rounded-[19px]`}
                    src={ProjectImage1}
                    alt="projects"
                  />
                </div>

                

                <div>
                  <div className="w-[60%] mx-[20%] mt-6 mb-[-10px] md:mt-0 md:w-[80%] md:mx-[10%] xl:mx-[15%] xl:mt-4">
                    <img src={Project6} alt="Project 1" />
                  </div>
                  <div className=" text-center mb-3 text-white text-[22px] font-bold font-['Poppins'] md:text-2xl lg:text-4xl xl:mb-5">
                    ZYNOVATEX PORTFOLIO
                  </div>
                  <div className=" text-justify text-white text-base font-normal font-['Poppins'] md:w-[90%] md:mx-[5%] lg:text-xl xl:w-[80%] xl:mx-[10%]">
                    Introducing a revolutionary POS system with user-friendly
                    interfaces, intuitive dashboards, and robust features.
                    Manage sales, debts, and reports effortlessly while handling
                    inventory and customers. Customize settings to tailor the
                    system to your needs. Experience the future of business
                    management.
                  </div>
                  <div className="w-[50%] mx-[25%] mt-4  rounded-lg shadow-inner border-2 border-white backdrop-blur-[50px] lg:mt-5">
                    <p className="p-1 text-center text-white lg:text-lg">
                      See More
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectExample;

import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import Section2Background from "../../Images/HomeImages/Section3/Section3Background.webp";
import PreviousButton from "../../Images/HomeImages/Section5/PreviousButton.png";
import BackButton from "../../Images/HomeImages/Section5/BackButton.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from "react-countup";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";

const HomeSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const sliderRef = useRef(null);

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      console.log("Slider component is mounted");
    }
  }, []);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.1)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isTikTokHovered, setIsTikTokHovered] = useState(false);

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div
            className="w-[100%] h-full bg-center bg-cover pb-20"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <div style={{ marginBottom: counterOn ? "-64px" : "0" }}>
                {counterOn && (
                  <div>
                    <Text text={"Our Success"} deviceView={deviceView} />
                  </div>
                )}
              </div>
              <div className="success_in_numbers  underline underline-offset-4  text-white text-center font-['Poppins'] text-3xl font-extrabold leading-[156%] uppercase mx-auto ">
                Success in Numbers
              </div>
              <div className="our_accomplishments_and_expertise  mb-10 flex-shrink-0  text-white text-center font-['Poppins'] text-[1.625rem] font-medium leading-[156%] capitalize mx-auto">
                Our Accomplishments and Expertise
              </div>
            </ScrollTrigger>
            <div className="text-center text-white w-[80%] mx-[10%] ">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                {/* card 1 */}
                <div>
                  <div className=" w-[80%] mx-[15%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={1} duration={2} delay={0} />K+
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Projects
                      <br />
                      Completed
                    </div>
                  </div>
                </div>

                {/* card 2 */}
                <div>
                  <div className=" w-[80%] mx-[15%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp
                        start={0}
                        end={110}
                        
                        duration={2}
                        delay={0}
                      />
                      
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Professional
                      <br />
                      Clients
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div>
                  <div className=" w-[80%] mx-[15%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={98} duration={2} delay={0} />%
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Positive
                      <br />
                      Rating
                    </div>
                  </div>
                </div>

                {/* card 4 */}
                <div>
                  <div className=" w-[80%] mx-[15%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={3} duration={2} delay={0.5} />+
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      years
                      <br />
                      Experience
                    </div>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </Slider>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div
            className="w-[100%] h-full bg-center bg-cover py-20"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <div style={{ marginBottom: counterOn ? "-64px" : "0" }}>
                {counterOn && (
                  <div>
                    <Text text={"Our Success"} deviceView={deviceView} />
                  </div>
                )}
              </div>
              <div className="success_in_numbers underline underline-offset-4 mt-5 text-white text-center font-['Poppins'] text-3xl font-extrabold leading-[156%] uppercase mx-auto">
                Success in Numbers
              </div>
              <div className="our_accomplishments_and_expertise  mb-10 flex-shrink-0 mt-2 text-white text-center font-['Poppins'] text-xl font-bold leading-[156%] capitalize mx-auto">
                Our Accomplishments and Expertise
              </div>
            </ScrollTrigger>
            <div className="text-center text-white w-[80%] mx-[10%] mt-10">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                {/* card 1 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={75} duration={5} delay={0} />K
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Projects
                      <br />
                      Completed
                    </div>
                  </div>
                </div>

                {/* card 2 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp
                        start={0}
                        end={2.5}
                        decimals={1}
                        duration={2}
                        delay={0}
                      />
                      K
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Professional
                      <br />
                      Clients
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={98} duration={2} delay={0} />%
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Positive
                      <br />
                      Rating
                    </div>
                  </div>
                </div>

                {/* card 4 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={3} duration={2} delay={0.5} />+
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      years
                      <br />
                      Experience
                    </div>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </Slider>
              <div>
                <div className="absolute w-10 mt-5 left-80 ">
                  <button onClick={previousSlide}>
                    <img
                      src={BackButton}
                      alt="Previous"
                      style={{
                        ...(isLinkedinHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsLinkedinHovered(true)}
                      onMouseLeave={() => setIsLinkedinHovered(false)}
                    />
                  </button>
                </div>
                <div className="absolute w-10 mt-5 transform right-80 ">
                  <button onClick={nextSlide}>
                    <img
                      src={PreviousButton}
                      alt="Next"
                      style={{
                        ...(isTikTokHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsTikTokHovered(true)}
                      onMouseLeave={() => setIsTikTokHovered(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div
            className="w-[100%] h-full bg-center bg-cover py-20"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <div style={{ marginBottom: counterOn ? "-64px" : "0" }}>
                {counterOn && (
                  <div>
                    <Text text={"Our Success"} deviceView={deviceView} />
                  </div>
                )}
              </div>
              <div className="success_in_numbers  underline underline-offset-4 text-white text-center font-['Poppins'] text-2xl mt-8 font-bold leading-[156%] uppercase mx-auto">
                Success in Numbers
              </div>
              <div className="our_accomplishments_and_expertise  mb-10 flex-shrink-0  text-white text-center font-['Poppins'] mt-2 text-lg font-medium leading-[156%] capitalize mx-auto">
                Our Accomplishments and Expertise
              </div>
            </ScrollTrigger>
            <div className="text-center text-white w-[80%] mx-[10%] mt-10">
              <Slider
                ref={sliderRef}
                {...settings}
                className="flex justify-center"
              >
                {/* card 1 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={75} duration={5} delay={0} />K
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Projects
                      <br />
                      Completed
                    </div>
                  </div>
                </div>

                {/* card 2 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp
                        start={0}
                        end={2.5}
                        decimals={1}
                        duration={2}
                        delay={0}
                      />
                      K
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Professional
                      <br />
                      Clients
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={98} duration={2} delay={0} />%
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      Positive
                      <br />
                      Rating
                    </div>
                  </div>
                </div>

                {/* card 4 */}
                <div>
                  <div className=" w-[80%] mx-[10%] py-12 rounded-xl  border-2 border-[#5882c1]/[.49] bg-[#5882c1]/[.28] backdrop-blur-md">
                    <div className="text-white font-['Poppins'] text-[2.8125rem] font-bold leading-[150%] capitalize">
                      <CountUp start={0} end={3} duration={2} delay={0.5} />+
                    </div>
                    <hr className="h-1 w-[20%] mx-[40%] text-center bg-white rounded mb-5" />
                    <div className="text-center text-white text-xl font-medium font-['Poppins'] capitalize">
                      years
                      <br />
                      Experience
                    </div>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </Slider>
              <div>
                <div className="absolute w-10 mt-5 left-60 max-[400px]:left-20 max-[550px]:left-40  ">
                  <button onClick={previousSlide}>
                    <img
                      src={BackButton}
                      alt="Previous"
                      style={{
                        ...(isLinkedinHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsLinkedinHovered(true)}
                      onMouseLeave={() => setIsLinkedinHovered(false)}
                    />
                  </button>
                </div>
                <div className="absolute w-10 mt-5 transform right-60 max-[400px]:right-20 max-[550px]:right-40 ">
                  <button onClick={nextSlide}>
                    <img
                      src={PreviousButton}
                      alt="Next"
                      style={{
                        ...(isTikTokHovered && iconHoverStyle),
                        ...fontFamilyStyle,
                      }}
                      onMouseEnter={() => setIsTikTokHovered(true)}
                      onMouseLeave={() => setIsTikTokHovered(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeSection2;

import React, { useState, useEffect, useRef } from "react";
import "../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../Assests/style";
import BgImage from "../../Images/AboutImages/Section2/Section2Background.webp";
import FounderImage from "../../Images/AboutImages/Section2/FounderImage.png";
import FBImg from "../../Images/FounderImages/fb.png";
import LIImg from "../../Images/FounderImages/LinkedIn.png";
import GmailImg from "../../Images/FounderImages/mail.png";

const customBlue = "#3e3446";
const boxShadowColor = "#452A7C1A";
const fontFamily = "Poppins";

const cardStyle = {
  backgroundColor: `${customBlue}15`,
  backdropFilter: "blur(30px)",
  width: "auto%",
  height: "auto%",
  boxShadow: `50px 1.1966018676757812px 29.91504669189453px 50px ${boxShadowColor}`,
};

const AboutSection2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setDeviceView("mobile");
      } else if (width <= 769) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative">
      <div
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          padding: "10px",
        }}
      >
        <section>
          <ScrollTrigger
            onEnter={() => setConterOn(true)}
            onExit={() => setConterOn(false)}
          >
            <section>
              <div
                style={{
                  marginBottom: counterOn ? "-70px" : "0",
                  marginTop: "50px",
                }}
              >
                {counterOn && (
                  <div>
                    <Text text={"founder"} deviceView={deviceView} />
                  </div>
                )}
              </div>
            </section>
          </ScrollTrigger>
          <section className="text-white font-[' Poppins']">
            <div
              className="
                                    text-center 
                                    font-bold 
                                    leading-[156%]  
                                    uppercase 
                                    lg:text-[55px] 
                                    md:text-5xl 
                                    text-2xl 
                                    mt-10
                                    md:mt-5
                                    lg:mt-1
                                    "
            >
              meet out founder
            </div>
          </section>

          <section>
            <div
              className="
                        glass_frame
                        w-[70%] 
                        h-auto 
                        rounded-[0.8125rem] 
                        border-[3px] 
                        border-[#fff]/[.30]
                        bg-[#fff]/[.10]
                        mt-7 
                        mb-7 
                        ml-[15%] 
                        grid gap-8 
                        lg:grid-cols-2
                        lg:items-center
                        lg:text-left
                        lg:w-[80%]
                        lg:ml-[10%]
                        min-[1920px]:w-[60%]
                        min-[1920px]:ml-[20%]
                        "
            >
              <div>
                <img
                  src={FounderImage}
                  alt="Founder Image"
                  className="
                                w-[100%] 
                                h-[100% ]
                                rounded-xl
                                "
                />
              </div>
              <div>
                <div>
                  <h1 className="text-white text-center font-['Poppins'] text-[1.375rem] font-bold leading-[normal] uppercase mt-5 lg:text-4xl md:text-3xl">
                    Founder
                  </h1>
                  <svg
                    width={79}
                    height={4}
                    viewBox="0 0 79 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto"
                  >
                    <path
                      d="M1.92383 1.59399H76.5298"
                      stroke="white"
                      strokeWidth={3}
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <p
                    className="
                                    w-[80%]
                                    text-white 
                                    text-justify 
                                    font-['Poppins'] 
                                    text-sm 
                                    font-medium 
                                    leading-[1.5625rem] 
                                    mx-auto 
                                    mb-5 
                                    mt-5 
                                    md:w-[100%] 
                                    lg:w-[100%] 
                                    md:pl-5
                                    md:pr-5
                                    lg:pl-10
                                    lg:pr-10
                                    md:text-[20px]
                                    lg:text-lg
                                    
                                    "
                  >
                    Experienced Contractor with a demonstrated history of
                    working in the information technology and services industry.
                    Skilled in Angular, React js, node js, SQL, AWS, JS, TS,
                    java, Spring. Strong professionals with a Bachelor's degree
                    focused on Information Technology from the University of
                    Moratuwa.
                  </p>
                </div>
                <div>
                  <p className="w-full h-full text-white text-center font-['Poppins'] text-[1.75rem] font-bold leading-[normal]">
                    Mr. Akila Dilan
                  </p>

                  <p className="pb-5 text-white text-center font-['Poppins'] text-sm italic font-medium leading-[normal]">
                    CEO and Founder <br />
                    <div className="grid gap-8 grid-cols-3 mt-5 mx-[20%] lg:ml-[25%] md:ml-[25%]">
                      <a href="mailto:info.zx@zynovatex.com" target="_blank">
                        <img
                          src={GmailImg}
                          alt="Founder FB Image"
                          className="w-10"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/akila-dilan/"
                        target="_blank"
                      >
                        <img
                          src={LIImg}
                          alt="Founder LinkedIn Image"
                          className="w-10 "
                        />
                      </a>
                      <a
                        href="https://web.facebook.com/akila.medaduwage"
                        target="_blank"
                      >
                        <img
                          src={FBImg}
                          alt="Founder FaceBook Image"
                          className="w-10"
                        />
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default AboutSection2;

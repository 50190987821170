import React, { useState, useEffect } from "react";
import BackgroundImage from "../../Images/MemberImages/Section1Background.webp";
import Member1Image from "../../Images/MemberImages/MemberProfile.webp";
import MemberSocialMedia from "./MemberSocialMedia";

const Member1 = () => {

  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div className="relative">
            <div
              className="-[100%]  h-full bg-cover bg-center bg-no-repeat py-[10%]"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div>
                <div className="w-[100%] h-[220px] mt-16 border-[#fff]/[.10] p-[3%]  bg-[#fff]/[.10]  border-2 backdrop-blur-[50px]">
                  <div>
                    <div className="mr-[500px] max-xl:mr-[400px]">
                      <div className=" text-center ml-[] text-white text-4xl font-bold font-['Poppins'] uppercase tracking-widest">
                        STEPHANIE WILLIAM
                        <p className=" mt-4 text-white text-lg font-semibold font-['Poppins'] uppercase tracking-wide">
                          Lead UI/UX Designer
                        </p>
                        <MemberSocialMedia />
                      </div>
                    </div>
                    {/* member image */}
                    <div className="flex justify-end">
                      <img
                        src={Member1Image}
                        alt="member1"
                        className="w-[400px] mt-[-275px] mr-60 max-xl:mr-16 max-lg:mr-20 "
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* member details */}
              <div className="w-[80%] mx-[10%] mt-28">
                <div className=" text-white text-xl font-medium font-['Poppins'] leading-[29.10px] text-justify">
                  Meet John Smith, our Lead UI/UX Designer with a passion for
                  crafting seamless digital experiences. With a blend of
                  creativity and user-centered design principles, John brings a
                  wealth of expertise in transforming complex concepts into
                  intuitive, visually stunning interfaces. He thrives on
                  delivering designs that not only captivate users but also
                  elevate the overall usability of our products.
                </div>

                <div className="text-pink-600 text-2xl font-medium font-['Poppins'] leading-[34.20px] mt-10">
                  About Me
                </div>

                <div className=" text-white text-xl font-medium font-['Poppins'] leading-[29.10px] text-justify mt-10">
                  With a rich background in user interface and experience
                  design, John Smith has honed his skills to create designs that
                  seamlessly blend aesthetics with functionality. Drawing from
                  years of experience, John has mastered the art of translating
                  user needs into visually engaging and intuitive designs. His
                  approach centers around a deep understanding of user behavior,
                  allowing him to craft digital solutions that not only meet but
                  exceed user expectations. Passionate about every pixel and
                  interaction, John is dedicated to crafting interfaces that not
                  only look stunning but also provide exceptional user
                  experiences.
                </div>
                <div className=" text-pink-600 text-2xl font-medium font-['Poppins'] mt-10 pb-10">
                  Expertise in UI/UX Design
                </div>

                <div className="grid grid-cols-3 gap-4">
                  {/* card 1 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      User Interface (UI) and
                      <br />
                      User Experience (UX) Design
                    </div>
                  </div>
                  {/* card 2 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      Wireframing, Prototyping,
                      <br />
                      and Interaction Design
                    </div>
                  </div>
                  {/* card 3 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      Information Architecture
                      <br />
                      and Visual Design
                    </div>
                  </div>
                  {/* card 4 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      Figma, Sketch, and
                      <br />
                      Collaborative Design Tools
                    </div>
                  </div>
                  {/* card 5 */}
                  <div className="w-[100%] h-full pl-20 p-1 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      Usability Testing and
                      <br />
                      User-Centered Design (UCD) Principles
                    </div>
                  </div>
                  {/* card 6 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-lg font-medium font-['Poppins']">
                      Aesthetic Sensibility and
                      <br />
                      Problem Solving
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <MemberSocialMedia />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div className="relative">
            <div
              className="-[100%]  h-full bg-cover bg-center bg-no-repeat py-[10%]"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div>
                <div className="w-[100%] h-[220px] mt-16 border-[#fff]/[.10] p-[3%]  bg-[#fff]/[.10]  border-2 backdrop-blur-[50px]">
                  <div>
                    <div className="mr-[500px] max-xl:mr-[400px]">
                      <div className=" text-center ml-[] text-white text-4xl font-bold font-['Poppins'] uppercase tracking-widest">
                        STEPHANIE WILLIAM
                        <p className=" mt-4 text-white text-lg font-semibold font-['Poppins'] uppercase tracking-wide">
                          Lead UI/UX Designer
                        </p>
                        <MemberSocialMedia />
                      </div>
                    </div>
                    {/* member image */}
                    <div className="flex justify-end">
                      <img
                        src={Member1Image}
                        alt="member1"
                        className="w-[400px] mt-[-275px] mr-60 max-xl:mr-16 max-lg:mr-20 "
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* member details */}
              <div className="w-[80%] mx-[10%] mt-28">
                <div className=" text-white text-lg font-normal font-['Poppins'] leading-[29.10px] text-justify">
                  Meet John Smith, our Lead UI/UX Designer with a passion for
                  crafting seamless digital experiences. With a blend of
                  creativity and user-centered design principles, John brings a
                  wealth of expertise in transforming complex concepts into
                  intuitive, visually stunning interfaces. He thrives on
                  delivering designs that not only captivate users but also
                  elevate the overall usability of our products.
                </div>

                <div className="text-pink-600 text-2xl font-medium font-['Poppins'] leading-[34.20px] mt-10">
                  About Me
                </div>

                <div className=" text-white text-lg font-normal font-['Poppins'] leading-[29.10px] text-justify mt-10">
                  With a rich background in user interface and experience
                  design, John Smith has honed his skills to create designs that
                  seamlessly blend aesthetics with functionality. Drawing from
                  years of experience, John has mastered the art of translating
                  user needs into visually engaging and intuitive designs. His
                  approach centers around a deep understanding of user behavior,
                  allowing him to craft digital solutions that not only meet but
                  exceed user expectations. Passionate about every pixel and
                  interaction, John is dedicated to crafting interfaces that not
                  only look stunning but also provide exceptional user
                  experiences.
                </div>
                <div className=" text-pink-600 text-2xl font-medium font-['Poppins'] mt-10 pb-10">
                  Expertise in UI/UX Design
                </div>

                <div className="grid grid-cols-3 gap-4">
                  {/* card 1 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      User Interface (UI) and
                      <br />
                      User Experience (UX) Design
                    </div>
                  </div>
                  {/* card 2 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Wireframing, Prototyping,
                      <br />
                      and Interaction Design
                    </div>
                  </div>
                  {/* card 3 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Information Architecture
                      <br />
                      and Visual Design
                    </div>
                  </div>
                  {/* card 4 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Figma, Sketch, and
                      <br />
                      Collaborative Design Tools
                    </div>
                  </div>
                  {/* card 5 */}
                  <div className="w-[100%] h-full pl-20 p-1 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Usability Testing and
                      <br />
                      User-Centered Design (UCD) Principles
                    </div>
                  </div>
                  {/* card 6 */}
                  <div className="w-[100%] h-full pl-20 p-4 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Aesthetic Sensibility and
                      <br />
                      Problem Solving
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <MemberSocialMedia />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div className="relative">
            <div
              className="-[100%]  h-full bg-cover bg-center bg-no-repeat py-[10%]"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div>
                <div className="w-[100%] h-full  mt-60 border-[#fff]/[.05] p-[4%]  bg-[#fff]/[.10]  border-2 backdrop-blur-[50px]">
                  <div className="flex justify-center mt-[-200px] ">
                    {/* member image */}
                    <img
                      src={Member1Image}
                      alt="member1"
                      className="w-[400px]  "
                    />
                  </div>
                  <div>
                    <div className="">
                      <div className=" text-center  text-white text-3xl font-bold font-['Poppins'] uppercase tracking-widest">
                        STEPHANIE WILLIAM
                        <p className=" mt-3 text-white text-lg font-semibold font-['Poppins'] uppercase tracking-wide">
                          Lead UI/UX Designer
                        </p>
                        <MemberSocialMedia />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* member details */}
              <div className="w-[80%] mx-[10%] mt-20">
                <div className=" text-white text-base font-normal font-['Poppins'] leading-[29.10px] text-justify">
                  Meet John Smith, our Lead UI/UX Designer with a passion for
                  crafting seamless digital experiences. With a blend of
                  creativity and user-centered design principles, John brings a
                  wealth of expertise in transforming complex concepts into
                  intuitive, visually stunning interfaces. He thrives on
                  delivering designs that not only captivate users but also
                  elevate the overall usability of our products.
                </div>

                <div className="text-pink-600 text-xl font-medium font-['Poppins'] leading-[34.20px] mt-6">
                  About Me
                </div>

                <div className=" text-white text-base font-normal font-['Poppins'] leading-[29.10px] text-justify mt-6">
                  With a rich background in user interface and experience
                  design, John Smith has honed his skills to create designs that
                  seamlessly blend aesthetics with functionality. Drawing from
                  years of experience, John has mastered the art of translating
                  user needs into visually engaging and intuitive designs. His
                  approach centers around a deep understanding of user behavior,
                  allowing him to craft digital solutions that not only meet but
                  exceed user expectations. Passionate about every pixel and
                  interaction, John is dedicated to crafting interfaces that not
                  only look stunning but also provide exceptional user
                  experiences.
                </div>
                <div className=" text-pink-600 text-xl font-medium font-['Poppins'] mt-6 pb-6">
                  Expertise in UI/UX Design
                </div>

                <div className="">
                  {/* card 1 */}
                  <div className="w-[100%] h-full pl-20 p-4  border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      User Interface (UI) and
                      <br />
                      User Experience (UX) Design
                    </div>
                  </div>
                  {/* card 2 */}
                  <div className="w-[100%] h-full pl-20 p-4 mt-5 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Wireframing, Prototyping,
                      <br />
                      and Interaction Design
                    </div>
                  </div>
                  {/* card 3 */}
                  <div className="w-[100%] h-full pl-20 p-4 mt-5 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Information Architecture
                      <br />
                      and Visual Design
                    </div>
                  </div>
                  {/* card 4 */}
                  <div className="w-[100%] h-full pl-20 p-4 mt-5 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Figma, Sketch, and
                      <br />
                      Collaborative Design Tools
                    </div>
                  </div>
                  {/* card 5 */}
                  <div className="w-[100%] h-full pl-20 p-1 mt-5 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Usability Testing and
                      <br />
                      User-Centered Design (UCD) Principles
                    </div>
                  </div>
                  {/* card 6 */}
                  <div className="w-[100%] h-full pl-20 p-4 mt-5 border-[#fff]/[.10] bg-[#fff]/[.10] rounded-[15px] shadow-inner border-2 backdrop-blur-[50px]">
                    <div className="text-white text-sm font-medium font-['Poppins']">
                      Aesthetic Sensibility and
                      <br />
                      Problem Solving
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <MemberSocialMedia />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Member1;

import React, { useState, useEffect, useRef, Component } from 'react';
import Slider from "react-slick";
import BgImage from "../../Images/AboutImages/Section3/Section2Background.webp";
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../Assests/style';
import SampleImage1 from "../../Images/AboutImages/Section2/FounderImage.png";
import SampleImage2 from "../../Images/AboutImages/Section3/Director03.png";
import SampleImage3 from "../../Images/AboutImages/Section3/Director02.png";
import { LiaDotCircle } from "react-icons/lia";



const AboutSection3 = () => {
    const [counterOn, setConterOn] = useState(false);
    const [deviceView, setDeviceView] = useState('web');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setDeviceView('mobile');
            } else if (width <= 769) {
                setDeviceView('tablet');
            } else {
                setDeviceView('web');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const baseIconSize = 25;
    const largerIconSize = 30;
    const dotMarginTop = 20;
    const activeColor = "#fff";
    const inactiveColor = "#FFFFFF80";
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        beforeChange: (current, next) => setCurrentSlide(next),
        customPaging: function (i) {
            const size = i === currentSlide ? largerIconSize : baseIconSize;
            const color = i === currentSlide ? activeColor : inactiveColor;
            return (
                <div style={{ marginTop: `${dotMarginTop}px` }}>
                    <LiaDotCircle size={size} color={color} />
                </div>
            );
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
        ],
    };

    return (
      <div className="relative ">
        <div
          style={{
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            padding: "10px",
          }}
        >
          <section>
            <ScrollTrigger
              onEnter={() => setConterOn(true)}
              onExit={() => setConterOn(false)}
            >
              <section>
                <div
                  style={{
                    marginBottom: counterOn ? "-80px" : "0",
                    marginTop: "50px",
                  }}
                >
                  {counterOn && (
                    <div>
                      <Text text={"DIRECTORS"} deviceView={deviceView} />
                    </div>
                  )}
                </div>
              </section>
            </ScrollTrigger>
            <section className="text-white font-[' Poppins']">
              <h1
                className="
                                    font-[' Poppins']
                                    text-center 
                                    font-bold 
                                    leading-[156%] 
                                    uppercase 
                                    lg:text-[45px] 
                                    md:text-[40px] 
                                    text-[20px]
                                    md:mt-5
                                    lg:mt-1
                                    mt-12
                                    "
              >
                BOARD OF DIRECTORS
              </h1>

              <div
                className="
                                    font-['Poppins']
                                    text-center 
                                    font-normal 
                                    leading-[156%]  
                                    uppercase 
                                    mt-0
                                    mb-10
                                    text-[15px]
                                    lg:text-2xl 
                                    md:text-2xl 
                                    md:-mt-3
                                    lg:-mt-1
                                    "
              >
                The faces behind our success
              </div>
            </section>

            <section className="mx-[15%] ">
              <div className="pb-10 mb-10 ">
                <Slider {...settings} className="flex justify-center">
                  {/* card 1 */}
                  <div>
                    <div className="glass_frame h-[100%] w-[90%]  mx-auto pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.1]">
                      <img
                        src={SampleImage1}
                        alt="teammember1"
                        className="w-full rounded-xl"
                      />
                      <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                        CEO & Founder
                      </div>

                      <svg
                        width={40}
                        height={4}
                        viewBox="0 0 40 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto"
                      >
                        <path
                          d="M2.14844 1.74121H37.896"
                          stroke="white"
                          strokeWidth={3}
                          strokeLinecap="round"
                        />
                      </svg>

                      <div className="w-[90%] mx-[5%] mt-2 pb-8 text-center text-white text-[20px] font-bold font-['Poppins'] capitalize">
                        Mr. Akila Dilan
                      </div>
                      {/* <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                        24 Avenue,
                        <br />
                        United Status
                      </div> */}
                    </div>
                  </div>

                  {/* card 2 */}
                  <div>
                    <div className="glass_frame h-[100%] w-[90%] mx-auto pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.1]">
                      <img
                        src={SampleImage2}
                        alt="teammember1"
                        className="w-full rounded-xl"
                      />
                      <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4">
                        COO & Software Engineer
                      </div>
                      <svg
                        width={40}
                        height={4}
                        viewBox="0 0 40 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto"
                      >
                        <path
                          d="M2.14844 1.74121H37.896"
                          stroke="white"
                          strokeWidth={3}
                          strokeLinecap="round"
                        />
                      </svg>

                      <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[20px] font-bold font-['Poppins'] capitalize">
                        Mrs. Hiruni Gamage
                      </div>
                      {/* <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                        24 Avenue,
                        <br />
                        United Status
                      </div> */}
                    </div>
                  </div>

                  {/* card 3 */}
                  <div>
                    <div className="glass_frame h-[100%] w-[90%] mx-auto pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.1]">
                      <img
                        src={SampleImage3}
                        alt="teammember1"
                        className="w-full rounded-xl"
                      />
                      <div className=" text-center text-white text-[23px]  font-bold font-['Poppins'] uppercase mt-4">
                        CFO
                      </div>
                      <svg
                        width={40}
                        height={4}
                        viewBox="0 0 40 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto"
                      >
                        <path
                          d="M2.14844 1.74121H37.896"
                          stroke="white"
                          strokeWidth={3}
                          strokeLinecap="round"
                        />
                      </svg>

                      <div className="w-[90%] mx-[5%] mt-2 pb-8 text-center text-white text-[20px] font-bold font-['Poppins'] capitalize">
                        Mr. Sumith Disanayake
                      </div>
                      {/* <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                        24 Avenue,
                        <br />
                        United Status
                      </div> */}
                    </div>
                  </div>
                </Slider>
              </div>
            </section>
          </section>
        </div>
      </div>
    );
};

export default AboutSection3;

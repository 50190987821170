// style.js
import React from 'react';
import './writter.css'; 

export default function SetText({ text, deviceView }) {
    let fontSize;

    switch (deviceView) {
        case 'web':
            fontSize = '100px';
            break;
        case 'tablet':
            fontSize = '70px';
            break;
        case 'mobile':
            fontSize = '40px';
            break;
        case 'extramobile':
            fontSize = '30px'
            break;
        case 'small':
            fontSize =  '26px';
            break;
        default:
            fontSize = '100px';
    }

    const seasideTextStyle = {
        fontSize,
        fontWeight: 900,
        fontFamily: 'Poppins',
        WebkitTextStroke: '2px rgba(128, 128, 128, 0.5)',
        WebkitTextFillColor: 'transparent',
        textAlign: 'center',
        textTransform: 'uppercase',
        wordSpacing: '5px',        
    };

    const animationStyle = {
        // borderRight: '.1em solid black',
        width: '100%',
        margin: '0 auto', // Center horizontally
        paddingTop: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        animation: 'typing 1s steps(60, end) forwards, blink-caret .5s step-end',
    };

    return (
        <div className="animation" style={animationStyle}>
            <p style={{ ...seasideTextStyle }}>{text}</p>
        </div>
    );
}

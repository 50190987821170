import React from 'react'
import MSec01 from './MSec01'
import MSec02 from './MSec02'


const Marketing = () => {
  return (
    <div>
      <MSec01/>
      <MSec02/>
    </div>
  )
}

export default Marketing

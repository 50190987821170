import React, { useState, useEffect } from "react";
import Section2Background from "../../../Images/TeamsImages/Section2Background.webp";
import ReactSlick from "../../../Assests/ReactSlick";
import "../../../Assests/homeSection2Style.css";
import ScrollTrigger from "react-scroll-trigger";
import Text from "../../../Assests/style";
import UiUxTeam from './UiUxTeam';

const Section2 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        className=" w-[100%] h-full bg-cover bg-center bg-no-repeat px-[10%] pb-[10%] max-md:pb-[20%] "
        style={{ backgroundImage: `url(${Section2Background})` }}
      >
        <ScrollTrigger
          onEnter={() => setConterOn(true)}
          onExit={() => setConterOn(false)}
        >
          <div style={{ marginBottom: "-65px" }}>
            {counterOn && (
              <div>
                <Text text={"zynovatex team"} deviceView={deviceView} />
              </div>
            )}
          </div>

          <div className="text-center max-sm:mt-10 md:mt-6 lg:mt-0  text-white text-[49px] font-bold font-['Poppins'] uppercase leading-tight max-lg:text-[35px] max-md:text-[25px] max-sm:mb-4 max-sm:font-medium ">
            Meet Our Team
          </div>
          <div className="text-center mt-[2%] mb-[3%] text-white text-lg font-medium font-['Poppins'] capitalize leading-[29px] max-lg:text-base max-md:text-sm max-sm:mb-5 ">
            Where Imagination Meets Precision. Our UI/UX team transforms ideas
            into captivating digital experiences, blending creativity with
            precision to redefine user interaction in the most intuitive and
            visually appealing ways.
          </div>
        </ScrollTrigger>
        {/* slider */}
        <div>
          <UiUxTeam />
        </div>
      </div>
    </div>
  );
};

export default Section2;

import React, { useState, useEffect } from "react";
import Section2Background from "../../Images/NewsImages/Section2Background.webp";
import DropDown from "./DropDown";
import NewsDetails from "./NewsDetails";
import Select from "react-select";

const NewsSection2 = () => {
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSelectMonth = (month) => {
    setSelectedMonth(month);
    setDropdownVisible(false);
    // You can perform any action based on the selected month here
  };

  const [yearOptionsVisible, setYearOptionsVisible] = useState(false);

  const toggleYearOptions = () => {
    setYearOptionsVisible(!yearOptionsVisible);
  };

  const options = [
    {
      label: "Category",
      options: [
        { value: "option1", label: "Product Updates" },
        { value: "option2", label: "Company Events" },
        { value: "option3", label: "Industry News" },
        { value: "option4", label: "Market Insights" },
        { value: "option5", label: "Innovations and Advancements" },
      ],
    },
    {
      label: "Date",
      options: [
        { value: "option6", label: "Last 7 Days" },
        { value: "option7", label: "Last 30 Days" },
        { value: "option8", label: "Last Year" },
      ],
    },
    {
      label: "Year",
      options: [
        { value: "option9", label: "2024" },
        { value: "option10", label: "2023" },
        { value: "option11", label: "2022" },
        { value: "option12", label: "2022" },
        { value: "option13", label: "2022" },
      ],
    },
    // Add more groups or options as needed
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backdropFilter: "blur(8px)",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    }),
    menu: (styles) => ({
      ...styles,
      backdropFilter: "blur(30px)",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "" : "", // Change the background color of options on hover
      color: isFocused ? "#fff" : "#fff", // Change the text color of options on hover
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // Change the placeholder text color
    }),
  };

  return (
    <div>
      {deviceView === "web" && (
        // Web view
        <>
          <div
            className="bg-no-repeat bg-cover w-100% h-full px-[10%] py-[5%] text-white font-['poppins']"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <div className="grid grid-cols-8 gap-10 ">
              {/* Column 1 */}
              <div className="col-span-2">
                <div class=" w-[100%] h-full border-[#fff]/[.50] px-[12%] py-[10%] bg-[#fff]/[.10] rounded-[10px] border-2 backdrop-blur-[50px]">
                  <div class="text-xl font-bold">Filter By</div>
                  {/* section1 */}
                  <div>
                    <p class="text-gray-400 mt-5">Category</p>
                    <div>
                      <label for="checkbox1">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Include Category</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox2">
                        <input
                          type="checkbox"
                          id="checkbox2"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Company Events</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox3">
                        {" "}
                        <input
                          type="checkbox"
                          id="checkbox3"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Industry News</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox4">
                        <input
                          type="checkbox"
                          id="checkbox4"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Industry News</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox5">
                        <input
                          type="checkbox"
                          id="checkbox5"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3">
                          {" "}
                          Innovations and Advancements
                        </span>
                      </label>
                    </div>
                  </div>
                  <hr className="my-8 border-t border-gray-300" />
                  {/* section2 */}
                  <div>
                    <p class="text-gray-400 mt-5">Date</p>
                    <div>
                      <label for="checkbox6">
                        <input
                          type="checkbox"
                          id="checkbox6"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last 7 Days</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox7">
                        <input
                          type="checkbox"
                          id="checkbox7"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last 10 Days</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox8">
                        <input
                          type="checkbox"
                          id="checkbox8"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last Year</span>
                      </label>
                    </div>
                  </div>

                  {/* section3 */}
                  <hr className="my-8 border-t border-gray-300" />
                  <div className="mt-5 text-xl font-bold">News Archive</div>
                  <DropDown />
                </div>
              </div>

              {/* Column 2 */}
              <div className="col-span-6">
                <div>
                  <NewsDetails />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "tablet" && (
        // Tablet view
        <>
          <div
            className="bg-no-repeat bg-cover w-100% h-full px-[10%] py-[5%] text-white font-['poppins']"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <div className="grid grid-cols-8 gap-10 ">
              {/* Column 1 */}
              <div className="col-span-3">
                <div class=" w-[100%] h-full border-[#fff]/[.50] px-[12%] py-[10%] bg-[#fff]/[.10] rounded-[10px] border-2 backdrop-blur-[50px]">
                  <div class="text-lg font-semibold">Filter By</div>
                  {/* section1 */}
                  <div className="text-base">
                    <p class="text-gray-400 mt-5">Category</p>
                    <div>
                      <label for="checkbox1">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Include Category</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox2">
                        <input
                          type="checkbox"
                          id="checkbox2"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Company Events</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox3">
                        <input
                          type="checkbox"
                          id="checkbox3"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Industry News</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox4">
                        <input
                          type="checkbox"
                          id="checkbox4"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Industry News</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox5">
                        <input
                          type="checkbox"
                          id="checkbox5"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3">
                          {" "}
                          Innovations and Advancements
                        </span>
                      </label>
                    </div>
                  </div>
                  <hr className="my-8 border-t border-gray-300" />
                  {/* section2 */}
                  <div>
                    <p class="text-gray-400 mt-5">Date</p>
                    <div>
                      <label for="checkbox6">
                        <input
                          type="checkbox"
                          id="checkbox6"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last 7 Days</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox7">
                        <input
                          type="checkbox"
                          id="checkbox7"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last 10 Days</span>
                      </label>
                    </div>
                    <div>
                      <label for="checkbox8">
                        <input
                          type="checkbox"
                          id="checkbox8"
                          className="w-4 h-4 mt-5"
                        />
                        <span className="ml-3"> Last Year</span>
                      </label>
                    </div>
                  </div>

                  {/* section3 */}
                  <hr className="my-8 border-t border-gray-300" />
                  <div className="mt-5 text-lg font-semibold">News Archive</div>
                  <DropDown />
                </div>
              </div>

              {/* Column 2 */}
              <div className="col-span-5">
                <div>
                  <NewsDetails />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {deviceView === "mobile" && (
        // Mobile view
        <>
          <div
            className="bg-no-repeat bg-cover w-100% h-full px-[10%] py-[5%] text-black font-['poppins']"
            style={{ backgroundImage: `url(${Section2Background})` }}
          >
            <div>
              <Select
                options={options}
                isMulti
                onChange={handleSelectChange}
                value={selectedOptions}
                placeholder="Filter By"
                styles={customStyles}
              />
            </div>

            {/* newstitles */}
            <div className="mt-10">
              <NewsDetails />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsSection2;

import React from "react";
import UiUxIntroSec01 from './UiUxIntroSec01';
import UiUxIntroSec02 from "./UiUxIntroSec02";

const UiUxIntro = () => {
  return (
    <div>
      <UiUxIntroSec01 />
      <UiUxIntroSec02/>
    </div>
  );
};

export default UiUxIntro;

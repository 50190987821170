import React, { useState } from "react";
import GithubIcon from "../../Images/FooterImages/Github.png";
import LinkedinIcon from "../../Images/FooterImages/LinkedIn.png";
import InstaIcon from "../../Images/FooterImages/Instagram.png";
import FbIcon from "../../Images/FooterImages/Facebook.png";

const MemberSocialMedia = () => {
  const fontFamilyStyle = { fontFamily: "Poppins" };
  const [isFbHovered, setIsFbHovered] = useState(false);
  const [isInstaHovered, setIsInstaHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isGithubHovered, setIsGithubHovered] = useState(false);

  const iconHoverStyle = {
    filter: "drop-shadow(0 0 0.20rem rgba(255, 255, 255, 0.8))",
    transform: "scale(1.15)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  return (
    <div>
      {/* SocialMedia icons */}
      <div className="flex justify-center mt-5">
        <div className="flex">
          <img
            src={FbIcon}
            alt="Facebook"
            className="w-10 h-10 transition-transform transform cursor-pointer max-md:w-8 max-md:h-8"
            style={{ ...(isFbHovered && iconHoverStyle), ...fontFamilyStyle }}
            onMouseEnter={() => setIsFbHovered(true)}
            onMouseLeave={() => setIsFbHovered(false)}
          />

          <img
            src={InstaIcon}
            alt="Instagram"
            className="w-10 h-10 mx-2 transition-transform transform cursor-pointer max-lg:mx-1 max-md:w-8 max-md:h-8"
            style={{
              ...(isInstaHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsInstaHovered(true)}
            onMouseLeave={() => setIsInstaHovered(false)}
          />

          <img
            src={LinkedinIcon}
            alt="LinkedIn"
            className="w-10 h-10 transition-transform transform cursor-pointer max-md:w-8 max-md:h-8"
            style={{
              ...(isLinkedinHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsLinkedinHovered(true)}
            onMouseLeave={() => setIsLinkedinHovered(false)}
          />

          <img
            src={GithubIcon}
            alt="GitHub"
            className="w-10 h-10 mx-2 transition-transform transform cursor-pointer max-lg:mx-1 max-md:w-8 max-md:h-8"
            style={{
              ...(isGithubHovered && iconHoverStyle),
              ...fontFamilyStyle,
            }}
            onMouseEnter={() => setIsGithubHovered(true)}
            onMouseLeave={() => setIsGithubHovered(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberSocialMedia;

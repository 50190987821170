import React, { useState, useEffect } from "react";
import Section1Background from '../../Images/CareerImages/Section1/Section1Background.webp';
import '../../Assests/homeSection2Style.css'
import ScrollTrigger from 'react-scroll-trigger';
import Text from '../../Assests/style';
import { Link } from "react-router-dom";

const fontFamily = 'Poppins';

const CareerSection1 = () => {
  const [counterOn, setConterOn] = useState(false);
  const [deviceView, setDeviceView] = useState("web"); // Default to web view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceView("mobile");
      } else if (width <= 1024) {
        setDeviceView("tablet");
      } else {
        setDeviceView("web");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

   const handleOpenCareersClick = () => {
     const section = document.getElementById("careerSection2");
     if (section) {
       section.scrollIntoView({ behavior: "smooth" });
     }
   };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    scrollToTop();
    
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  
  return (
    <>
      <div className="relative">
        <div
          className="w-[100%] flex justify-center h-screen bg-cover bg-center bg-no-repeat  "
          style={{ backgroundImage: `url(${Section1Background})` }}
        >
          <ScrollTrigger
            onEnter={() => setConterOn(true)}
            onExit={() => setConterOn(false)}
          >
            <div style={{ marginBottom: "-64px", marginTop: "180px " }}>
              {counterOn && (
                <div>
                  <Text text={"careers"} deviceView={deviceView} />
                </div>
              )}
            </div>
            <div className="text-center">
              <h1
                className="font-semibold text-white text-5xl max-lg:text-4xl max-lg:mt-5 max-md:mt-8 max-md:text-3xl max-sm:text-2xl mt-[0] inline-block border-b border-gray-300 pb-1 tracking-wider uppercase"
                style={{ fontFamily: fontFamily }}
              >
                careers
              </h1>
              <div
                className="  text-center w-[80%] text-white text-lg max-lg:text-lg max-md:text-base max-sm:text-sm font-normal capitalize leading-[32px] mx-auto mt-8"
                style={{ fontFamily: fontFamily }}
              >
                Join our dynamic team: Explore exciting career opportunities.
                Discover a world of possibilities with us. We're passionate
                about fostering growth, innovation, and professional
                development. Take the next step in your career journey with us
              </div>
              <div className="flex justify-center mt-10">
                <div
                  onClick={handleOpenCareersClick}
                  className="w-[20%] max-sm:w-[40%] h-full pb-1 rounded-lg shadow-inner border-2 border-blue-900 backdrop-blur-[50px] cursor-pointer"
                >
                  <div className="text-center items-center text-white text-[20px] max-sm:text-[15px] mt-1 font-medium font-['Poppins']">
                    Open Careers
                  </div>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </>
  );
};

export default CareerSection1;

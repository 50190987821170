import React, { useState } from "react";
import Slider from "react-slick";
import ProjectImage1 from "../Images/TeamsImages/ProjectImage1.png";
import { LiaDotCircle } from "react-icons/lia";

const ReactSlick3 = () => {
   const baseIconSize = 25;
   const largerIconSize = 30;
   const dotMarginTop = 20;
   const activeColor = "#fff";
   const inactiveColor = "#FFFFFF80";
   const [currentSlide, setCurrentSlide] = useState(0);

   const settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     initialSlide: 0,
     autoplay: true,
     autoplaySpeed: 2000,
     arrows: false,
     beforeChange: (current, next) => setCurrentSlide(next),
     customPaging: function (i) {
       const size = i === currentSlide ? largerIconSize : baseIconSize;
       const color = i === currentSlide ? activeColor : inactiveColor;
       return (
         <div style={{ marginTop: `${dotMarginTop}px` }}>
           <LiaDotCircle size={size} color={color} />
         </div>
       );
     },
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 1,
           infinite: true,
           dots: true,
         },
       },
       {
         breakpoint: 768,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           initialSlide: 2,
         },
       },
     ],
   };

  return (
    <div>
      <div className="text-center text-white">
        <Slider {...settings}>
          <div>
            {/* card1*/}
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]">
                {/* <img
                  src={ProjectImage1}
                  alt="teammember1"
                  className="w-full rounded-xl"
                /> */}
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4 max-lg:text-[20px] max-md:text-[18px] mw-[80%] mx-[10%]">
                  Design Excellence Reward
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Delve into a showcase of our UI/UX team's exceptional work,
                  spanning diverse projects that exemplify their creativity and
                  expertise. Explore designs that redefine digital interactions
                  and elevate user experiences.
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* card2*/}
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]">
                {/* <img
                  src={ProjectImage1}
                  alt="teammember1"
                  className="w-full rounded-xl"
                /> */}
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4 max-lg:text-[20px] max-md:text-[18px] w-[80%] mx-[10%]">
                  Design Excellence Award
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Delve into a showcase of our UI/UX team's exceptional work,
                  spanning diverse projects that exemplify their creativity and
                  expertise. Explore designs that redefine digital interactions
                  and elevate user experiences.
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* card3*/}
            <div>
              <div className="h-[100%] w-[90%] mx-[5%] pb-4 rounded-xl  border-2 border-[#fff]/[.19] bg-[#fff]/[.18]">
                {/* <img
                  src={ProjectImage1}
                  alt="teammember1"
                  className="w-full rounded-xl"
                /> */}
                <div className=" text-center text-white text-[23px] font-bold font-['Poppins'] uppercase mt-4 max-lg:text-[20px] max-md:text-[18px] w-[80%] mx-[10%]">
                  Creative Excellence Accolade
                </div>
                <div className="w-[90%] mx-[5%] mt-2 text-center text-white text-[13px] font-normal font-['Poppins'] capitalize">
                  Delve into a showcase of our UI/UX team's exceptional work,
                  spanning diverse projects that exemplify their creativity and
                  expertise. Explore designs that redefine digital interactions
                  and elevate user experiences.
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ReactSlick3;

import React, { useRef } from 'react';
import TechnologiesBG from '../../../Images/ServiceImages/ReadeMoreImages/Technologies.webp';
import '../../../Assests/technologiesStyle.css';
import BackButton from '../../../Images/ServiceImages/ReadeMoreImages/BackButton.png';
import ForwardButton from '../../../Images/ServiceImages/ReadeMoreImages/ForwardButton.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
import Frontend from './sliders/frontend';
import BackEnd from './sliders/backend';
import Mobile from './sliders/mobile';
import Languages from './sliders/languages';
import Cloud from './sliders/cloud';
import Database from './sliders/databases';

const Technologies = () => {

    const swiperRef = useRef(null);

    const slideNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className="bg-cover bg-center w-[100%] h-screen bg-black/50 " style={{ backgroundImage: `url(${TechnologiesBG})` }}>
            <div className="text-white pb-5 pt-20 mt-[-20px] text-center font-['Poppins'] text-[2.375rem] font-bold leading-[63px] uppercase">
                technologies we use
            </div>

            <div className="technologies mx-auto flex-shrink-0 ml-[10%] mr-[10%] pl-5 pr-5 w-auto h-[23.75rem] rounded-xl border-[3px] border-[#5882c1]/[.49] bg-[#5882c1]/[.28] relative mb-4">
                <div className="
                frame_1
                mx-auto 
                mt-8
                mb-5 
                w-auto 
                lg:h-[5.375rem]
                md:h-[5.375rem]
                rounded-xl 
                border-[3px] 
                border-[#5882c1]/[.49] 
                bg-[#5882c1]/[.28] 
                relative
                min-[320px]:h-[100px]">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        spaceBetween={-0}
                        slidesPerView={1}
                        
                        navigation={{
                            nextEl: '.custom-next-button',
                            prevEl: '.custom-prev-button',
                        }}
                        autoplay={{
                            
                            delay: 10000,
                            disableOnInteraction: false,
                        }}
                    >
                        <SwiperSlide>
                            <div className="
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                front-end development Tools
                            </div>
                            <div className='pt-24 mx-auto'>
                                <Frontend />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="
                            mb-10 
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                back-end development Tools
                            </div>
                            <div className='mx-auto pt-14'>
                                <BackEnd />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="
                            mb-10 
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                mobile development Tools
                            </div>
                            <div className='mx-auto pt-14'>
                                <Mobile />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="
                            mb-10 
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                Programming Languages
                            </div>
                            <div className='mx-auto pt-14'>
                                <Languages />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="
                            mb-20 
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                Cloud Services
                            </div>
                            <div className='pt-5 mx-auto'>
                                <Cloud />
                            </div>
                        </SwiperSlide>
                       
                        <SwiperSlide>
                            <div className="
                            mb-20 
                            text-white 
                            text-center 
                            font-['Poppins'] 
                            lg:text-[1.5625rem] 
                            lg:w-auto
                            font-semibold 
                            leading-[20px] 
                            capitalize
                            min-[320px]:w-[9.3125rem]
                            min-[320px]:text-sm
                            min-[320px]:mt-[25px]
                            min-[320px]:mx-auto
                            md:w-auto
                            md:text-2xl
                            ">
                                Database
                            </div>
                            <div className='pt-5 mx-auto'>
                                <Database />
                            </div>
                        </SwiperSlide>

                        <div className='container'>
                            {/* Your existing code for navigation buttons */}
                            <div className="absolute lg:top-6 top-[40px] md:top-[25px] left-4 z-50 ml-[0px] mr-5 transition-all duration-300 ease-in-out hover:scale-100">
                                <div className="flex text-white custom-next-button" onClick={slidePrev}>
                                    <img src={BackButton} alt='back button' className='w-5 lg:w-8 md:w-8' />
                                </div>
                            </div>
                            <div className="absolute lg:top-6 top-[40px] md:top-[25px] right-4 z-50 mr-[0px] transition-all duration-300 ease-in-out hover:scale-100">
                                <div className="text-white custom-prev-button" onClick={slideNext}>
                                    <img src={ForwardButton} alt='previous button' className='w-5 lg:w-8 md:w-8' />
                                </div>
                            </div>

                            {/* New styles for border glow effect */}
                            <style>
                                {`
                        .container .custom-next-button:hover,
                        .container .custom-prev-button:hover {
                            box-shadow: 0px 0px 10px 1px rgba(151, 216, 222, 0.5);
                            border: 0px solid rgba(255, 255, 255, 0.225);
                            border-radius: 50px;
                        }
                    `}
                            </style>
                        </div>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Technologies;